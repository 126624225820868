import {DeleteForever, Edit} from '@mui/icons-material';
import {GridColDef} from '@mui/x-data-grid';
import moment from 'moment';

export const equipmentColumns: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Created At',
    width: 200,
    renderCell: data =>
      moment(data.row.created_at).format('hh:mm A DD/MM/YYYY'),
  },
  {
    field: 'equipment',
    headerName: 'Equipment',
    width: 250,
    renderCell: data => data.row.name,
  },
  {
    field: 'edit',
    headerName: 'Edit',
    width: 50,
    align: 'center',
    renderCell: () => <Edit sx={{cursor: 'pointer'}} />,
  },
  {
    field: 'delete',
    headerName: 'Delete',
    width: 70,
    align: 'center',
    renderCell: () => <DeleteForever sx={{cursor: 'pointer'}} />,
  },
];
