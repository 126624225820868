export interface VacationStruct {
  id: string;
  title: string;
  start: string | null;
  end: string | null;
  reason: string;
  status: string;
}

export const emptyVacation: VacationStruct = {
  id: '',
  title: '',
  start: null,
  end: null,
  reason: '',
  status: 'active',
};
