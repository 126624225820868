/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {DeleteForever} from '@mui/icons-material';
import {
  FormControlLabel,
  Checkbox,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {Confirm} from '../../common/Alerts/Confirm';

export interface CategoryProps {
  category: any;
  handleUpdate: (row: any) => void;
}
export const CategoryRow = ({category, handleUpdate}: CategoryProps) => {
  const [enabled, setEnabled] = useState(category.status === 'active');
  const [state, setState] = useState(category);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    handleUpdate(state);
  }, [handleUpdate, state]);

  const handleUpdateStatus = (checked: boolean | null) => {
    if (checked !== null) {
      const newStatus = checked ? 'active' : 'inactive';
      setEnabled(checked);
      setState({...state, status: newStatus});
    } else {
      setState({...state, status: 'deleted'});
    }
  };

  return (
    <>
      <Grid container columns={12} sx={{display: 'flex', alignItems: 'center'}}>
        <Grid item xs={1}>
          <Checkbox
            checked={enabled}
            onChange={event => handleUpdateStatus(event.target.checked)}
          />
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="Category Name">
            <TextField
              fullWidth
              label="Name"
              size="small"
              disabled={!enabled}
              value={state.name || ''}
              onChange={event => {
                setState({...state, name: event.target.value});
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <FormControl size="small" fullWidth>
            <InputLabel id="inputlbl">Input</InputLabel>
            <Select
              labelId="inputlbl"
              id="input"
              label="Input"
              value={state.input_type || ''}
              onChange={event => {
                setState({...state, input_type: event.target.value});
              }}
              disabled={!enabled}
            >
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="number_single">Single Number</MenuItem>
              <MenuItem value="number_range">Number Range</MenuItem>
              <MenuItem value="date">Date Selector</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title="How far in advance to book an appointment (in hours)">
            <TextField
              fullWidth
              label="Hours"
              size="small"
              type="number"
              InputProps={{inputProps: {min: 1}}}
              disabled={!enabled}
              value={state.apprise_hours || ''}
              onChange={event => {
                setState({...state, apprise_hours: event.target.value});
              }}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={2} sx={{padding: '15px'}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.is_shift_linked ? state.is_shift_linked : false}
                onChange={() =>
                  setState({...state, is_shift_linked: !state.is_shift_linked})
                }
              />
            }
            label="Linked To Shift"
          />
        </Grid>
        <Grid item xs={1} sx={{textAlign: 'right'}}>
          <Tooltip title="Delete">
            <DeleteForever
              sx={{cursor: 'pointer'}}
              onClick={() => setShowConfirm(true)}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Confirm
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        handleConfirmed={() => handleUpdateStatus(null)}
        text="Are you sure you want to delete this category?"
      />
    </>
  );
};
