import {
  Alert,
  AlertColor,
  Grow,
  GrowProps,
  Snackbar,
  Typography,
} from '@mui/material';

export interface ToastProps {
  open: boolean;
  handleClose: () => void;
  text: string;
  severity: AlertColor;
  verticalPos?: 'top' | 'bottom';
  horizontalPos?: 'left' | 'right' | 'center';
}

/**
 * Displays a toast that provides a brief notification
 *
 * @remarks
 * This component makes use of the mui Snackbar and Alert components
 *
 * @param open - Whether to show the toast or not
 * @param handleClose - Handles what happens when the component is closed
 * @param text - The text to display in the popup
 * @param severity - The severity of the toast. Options are: 'success', 'info', 'warning', 'error'
 * @param verticalPos - Vertical position of the toast. Options are: 'top', 'bottom'
 * @param horizontalPos - Horizontal position of the toast. Options are 'left', 'center', 'right'
 */
export const Toast = ({
  open,
  handleClose,
  text,
  severity,
  verticalPos = 'bottom',
  horizontalPos = 'center',
}: ToastProps) => {
  return (
    <>
      {open && (
        <Snackbar
          anchorOrigin={{vertical: verticalPos, horizontal: horizontalPos}}
          open={open}
          onClose={handleClose}
          key="toast"
          TransitionComponent={(props: GrowProps) => <Grow {...props} />}
        >
          <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
            <Typography variant="h6">{text}</Typography>
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
