import {
  createCertification,
  deleteCertification,
  updateCertification,
} from '@digistaff/business';
import {Button, Stack, TextField} from '@mui/material';
import {useEffect, useState} from 'react';
import {Confirm} from '../../common/Alerts/Confirm';
import {GlobalModal} from '../../common/GlobalModal/GlobalModal';
import {app} from '../../providers/Account';
import {ToastStruct} from '../../types/types';
import {CertificationStruct, emptyCertification} from './CertificationData';

export interface ManageProps {
  open: boolean;
  handleClose: () => void;
  data: null | CertificationStruct;
  reload: () => void;
  displayToast: (data: ToastStruct) => void;
}
export const ManageCertification = ({
  open,
  handleClose,
  data,
  reload,
  displayToast,
}: ManageProps) => {
  const [state, setState] = useState<CertificationStruct>(emptyCertification);
  const [showConfirm, setShowConfirm] = useState(false);
  const [action, setAction] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (data) setState(data);
  }, [data]);

  const handleAddCertification = () => {
    createCertification(app, {
      name: state.name,
      description: state.description,
    })
      .then(() => {
        setShowConfirm(false);
        handleModalClosed();
        displayToast({
          severity: 'success',
          text: `Certification ${state.name} has been created`,
        });
      })
      .catch(err => handleError(err))
      .finally(() => reload());
  };

  const handleUpdateCertification = () => {
    updateCertification(app, state.id, {
      name: state.name,
      description: state.description,
    })
      .then(() => {
        setShowConfirm(false);
        handleModalClosed();
        displayToast({
          severity: 'success',
          text: `Certification ${state.name} has been updated`,
        });
      })
      .catch(err => handleError(err))
      .finally(() => reload());
  };

  const handleDeleteCertification = () => {
    deleteCertification(app, state.id)
      .then(() => {
        setShowConfirm(false);
        handleModalClosed();
        displayToast({
          severity: 'success',
          text: `Certification ${state.name} has been deleted`,
        });
      })
      .catch(err => handleError(err))
      .finally(() => reload());
  };

  const handleModalClosed = () => {
    setState(emptyCertification);
    handleClose();
  };

  const manageConfirm = (type: string) => {
    setAction(type);
    switch (type) {
      case 'ADD':
        setMessage('Are you sure you want to add this new certification?');
        setShowConfirm(true);
        break;
      case 'UPDATE':
        setMessage('Are you sure you want to update this certification?');
        setShowConfirm(true);
        break;
      case 'DELETE':
        setMessage(`Are you sure you want to delete ${state.name}?`);
        setShowConfirm(true);
        break;
      default:
        handleError('Error. Unknown confirmation option provided');
    }
  };

  const handleError = (message: string) => {
    handleModalClosed();
    displayToast({
      severity: 'error',
      text: message,
    });
  };

  const handleAction = () => {
    switch (action) {
      case 'ADD':
        handleAddCertification();
        break;
      case 'UPDATE':
        handleUpdateCertification();
        break;
      case 'DELETE':
        handleDeleteCertification();
        break;
      default:
        handleError('Error. Unknown action provided');
    }
  };

  return (
    <>
      <GlobalModal
        open={open}
        onClose={handleModalClosed}
        defaultHeader={true}
        title={data !== null ? `Edit ${data.name}` : 'Add Certification'}
        width="40vw"
      >
        <Stack spacing={1}>
          <TextField
            label="Name"
            size="small"
            fullWidth
            value={state.name}
            onChange={event => setState({...state, name: event.target.value})}
          />
          <TextField
            label="Description"
            size="small"
            fullWidth
            value={state.description}
            onChange={event =>
              setState({...state, description: event.target.value})
            }
          />
          {data === null ? (
            <Button onClick={() => manageConfirm('ADD')} variant="outlined">
              Add
            </Button>
          ) : (
            <Stack direction="row" spacing={1}>
              <Button
                onClick={() => manageConfirm('DELETE')}
                variant="outlined"
                fullWidth
              >
                Delete
              </Button>
              <Button
                onClick={() => manageConfirm('UPDATE')}
                variant="outlined"
                fullWidth
              >
                Update
              </Button>
            </Stack>
          )}
        </Stack>
      </GlobalModal>
      <Confirm
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        handleConfirmed={handleAction}
        text={message}
      />
    </>
  );
};
