export const labeledBox = {
  border: '1px solid black',
  paddingX: '15px',
  paddingBottom: '15px',
  width: '100%',
  marginBottom: '15px',
};

export const labeledBoxTitle = {
  marginTop: '-25px',
  marginLeft: '15px',
  backgroundColor: 'white',
  padding: '10px',
  width: 'fit-content',
};
