/* eslint-disable @typescript-eslint/no-explicit-any */

export const filterHiddenAdmins = (list: any) => {
  const hiddenAdmins = [
    'yash@digistaff.ca',
    'lucky@digistaff.ca',
    'yash@hdgroup.ca',
    'lucky@hdgroup.ca',
    'mak@digistaff.ca',
    'ops.rcoleman@gmail.com',
  ];

  return list.filter((staff: any) => !hiddenAdmins.includes(staff.id));
};
