import {MoreVert} from '@mui/icons-material';
import {useState} from 'react';
import {Box, IconButton, MenuItem, Menu, Tooltip} from '@mui/material';
import {ManageModal} from './Modals/ManageModal';
import {RoleStruct} from './rolesViewData';
import {Confirm} from '../../common/Alerts/Confirm';
import {ToastStruct} from '../../types/types';
import {useRole} from '../../providers/api/useRole';

export interface ManageRoleMenuProps {
  role: RoleStruct;
  handleToast: (data: ToastStruct) => void;
  update: () => void;
}

export const ManageRoleMenu = ({
  role,
  handleToast,
  update,
}: ManageRoleMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showEditRole, setShowEditRole] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const {deleteARole} = useRole();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    deleteARole(role.id).then(() => {
      update();
      handleToast({
        severity: 'success',
        text: `The role ${role.name} has been deleted`,
        verticalPos: 'bottom',
        horizontalPos: 'center',
      });
      setShowDelete(false);
    });
  };

  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        <Tooltip title="Manage">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'manage-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVert sx={{fontSize: '1.5em', color: 'black'}} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="manage-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            marginTop: '10px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem onClick={() => setShowEditRole(true)}>Edit</MenuItem>
        <MenuItem onClick={() => setShowDelete(true)}>Delete</MenuItem>
      </Menu>
      <ManageModal
        title="Edit Role"
        currentRole={role}
        open={showEditRole}
        handleClose={() => {
          update();
          setShowEditRole(false);
        }}
        handleToast={(data: ToastStruct) => handleToast(data)}
      />
      <Confirm
        text={`Are you sure you want to delete the role ${role.name}`}
        open={showDelete}
        handleClose={() => setShowDelete(false)}
        handleConfirmed={handleDelete}
      />
    </>
  );
};
