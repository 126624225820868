export const styles = {
  paymentsMainContainer: {
    borderBottom: 1,
    borderColor: 'divider',
  },
  paymentsDetailContainer: {
    boxShadow: '0 0 6px 2px rgb(65 64 66 / 15%)',
    borderRadius: 2,
  },
  paymentsDetailsContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 4,
  },
  paymentsDetailsInfo: {
    bgcolor: '#f5f5f5',
    pb: 2,
    pt: 2,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  loaderContainer: {
    display: 'flex',
    alignContent: 'center',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 20,
    marginBottom: 20,
  },
  card: {
    minWidth: 275,
    marginBottom: '25px',
  },
};
