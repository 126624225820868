import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from '@mui/material';
import {GlobalModal} from '../../../common/GlobalModal/GlobalModal';
import {emptyUser, UserStruct} from '../UserViewData';
import {useEffect, useState} from 'react';
import {VisibilityOff, Visibility} from '@mui/icons-material';
import {ToastStruct} from '../../../types/types';

export interface SetPassModalProps {
  open: boolean;
  handleClose: () => void;
  user?: UserStruct;
  handleToast: (data: ToastStruct) => void;
}

export const SetPassModal = ({
  open,
  handleClose,
  user,
  handleToast,
}: SetPassModalProps) => {
  const [, setProfile] = useState<UserStruct>(emptyUser);
  const [showNewPass, setShowNewPass] = useState<boolean>(false);
  const [newPass, setNewPass] = useState<string>('');
  const [verifyNewPass, setVerifyNewPass] = useState<string>('');

  useEffect(() => {
    if (user) setProfile(user);
  }, [user, setProfile]);

  const verifyPass = () => {
    if (newPass !== '' && newPass === verifyNewPass) {
      handleToast({
        severity: 'success',
        text: 'Users password has been changed',
        verticalPos: 'bottom',
        horizontalPos: 'center',
      });
      handleClose();
    } else {
      handleToast({
        severity: 'error',
        text: 'Invalid new password provided',
        verticalPos: 'bottom',
        horizontalPos: 'center',
      });
    }
  };

  return (
    <>
      <GlobalModal
        open={open}
        onClose={handleClose}
        defaultHeader={true}
        title="Set Password"
        width="30vw"
      >
        <Box>
          <Stack spacing={2}>
            <FormControl>
              <InputLabel htmlFor="old-password">New Password</InputLabel>
              <OutlinedInput
                type={showNewPass ? 'text' : 'password'}
                label="New Password"
                value={newPass}
                onChange={event => setNewPass(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle new password visibility"
                      onClick={() => setShowNewPass(!showNewPass)}
                      edge="end"
                    >
                      {showNewPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              ></OutlinedInput>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="old-password">
                Repeat New Password
              </InputLabel>
              <OutlinedInput
                type={showNewPass ? 'text' : 'password'}
                label="Repeat New Password"
                value={verifyNewPass}
                onChange={event => setVerifyNewPass(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle verify new password visibility"
                      onClick={() => setShowNewPass(!showNewPass)}
                      edge="end"
                    >
                      {showNewPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              ></OutlinedInput>
            </FormControl>
            <FormControl>
              <Button variant="outlined" onClick={verifyPass}>
                Update
              </Button>
            </FormControl>
          </Stack>
        </Box>
      </GlobalModal>
    </>
  );
};
