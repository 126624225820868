import {Typography} from '@mui/material';
import {GridColDef} from '@mui/x-data-grid';

export const logColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Log ID',
    width: 175,
  },
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    width: 200,
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 150,
  },
  {
    field: 'user',
    headerName: 'User',
    width: 100,
  },
  {
    field: 'ip',
    headerName: 'IP',
    width: 150,
    renderCell: data => (
      <Typography fontWeight="bold">{data.formattedValue}</Typography>
    ),
  },
  {
    field: 'logAction',
    headerName: 'Action',
    width: 150,
  },
  {
    field: 'message',
    headerName: 'Message',
    width: 400,
  },
];

export interface LogStruct {
  id: string;
  timestamp: string;
  category: 'scheduling' | 'payroll' | 'login' | 'adminUpdate';
  user: string;
  ip: string;
  logAction: string;
  message: string;
}

export const emptyLog: LogStruct = {
  id: '',
  timestamp: '',
  category: 'scheduling',
  user: '',
  ip: '',
  logAction: '',
  message: '',
};

export const logRows: LogStruct[] = [
  {
    id: 'ddgQ-S6sO-MoSJ',
    timestamp: '2023/02/10 3:34 PM',
    category: 'scheduling',
    user: 'Admin',
    ip: '192.168.0.1',
    logAction: 'endpoint',
    message: 'Pay marked as sent',
  },
];
