import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {HorizontalTabs} from '../../common/Tabs/HorizontalTabs';
import {DigiCheckSettings} from './DigiCheckSettings';
import {DigiScreenSettings} from './DigiScreenSettings';

export const OnboardingView = () => {
  return (
    <MainViewContainer title="Onboarding">
      <HorizontalTabs labels={['DigiScreen', 'DigiCheck']}>
        <DigiScreenSettings />
        <DigiCheckSettings />
      </HorizontalTabs>
    </MainViewContainer>
  );
};
