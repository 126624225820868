export type AlertColor = 'success' | 'info' | 'warning' | 'error';

export interface ToastStruct {
  severity: AlertColor;
  text: string;
  verticalPos?: 'top' | 'bottom';
  horizontalPos?: 'left' | 'right' | 'center';
}

export const blankToast: ToastStruct = {
  severity: 'success',
  text: '',
  verticalPos: 'bottom',
  horizontalPos: 'center',
};
export interface ResourceStruct {
  app_id: string;
  created_at: string;
  description: string;
  id: string;
  name: string;
  updated_at: string;
}

export interface PolicyStruct {
  id: string;
  allow: string[];
  created_at?: string;
  resource_id?: string;
  role_id?: string;
  updated_at?: string;
}

export interface NewRoleErrorsStruct {
  name?: string;
  description?: string;
}
