import {Box, FormControlLabel, Checkbox} from '@mui/material';
import {ChangeEvent, useEffect, useState} from 'react';
import {PolicyStruct, ResourceStruct} from '../../../types/types';

interface PermissionGroupProps {
  resource: ResourceStruct;
  handleUpdate: (id: string, allow: string[], policyId?: string) => void;
  policy: PolicyStruct | undefined;
}

export const PermissionGroup = ({
  resource,
  handleUpdate,
  policy,
}: PermissionGroupProps) => {
  const [read, setRead] = useState(false);
  const [write, setWrite] = useState(false);

  useEffect(() => {
    if (policy) {
      if (policy.allow.includes('read')) setRead(true);
      if (policy.allow.includes('write')) setWrite(true);
    }
  }, [policy]);

  const handleParentClicked = () => {
    if ((!read && !write) || (!read && write) || (read && !write)) {
      setRead(true);
      setWrite(true);
      update(true, true);
    } else {
      setRead(false);
      setWrite(false);
      update(false, false);
    }
  };

  const handleReadClicked = (_event: ChangeEvent, value: boolean) => {
    if (write) {
      setRead(true);
      update(true, true);
    } else {
      setRead(value);
      update(value, write);
    }
  };

  const handleWriteClicked = (_event: ChangeEvent, value: boolean) => {
    if (value) {
      setRead(true);
      setWrite(true);
      update(true, true);
    } else {
      setWrite(false);
      update(read, false);
    }
  };

  const update = (currentRead: boolean, currentWrite: boolean) => {
    const allow: string[] = [];
    if (currentRead) allow.push('read');
    if (currentWrite) allow.push('write');

    handleUpdate(resource.id, allow, policy?.id);
  };

  return (
    <Box sx={{border: 'solid 1px black', p: 2}}>
      <FormControlLabel
        label={resource.name}
        control={
          <Checkbox
            checked={read && write}
            indeterminate={read !== write}
            onClick={handleParentClicked}
          />
        }
      />
      <Box sx={{display: 'flex', flexDirection: 'column', ml: 3}}>
        <FormControlLabel
          label="Read"
          control={<Checkbox checked={read} onChange={handleReadClicked} />}
        />
        <FormControlLabel
          label="Write"
          control={<Checkbox checked={write} onChange={handleWriteClicked} />}
        />
      </Box>
    </Box>
  );
};
