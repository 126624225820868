/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button, MenuItem} from '@mui/material';
import {DataGrid, GridColDef, GridToolbarExport} from '@mui/x-data-grid';
import {useCallback, useEffect, useState} from 'react';
import {Confirm} from '../../common/Alerts/Confirm';
import {Toast} from '../../common/Alerts/Toast';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {ManageMenu} from '../../common/ManageMenu/ManageMenu';
import {ToastStruct} from '../../types/types';
import {ManageModal} from './ManageModal';
import {emptyVacation, VacationStruct} from './vacationViewData';
import {useVacationBlackout} from '../../providers/api/useVacationBlackout';
import moment from 'moment';

export const VacationsView = () => {
  const [deleteId, setDeleteId] = useState('');

  const vacationColums: GridColDef[] = [
    {
      field: 'manage',
      headerName: 'Manage',
      width: 100,
      align: 'center',
      renderCell: data => (
        <ManageMenu>
          <MenuItem
            onClick={() => {
              setVacation(data.row);
              setShowManage(true);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              setDeleteId(data.row.id);
              setConfirm(true);
            }}
          >
            Delete
          </MenuItem>
        </ManageMenu>
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 200,
    },
    {
      field: 'start_date',
      headerName: 'Start',
      width: 100,
      renderCell: params => moment(params.row.start_date).format('DD/MM/YYYY'),
    },
    {
      field: 'end_date',
      headerName: 'End',
      width: 100,
      renderCell: params => moment(params.row.end_date).format('DD/MM/YYYY'),
    },
    {
      field: 'reason',
      headerName: 'Reason',
      width: 300,
    },
  ];

  const [confirm, setConfirm] = useState(false);
  const [showManage, setShowManage] = useState(false);
  const [vacation, setVacation] = useState<VacationStruct>(emptyVacation);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });
  const {listBlackouts, deleteBlackout} = useVacationBlackout();
  const [vacationBlackouts, setVacationBlackouts] = useState([]);

  const handleToast = (data: ToastStruct) => {
    setShowManage(false);
    setToastData(data);
    setShowToast(true);
  };

  const handleDelete = () => {
    setConfirm(false);
    deleteBlackout(deleteId)
      .then(() => {
        setToastData({
          severity: 'success',
          text: 'Vacation block has been deleted',
          verticalPos: 'bottom',
          horizontalPos: 'center',
        });
      })
      .catch(err => console.log(err))
      .finally(() => loadVacationsBlackouts());
    setShowToast(true);
  };

  const loadVacationsBlackouts = useCallback(async () => {
    const blackouts: any = await listBlackouts();
    setVacationBlackouts(blackouts);
  }, [listBlackouts]);

  useEffect(() => {
    loadVacationsBlackouts();
  }, [loadVacationsBlackouts]);

  return (
    <MainViewContainer title="Vacation Blackout Periods">
      <Box
        sx={{display: 'flex', justifyContent: 'flex-end', marginBottom: '15px'}}
      >
        <Button
          variant="contained"
          onClick={() => {
            setVacation(emptyVacation);
            setShowManage(true);
          }}
        >
          Add Blackout Period
        </Button>
      </Box>
      <DataGrid
        sx={{minHeight: '400px'}}
        rows={vacationBlackouts}
        columns={vacationColums}
        rowsPerPageOptions={[25, 50, 100]}
        density="compact"
        components={{
          Toolbar: () => <GridToolbarExport />,
        }}
      />
      <Confirm
        open={confirm}
        handleClose={() => setConfirm(false)}
        handleConfirmed={handleDelete}
        text="Are you sure you want to delete this vacation blackout block?"
      />
      <ManageModal
        open={showManage}
        handleClose={() => {
          setShowManage(false);
          loadVacationsBlackouts();
        }}
        currentVacation={vacation}
        handleToast={(data: ToastStruct) => handleToast(data)}
      />
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        severity={toastData.severity}
        text={toastData.text}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
    </MainViewContainer>
  );
};
