import {
  DataGrid,
  getGridStringOperators,
  GridCellParams,
  GridColDef,
  GridFilterModel,
  GridRowParams,
  GridSortModel,
  GridToolbar,
} from '@mui/x-data-grid';
import {useEffect, useMemo, useState} from 'react';
import {CustomNoRowsOverlay, FilterStruct} from './Helpers';

export interface DataTableProps {
  columns: GridColDef[];
  rows: Object[];
  loading: boolean;
  rowCount: number;
  height?: string;
  handleRowClick?: (event: GridRowParams) => void;
  handleCellClick?: (event: GridCellParams) => void;
  getData: (
    page: number,
    pageSize: number,
    filter: Object,
    sorting: Object[]
  ) => void;
}

/**
 * DataTable used to display information
 *
 * @remarks
 * Handles loading & when there are no rows
 *
 * @param columns - Headers for the DataGrid
 * @param rows - Data to be rendered in the DataGrid
 * @param loading - Whether or not the data is loading
 * @param rowCount - The total number of rows in the dataset on the server
 * @param height - Height of the DataGrid (optional)
 * @param handleRowClick- Handler for when a row is clicked
 * @param getData - Function to make the sdk call
 *
 * @returns A DataGrid that will display the passed information
 */
export const DataTable = ({
  columns,
  rows,
  loading,
  height,
  rowCount,
  handleRowClick,
  handleCellClick,
  getData,
}: DataTableProps) => {
  const filterColumns = useMemo(
    () =>
      columns.map((col: GridColDef) => {
        return {
          ...col,
          filterOperators: getGridStringOperators().filter(
            operator => operator.value === 'equals'
          ),
        };
      }),
    [columns]
  );

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [filter, setFilter] = useState({});
  const [sorting, setSorting] = useState([
    {column: 'updated_at', order: 'desc'},
  ]);

  useEffect(() => {
    getData(page, pageSize, filter, sorting);
  }, [getData, page, pageSize, filter, sorting]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSortChange = (model: GridSortModel) => {
    if (model.length > 0) {
      if (model[0].sort !== undefined && model[0].sort !== null) {
        setSorting([{column: model[0].field, order: model[0].sort}]);
      }
    } else setSorting([{column: 'updated_at', order: 'desc'}]);
  };

  const handleFilterChange = (model: GridFilterModel) => {
    const field = model.items[0].columnField;
    const value = model.items[0].value;
    if (value !== undefined && value.length > 0) {
      const newFilter: FilterStruct = {};
      newFilter[field] = value;
      setFilter(newFilter);
      setPage(0);
    } else {
      setFilter({});
    }
  };

  return (
    <DataGrid
      sx={{height: height ? height : '500px'}}
      columns={filterColumns}
      rows={rows}
      loading={loading}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
      rowsPerPageOptions={[10, 50, 100]}
      density="compact"
      paginationMode="server"
      sortingMode="server"
      filterMode="server"
      rowCount={rowCount}
      onPageChange={handlePageChange}
      onSortModelChange={handleSortChange}
      onFilterModelChange={handleFilterChange}
      onRowClick={handleRowClick}
      onCellClick={handleCellClick}
      components={{
        Toolbar: () => <GridToolbar />,
        NoRowsOverlay: CustomNoRowsOverlay,
      }}
      initialState={{
        filter: {
          filterModel: {
            items: [],
          },
        },
      }}
    />
  );
};
