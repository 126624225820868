/* eslint-disable @typescript-eslint/no-explicit-any */
import {useCallback, useEffect, useState} from 'react';
import {Button, Box} from '@mui/material';
import {app} from '../../providers/Account';
import {
  countBusinesses,
  getBusinessAndLocations,
  listBusiness,
} from '@digistaff/business';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {DataTable} from '../../common/DataTable/DataTable';
import {Toast} from '../../common/Alerts/Toast';
import {BusinessStruct, businessGridCols} from './BusinessData';
import {blankToast, ToastStruct} from '../../types/types';
import {ManageBusiness} from './ManageBusiness';

export const BusinessView = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>(blankToast);

  const [businesses, setBusinesses] = useState<BusinessStruct[]>([]);
  const [loading, setLoading] = useState(true);

  const [rowCount, setRowCount] = useState(0);
  const [editData, setEditData] = useState<any>(null);

  const [showManage, setShowManage] = useState(false);
  const getBusinesses = useCallback(
    (
      page: number,
      pageSize: number,
      filter: Object = {},
      sorting: Object[] = [{column: 'updated_at', order: 'desc'}]
    ) => {
      listBusiness(app, {
        order: sorting,
        limit: pageSize,
        offset: page * pageSize,
        filter: filter,
      })
        .then(res => {
          setBusinesses(res.data.listBusinesses);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });
    },
    []
  );

  useEffect(() => {
    countBusinesses(app)
      .then(res => setRowCount(res.data.countBusinesses))
      .catch(err => console.log(err));
  }, []);

  const handleRowClick = (event: any) => {
    getBusinessAndLocations(app, event.id)
      .then(res => {
        setEditData(res.data.getBusiness);
        setShowManage(true);
      })
      .catch(err => console.log(err));
  };

  const displayToast = (data: ToastStruct) => {
    setToastData(data);
    setShowToast(true);
  };

  return (
    <MainViewContainer title="Business">
      <Box
        sx={{display: 'flex', justifyContent: 'flex-end', marginBottom: '15px'}}
      >
        <Button variant="outlined" onClick={() => setShowManage(true)}>
          Add Business
        </Button>
      </Box>

      <DataTable
        rows={businesses}
        columns={businessGridCols}
        loading={loading}
        rowCount={rowCount}
        handleRowClick={handleRowClick}
        getData={getBusinesses}
      />
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        text={toastData.text}
        severity={toastData.severity}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
      {showManage && (
        <ManageBusiness
          open={showManage}
          handleClose={() => {
            setEditData(null);
            setShowManage(false);
          }}
          data={editData}
          reload={() => getBusinesses(0, 10)}
          displayToast={displayToast}
        />
      )}
    </MainViewContainer>
  );
};
