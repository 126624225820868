import {ContentCopy, DeleteForever, Edit} from '@mui/icons-material';
import {GridColDef} from '@mui/x-data-grid';
import moment from 'moment';

export const kioskViewData: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Created At',
    width: 200,
    renderCell: data =>
      moment(data.row.created_at).format('hh:mm A DD/MM/YYYY'),
  },
  {
    field: 'location',
    headerName: 'Location',
    width: 250,
    renderCell: data => data.row.nestGetBusinessLocation.street,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 175,
  },
  {
    field: 'auth_code',
    headerName: 'Pin',
    width: 130,
    renderCell: data => {
      if (data.row.auth_code)
        return (
          <>
            <ContentCopy sx={{mr: 1, cursor: 'pointer'}} />
            {data.row.auth_code}
          </>
        );
      else return '-';
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
  },
  {
    field: 'edit',
    headerName: 'Edit',
    width: 50,
    align: 'center',
    renderCell: () => <Edit sx={{cursor: 'pointer'}} />,
  },
  {
    field: 'delete',
    headerName: 'Delete',
    width: 70,
    align: 'center',
    renderCell: () => <DeleteForever sx={{cursor: 'pointer'}} />,
  },
];
