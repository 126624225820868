export const popupModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  overflow: 'hidden',
  display: 'flex',
};

export const popupCloseBtn = {
  cursor: 'pointer',
  fontSize: '38px',
  position: 'absolute',
  right: 24,
  top: 24,
};
