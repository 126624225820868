/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import {useState} from 'react';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {entries, ListEntry} from './notificationViewData';
import {TransferList} from './TransferList';

export const NotificationsView = () => {
  const [category, setCategory] = useState('');

  const [left, setLeft] = useState<ListEntry[]>([]);
  const [right, setRight] = useState<ListEntry[]>([]);

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setCategory(value);
    const newRight: ListEntry[] = [];
    const newLeft: ListEntry[] = [];
    entries.map(entry => {
      if (entry.category.includes(value)) newRight.push(entry);
      else newLeft.push(entry);
    });
    setLeft(newLeft);
    setRight(newRight);
  };

  return (
    <MainViewContainer title="Admin Notifications">
      <FormControl size="small" sx={{width: '250px'}}>
        <InputLabel id="categorylbl">Category</InputLabel>
        <Select
          labelId="categorylbl"
          id="category"
          label="Category"
          value={category}
          onChange={handleCategoryChange}
          sx={{marginBottom: '15px'}}
        >
          <MenuItem value="billing-payment">Billing & Payment</MenuItem>
          <MenuItem value="technology">Technology Alert</MenuItem>
          <MenuItem value="daily-ops">Daily Ops Summary</MenuItem>
          <MenuItem value="campaign">Campaign Results</MenuItem>
        </Select>
      </FormControl>
      {category !== '' && (
        <TransferList initialLeft={left} initialRight={right} />
      )}
    </MainViewContainer>
  );
};
