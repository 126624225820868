/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  createMandatoryCertification,
  deleteMandatoryCertification,
  listCertifications,
  listMandatoryCertifications,
  updateMandatoryCertification,
} from '@digistaff/business';
import {app} from '../Account';
import {useCallback} from 'react';

export const useCertifications = () => {
  const listMasterCertifications = useCallback(() => {
    return new Promise((resolve, reject) => {
      listCertifications(app, {})
        .then(res => {
          resolve(res.data.listCertifications);
        })
        .catch(err => {
          reject(err);
        });
    });
  }, []);

  const listTenantCertifications = useCallback(() => {
    return new Promise((resolve, reject) => {
      listMandatoryCertifications(app)
        .then(res => {
          resolve(res.data.listMandatoryCertifications);
        })
        .catch(err => reject(err));
    });
  }, []);

  const createTenantCertification = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      createMandatoryCertification(app, {id: id})
        .then(res => {
          resolve(res.data.createMandatoryCertification);
        })
        .catch(err => reject(err));
    });
  }, []);

  const deleteTenantCertification = (id: string) => {
    return new Promise((resolve, reject) => {
      deleteMandatoryCertification(app, id)
        .then(res => {
          resolve(res.data.deleteTenantCertification);
        })
        .catch(err => reject(err));
    });
  };

  const updateTenantCertification = (id: string, payload: any) => {
    return new Promise((resolve, reject) => {
      updateMandatoryCertification(app, id, payload)
        .then(res => {
          resolve(res.data.deleteTenantCertification);
        })
        .catch(err => reject(err));
    });
  };

  return {
    listMasterCertifications,
    listTenantCertifications,
    createTenantCertification,
    deleteTenantCertification,
    updateTenantCertification,
  };
};
