import {
  createEquipmentRental,
  listEquipmentRentals,
  updateEquipmentRental,
} from '@digistaff/business';
import {useCallback} from 'react';
import {app} from '../Account';

export const useEquipment = () => {
  const listEquipment = useCallback(async () => {
    return new Promise((resolve, reject) => {
      listEquipmentRentals(app, {
        filter: {
          status: {
            operator: '<>',
            value: 'deleted',
          },
        },
      })
        .then(res => {
          resolve(res.data.listEquipmentRentals);
        })
        .catch(err => {
          reject(err);
        });
    });
  }, []);

  const deleteEquipment = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      updateEquipmentRental(app, id, {status: 'deleted'})
        .then(res => resolve(res.data.updateEquipmentRental))
        .catch(err => reject(err));
    });
  }, []);

  const createEquipment = useCallback((payload: Object) => {
    return new Promise((resolve, reject) => {
      createEquipmentRental(app, payload)
        .then(res => resolve(res.data.createEquipmentRental))
        .catch(err => reject(err));
    });
  }, []);

  const updateEquipment = useCallback((id: string, name: string) => {
    return new Promise((resolve, reject) => {
      updateEquipmentRental(app, id, {name: name})
        .then(res => resolve(res.data.updateEquipmentRental))
        .catch(err => reject(err));
    });
  }, []);

  return {listEquipment, deleteEquipment, createEquipment, updateEquipment};
};
