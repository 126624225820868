/* eslint-disable @typescript-eslint/no-unused-vars */
export const styles = ({
  isBusinessChecked,
  isRoleChecked,
  isUserChecked,
  isCollapseCheckbox,
  isCollapseLabel,
  isGrouped,
  isAggregated,
  isHover,
}: {
  isBusinessChecked?: boolean;
  isRoleChecked?: boolean;
  isUserChecked?: boolean;
  isCollapseCheckbox?: boolean;
  isCollapseLabel?: boolean;
  isGrouped?: boolean;
  isAggregated?: boolean;
  isHover?: boolean;
}) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flex: 1,
      gap: 30,
    },
    collapseContainer: {
      flex: 1,
      minWidth: 100,
    },
    collapseSearchInput: {
      mb: 3,
      '& .MuiInputBase-root': {
        bgcolor: 'white',
        borderRadius: 1,
      },
    },
    collapseFormControl: {
      width: '100%',
      alignItems: 'center',
      flexDirection: 'row',
      mb: 1,
    },
    collapseFormCheckbox: {
      display: isCollapseCheckbox ? 'flex' : 'none',
    },
    collapseFormLabel: {
      bgcolor: isCollapseLabel ? 'rgba(0,0,0,0.1)' : undefined,
    },
    collapsedFormLabel: {
      m: 1,
      bgcolor: 'rgba(0,0,0,0.1)',
      textAlign: 'left',
    },
    collapseContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    tableContainer: {
      flex: 3,
      height: 'fit-content(100%)',
    },
    tableSearchContainer: {
      justifyContent: 'flex-end',
      display: 'flex',
      width: '100%',
    },
    tableSearch: {
      mb: 2,
      width: '50%',
      '& .MuiInputBase-root': {
        bgcolor: 'white',
        borderRadius: 1,
      },
    },
    table: {
      borderRadius: 5,
      width: '100%',
      borderSpacing: 0,
      borderCollapse: 'collapse',
    },
    tableHeadCell: {
      border: '1px solid lightgrey',
      padding: 2,
      textAlign: 'left',
      cursor: 'pointer',
    },
    tableRow: {
      border: '1px solid lightgrey',
    },
    tableRowCell: {
      borderSpacing: '2px',
      padding: 10,
      background:
        isGrouped || isAggregated
          ? 'rgba(0,0,0,0.05)'
          : isHover
          ? 'rgba(0,0,0,0.1)'
          : 'white',
      cursor: isGrouped && isAggregated ? 'pointer' : 'auto',
    },
    expandedRowContainer: {
      display: 'flex',
      padding: 10,
      alignItems: 'center',
      cursor: 'pointer',
    },
    expandedRow: {
      lineHeight: 2.5,
      cursor: 'pointer',
    },
    paperProps: {
      style: {
        maxHeight: 40 * 4.5,
        boxShadow: '0px 0px 9px 0px rgba(0,0,0,0.2)',
      },
    },
    emptyTableElement: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      mt: 2,
    },
  };
};
