import {
  AccessTime,
  AccountTree,
  AddBusiness,
  AdminPanelSettingsOutlined,
  Construction,
  DisplaySettings,
  FilterCenterFocus,
  Flight,
  Gavel,
  GpsFixed,
  Notifications,
  Payment,
  People,
  Receipt,
  Security,
  Settings,
  WorkspacePremium,
  ManageAccounts,
  Work,
  AttachMoney,
  // WorkspacePremium,
} from '@mui/icons-material';

export const sideBarItemList = [
  {
    unCollapsedItem: {
      name: 'General',
      Icon: AdminPanelSettingsOutlined,
      url: '/general',
    },
  },
  {
    unCollapsedItem: {
      name: 'Subscription',
      Icon: Payment,
      url: '/subscription',
    },
  },
  {
    unCollapsedItem: {
      name: 'Invoices',
      Icon: Receipt,
      url: '/invoices',
    },
  },
  {
    unCollapsedItem: {
      name: 'Users',
      Icon: People,
      url: '/users',
    },
  },
  {
    unCollapsedItem: {
      name: 'Roles',
      Icon: DisplaySettings,
      url: '/roles',
    },
  },
  {
    unCollapsedItem: {
      name: 'Advanced Preferences',
      Icon: Settings,
      url: '/preferences',
    },
  },
  {
    unCollapsedItem: {
      name: 'DigiTrack',
      Icon: GpsFixed,
      url: '/digitrack',
    },
  },
  {
    unCollapsedItem: {
      name: 'Vacations',
      Icon: Flight,
      url: '/vacations',
    },
  },
  {
    unCollapsedItem: {
      name: 'Admin Notifications',
      Icon: Notifications,
      url: '/notifications',
    },
  },
  {
    unCollapsedItem: {
      name: 'Contracts',
      Icon: Gavel,
      url: '/contracts',
    },
  },
  {
    unCollapsedItem: {
      name: 'Security',
      Icon: Security,
      url: '/security',
    },
  },
  {
    unCollapsedItem: {
      name: 'Onboarding',
      Icon: AccountTree,
      url: '/onboarding',
    },
  },
  {
    unCollapsedItem: {
      name: 'Business',
      Icon: AddBusiness,
      url: '/business',
    },
  },
  {
    unCollapsedItem: {
      name: 'Appointments',
      Icon: AccessTime,
      url: '/appointments',
    },
  },
  {
    unCollapsedItem: {
      name: 'Equipment',
      Icon: Construction,
      url: '/equipment',
    },
  },
  {
    unCollapsedItem: {
      name: 'Kiosk',
      Icon: FilterCenterFocus,
      url: '/kiosk',
    },
  },
  {
    unCollapsedItem: {
      name: 'Enabled Certifications',
      Icon: WorkspacePremium,
      url: '/certifications/enabled',
    },
  },
  {
    unCollapsedItem: {
      name: 'Staff Custom Attributes',
      Icon: ManageAccounts,
      url: '/staff-custom-attributes',
    },
  },
  {
    unCollapsedItem: {
      name: 'Shift Settings',
      Icon: Work,
      url: '/shift-settings',
    },
  },
  {
    unCollapsedItem: {
      name: 'Payroll',
      Icon: AttachMoney,
    },
    collapsedItems: [
      {
        name: 'General',
        url: '/payroll/settings',
      },
      {
        name: 'Automation',
        url: '/payroll/automation',
      },
    ],
  },
  // {
  //   unCollapsedItem: {
  //     name: 'Certifications',
  //     Icon: WorkspacePremium,
  //     url: '/certifications',
  //   },
  // },
];
