/* eslint-disable @typescript-eslint/no-explicit-any */
import {useCallback, useEffect, useRef, useState} from 'react';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {
  ToggleButtonGroup,
  ToggleButton,
  Box,
  CircularProgress,
  Typography,
  Stack,
  Tooltip,
  Button,
  Divider,
} from '@mui/material';
import {Toast} from '../../common/Alerts/Toast';
import {ToastStruct} from '../../types/types';
import {listPayrollSettings, updatePayrollSetting} from '@digistaff/business';
import {app} from '../../providers/Account';
import ReactSignatureCanvas from 'react-signature-canvas';
import {GlobalModal} from '../../common/GlobalModal/GlobalModal';
import {uploadImageFile} from '../../providers/api/image';

export const Settings = () => {
  const [settings, setSettings] = useState<any>({});
  const [deductionType, setDeductionType] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });
  const [openSign, setOpenSign] = useState(false);
  const sigPadRef = useRef<ReactSignatureCanvas | null>(null);
  const [signature, setSignature] = useState('');

  const getSettings = useCallback(async () => {
    const result: any = await listPayrollSettings(app);
    const payrollSettings: any = result.data.listPayrollSettings[0];

    setSettings(payrollSettings);
    setDeductionType(payrollSettings.general_deduction_calculation_method);
    setSignature(payrollSettings.general_cheque_signature_url);
    setLoading(false);
  }, []);

  const updateSettings = async () => {
    const payload = {
      general_deduction_calculation_method: deductionType,
      general_cheque_signature_url: signature,
    };

    const result: any = await updatePayrollSetting(app, settings.id, payload);

    if (result.data.updatePayrollSetting.id) {
      setToastData({
        severity: 'success',
        text: 'Payroll settings have been updated',
        verticalPos: 'bottom',
        horizontalPos: 'center',
      });
    } else {
      setToastData({
        severity: 'error',
        text: 'Could not update payroll settings',
        verticalPos: 'bottom',
        horizontalPos: 'center',
      });
    }

    setLoadingUpdate(false);
    setShowToast(true);
  };

  const handleClear = () => sigPadRef?.current?.clear();

  const getSignBoxWidth = () => {
    let signBoxWidth = 400;
    const termBox = document.getElementById('termBox');

    if (termBox) {
      signBoxWidth = termBox.offsetWidth - 30;
    }

    return signBoxWidth;
  };

  const handleUpdateClick = () => {
    setLoadingUpdate(true);
    updateSettings();
  };

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const getBase64Data = () => {
    if (sigPadRef.current) {
      const imageData =
        sigPadRef.current &&
        sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
      return imageData.substring(imageData.indexOf(',') + 1);
    } else {
      return;
    }
  };

  const uploadImage = async (base64Data: any, imageId: string) => {
    return uploadImageFile(base64Data, imageId);
  };

  const uploadSignature = async () => {
    if (sigPadRef.current && !sigPadRef.current.isEmpty()) {
      const base64Data = getBase64Data();
      const imageId = 'image_id';
      try {
        const uploadedImage = await uploadImage(base64Data, imageId);
        setSignature(uploadedImage);
        setOpenSign(false);
      } catch (err) {
        console.log(err);
      }
    } else {
      setToastData({
        ...toastData,
        severity: 'error',
        text: 'Please sign before confirming',
      });
      setShowToast(true);
    }
  };

  const handleUpdateSignature = () => {
    uploadSignature();
  };

  return (
    <MainViewContainer title="General Payroll Settings">
      {loading ? (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <Tooltip
            title="Which method to use when calculating payroll"
            sx={{alignSelf: 'flex-start'}}
          >
            <Typography variant="h6">Deduction Type</Typography>
          </Tooltip>
          <ToggleButtonGroup
            color="primary"
            value={deductionType}
            exclusive
            onChange={(event: any) => {
              setDeductionType(event.target.value);
            }}
            aria-label="Platform"
            size="small"
          >
            <ToggleButton value="T4032">Tables (T4032)</ToggleButton>
            <ToggleButton value="T4127">Formulas (T4127)</ToggleButton>
          </ToggleButtonGroup>
          <Divider />
          <Typography variant="h6" sx={{alignSelf: 'flex-start'}}>
            Cheque Signature
          </Typography>
          <Box
            component="img"
            sx={{
              height: 300 * 0.7,
              width: getSignBoxWidth() * 0.7,
            }}
            alt="Current Signature"
            src={signature}
          />
          <Stack direction="row">
            <Button
              sx={{width: '100px'}}
              onClick={() => {
                setOpenSign(true);
              }}
            >
              Edit
            </Button>
            <Button
              sx={{width: '100px'}}
              onClick={() => {
                setSignature(settings.general_cheque_signature_url);
              }}
            >
              Reset
            </Button>
          </Stack>
          <Button
            variant="outlined"
            onClick={handleUpdateClick}
            sx={{width: '200px'}}
          >
            {loadingUpdate ? <CircularProgress /> : 'Update Settings'}
          </Button>
        </Box>
      )}
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        severity={toastData.severity}
        text={toastData.text}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
      <GlobalModal
        open={openSign}
        onClose={() => setOpenSign(false)}
        defaultHeader={true}
        title="Cheque Signature"
        width="470px"
      >
        <Stack spacing={2}>
          <>
            <Box
              sx={{
                width: '400px',
                border: '1px solid',
                borderColor: 'secondary.main',
              }}
              id="termBox"
            >
              <ReactSignatureCanvas
                ref={sigPadRef}
                canvasProps={{
                  width: getSignBoxWidth(),
                  height: 300,
                  className: 'sigCanvas',
                }}
              />
            </Box>
            <Button variant="text" onClick={handleClear}>
              Clear
            </Button>
          </>
          <Button variant="outlined" fullWidth onClick={handleUpdateSignature}>
            Save
          </Button>
        </Stack>
      </GlobalModal>
    </MainViewContainer>
  );
};
