/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {AutoComplete} from '../../common/AutoComplete/AutoComplete';
import {DayTiming} from './DayTiming';
import {Categories} from './Categories';
import {useAppointments} from '../../providers/api/useAppointments';
import {ToastStruct} from '../../types/types';
import {Toast} from '../../common/Alerts/Toast';
import {Confirm} from '../../common/Alerts/Confirm';
import {GlobalModal} from '../../common/GlobalModal/GlobalModal';
import {CustomAppointment} from './CustomAppointment';

export const AppointmentsView = () => {
  const [showCategories, setShowCategories] = useState(false);
  const {getSettings, updateSettings} = useAppointments();
  const [settings, setSettings] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState('');
  const [location, setLocation] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });
  const [openCustom, setOpenCustom] = useState(false);

  const getAppSettings = useCallback(async () => {
    const allSettings: any = await getSettings();
    setSettings(allSettings);
    setId(allSettings.id);
    setLocation(
      `${allSettings.street}, ${allSettings.city}, ${allSettings.province} ${allSettings.postal}, ${allSettings.country}`
    );
    setLoading(false);
  }, [getSettings]);

  useEffect(() => {
    getAppSettings();
  }, [getAppSettings]);

  const handleSave = () => {
    delete settings.id;
    delete settings.__typename;
    updateSettings(id, settings).then(() => {
      setOpenConfirm(false);
      setToastData({
        ...toastData,
        severity: 'success',
        text: 'Appointment settings have been updated',
      });
      setShowToast(true);
    });
  };

  const handleLocationSetting = (address: any) => {
    setSettings({...settings, ...address});
  };

  return (
    <MainViewContainer title="Appointments">
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '10px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack spacing={4}>
            <Stack spacing={2}>
              <Typography variant="h5">Categories</Typography>
              <Button
                variant="outlined"
                onClick={() => setShowCategories(true)}
              >
                Edit Appointment Categories
              </Button>
            </Stack>
            <Stack spacing={2}>
              <Typography variant="h5">Location</Typography>
              <Box width="100%">
                <AutoComplete
                  initialValue={location}
                  onChange={address => {
                    handleLocationSetting(address);
                  }}
                />
              </Box>
              <Typography variant="h5">Timezone</Typography>
              <Box width="100%">{`${settings.timezone_id}
                (${(settings.timezone_offset / 60) * -1}hrs)`}</Box>
            </Stack>
            <Stack spacing={2}>
              <Typography variant="h5">Slots</Typography>
              <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button onClick={() => setOpenCustom(true)} variant="outlined">
                  Manage Custom Slots
                </Button>
              </Box>
              <Stack direction="row" spacing={1}>
                <TextField
                  label="Slot Interval"
                  size="small"
                  type="number"
                  InputProps={{inputProps: {min: 0}}}
                  sx={{width: '50%'}}
                  value={settings.interval_minutes}
                  onChange={event =>
                    setSettings({
                      ...settings,
                      interval_minutes: event.target.value,
                    })
                  }
                />
                <TextField
                  label="Bookings per Slot"
                  size="small"
                  type="number"
                  InputProps={{inputProps: {min: 0}}}
                  sx={{width: '50%'}}
                  value={settings.booking_per_slot}
                  onChange={event =>
                    setSettings({
                      ...settings,
                      booking_per_slot: event.target.value,
                    })
                  }
                />
              </Stack>
              <Stack sx={{marginTop: '15px'}}>
                <DayTiming
                  label="Monday"
                  data={{
                    id: settings.id,
                    start: settings.monday_start_time,
                    end: settings.monday_end_time,
                  }}
                  state={settings}
                  updateState={setSettings}
                />
                <DayTiming
                  label="Tuesday"
                  data={{
                    id: settings.id,
                    start: settings.tuesday_start_time,
                    end: settings.tuesday_end_time,
                  }}
                  state={settings}
                  updateState={setSettings}
                />
                <DayTiming
                  label="Wednesday"
                  data={{
                    id: settings.id,
                    start: settings.wednesday_start_time,
                    end: settings.wednesday_end_time,
                  }}
                  state={settings}
                  updateState={setSettings}
                />
                <DayTiming
                  label="Thursday"
                  data={{
                    id: settings.id,
                    start: settings.thursday_start_time,
                    end: settings.thursday_end_time,
                  }}
                  state={settings}
                  updateState={setSettings}
                />
                <DayTiming
                  label="Friday"
                  data={{
                    id: settings.id,
                    start: settings.friday_start_time,
                    end: settings.friday_end_time,
                  }}
                  state={settings}
                  updateState={setSettings}
                />
                <DayTiming
                  label="Saturday"
                  data={{
                    id: settings.id,
                    start: settings.saturday_start_time,
                    end: settings.saturday_end_time,
                  }}
                  state={settings}
                  updateState={setSettings}
                />
                <DayTiming
                  label="Sunday"
                  data={{
                    id: settings.id,
                    start: settings.sunday_start_time,
                    end: settings.sunday_end_time,
                  }}
                  state={settings}
                  updateState={setSettings}
                />
              </Stack>
            </Stack>
          </Stack>
          {showCategories && (
            <Categories
              open={showCategories}
              handleClose={() => setShowCategories(false)}
              setToastData={setToastData}
              setShowToast={setShowToast}
            />
          )}
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Button variant="outlined" onClick={() => setOpenConfirm(true)}>
              Update Settings
            </Button>
          </Box>
        </>
      )}
      <Confirm
        open={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        handleConfirmed={handleSave}
        text="Are you sure you want to update the appointment settings?"
      />
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        text={toastData.text}
        severity={toastData.severity}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
      <GlobalModal
        title="Custom Appointment Slots"
        open={openCustom}
        onClose={() => setOpenCustom(false)}
        defaultHeader={true}
        width="80vw"
        height="85vh"
      >
        <CustomAppointment />
      </GlobalModal>
    </MainViewContainer>
  );
};
