/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  createTenantStaffAttribute,
  listTenantStaffAttributes,
  updateTenantStaffAttribute,
  getTenantStaffAttribute,
} from '@digistaff/business';
import {useCallback} from 'react';
import {app} from '../Account';

export const useStaffCustomAttribute = () => {
  const apiList = useCallback(async () => {
    return new Promise((resolve, reject) => {
      listTenantStaffAttributes(app, {
        filter: {status: {operator: '<>', value: 'deleted'}},
      })
        .then(res => resolve(res.data.listTenantStaffAttributes))
        .catch(err => reject(err));
    });
  }, []);

  const apiRemove = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      updateTenantStaffAttribute(app, id, {status: 'deleted'})
        .then(res => resolve(res.data.updateTenantStaffAttribute))
        .catch(err => reject(err));
    });
  }, []);

  const apiCreate = useCallback((payload: any) => {
    return new Promise((resolve, reject) => {
      if (payload.type === 'text')
        payload = {...payload, unit: null, min: null, max: null};

      createTenantStaffAttribute(app, payload)
        .then(res => resolve(res.data.createTenantStaffAttribute))
        .catch(err => reject(err));
    });
  }, []);

  const apiUpdate = useCallback((id: string, payload: any) => {
    return new Promise((resolve, reject) => {
      if (payload.type === 'text')
        payload = {...payload, unit: null, min: null, max: null};
      updateTenantStaffAttribute(app, id, payload)
        .then(res => resolve(res.data.updateTenantStaffAttribute))
        .catch(err => reject(err));
    });
  }, []);

  const apiGet = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      getTenantStaffAttribute(app, id)
        .then(res => resolve(res.data.getTenantStaffAttribute))
        .catch(err => reject(err));
    });
  }, []);

  return {apiCreate, apiGet, apiList, apiRemove, apiUpdate};
};
