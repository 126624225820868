/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createBusinessLocation,
  deleteBusinessLocation,
  getBusinessLocation,
  updateBusinessLocation,
} from '@digistaff/business';
import {Close, ExpandMore} from '@mui/icons-material';
import {
  AccordionDetails,
  Stack,
  FormControl,
  TextField,
  AccordionSummary,
  Accordion,
  Typography,
  Button,
  FormHelperText,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {AutoComplete} from '../../common/AutoComplete/AutoComplete';
import {app} from '../../providers/Account';
import {BusinessLocation} from './BusinessData';
import {ToastStruct} from '../../types/types';
import {Confirm} from '../../common/Alerts/Confirm';
import validator from 'validator';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';

export interface BusinessRowProps {
  locationId: string;
  businessId: string;
  index: number;
  data?: any;
  deleteLocation: (id: string) => void;
  updateLocation: (id: string, state: BusinessLocation) => void;
  displayToast: (data: ToastStruct) => void;
}
export const BusinessRow = ({
  locationId,
  businessId,
  index,
  data,
  deleteLocation,
  updateLocation,
  displayToast,
}: BusinessRowProps) => {
  const [state, setState] = useState<any>(data);
  const [formatAddress, setFormatAddress] = useState<null | string>(null);
  const [blankFormat, setBlankFormat] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const handleUpdate = (newState: any) => {
    updateLocation(locationId, newState);
  };

  useEffect(() => {
    if (state.street !== '') {
      const formatedAddress = `${state.street}, ${state.city} ${state.province} ${state.postal}, ${state.country}`;

      setFormatAddress(formatedAddress);
    } else {
      setBlankFormat(true);
      setFormatAddress(`Location ${index + 1}`);
    }
  }, [state, index]);

  const setAddress = (address: any) => {
    setState((prevState: any) => {
      handleUpdate({...prevState, ...address});
      return {...prevState, ...address};
    });
  };

  const handleDeleteLocation = () => {
    deleteBusinessLocation(app, locationId)
      .then(res => {
        if (res.data.deleteBusinessLocation.id) {
          deleteLocation(locationId);
          displayToast({
            severity: 'success',
            text: 'Business location has been deleted',
          });
        } else {
          displayToast({
            severity: 'info',
            text: 'Cannot delete a business location that has active shifts',
          });
        }
      })
      .catch(err => console.log(err));
  };

  const closeAccordionAfterTimeout = (timeout: number) => {
    setTimeout(() => {
      setExpanded(false);
    }, timeout);
  };

  const validateForm = (payload: any) => {
    const newErrors: any = {};
    if (payload.contact_name.length < 1) newErrors.name = 'Required';

    if (!validator.isEmail(payload.contact_email)) newErrors.email = 'Required';

    if (!isValidPhoneNumber(payload.contact_phone))
      newErrors.phone = 'Required';

    if (payload.street.length < 1) newErrors.street = 'Required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdateLocation = () => {
    const payload: BusinessLocation = {
      business_id: businessId,
      contact_name: state.contact_name,
      contact_email: state.contact_email,
      contact_phone: state.contact_phone,
      longitude: state.longitude,
      latitude: state.latitude,
      country: state.country,
      province: state.province,
      postal: state.postal,
      city: state.city,
      street: state.street,
    };

    if (validateForm(payload)) {
      getBusinessLocation(app, locationId)
        .then(res => {
          if (res.data.getBusinessLocation) {
            updateBusinessLocation(app, locationId, payload)
              .then(() => {
                displayToast({
                  severity: 'success',
                  text: 'Updated Business Location',
                });
                closeAccordionAfterTimeout(1500);
              })
              .catch(err => console.log(err));
          } else {
            createBusinessLocation(app, payload)
              .then(() => {
                displayToast({
                  severity: 'success',
                  text: 'Created Business Location',
                });
                closeAccordionAfterTimeout(1500);
              })
              .catch(err => console.log(err));
          }
        })
        .catch(err => console.log(err));
    }
  };

  return (
    <>
      {data !== null && (
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel-content"
            id="panel-header"
            onClick={() => setExpanded(!expanded)}
          >
            <Stack spacing={2} direction="row">
              <Close onClick={() => setShowConfirm(true)} />
              <Typography>{formatAddress}</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="column" spacing={2}>
              <FormControl>
                {formatAddress !== null && (
                  <>
                    <AutoComplete
                      placeHolder="Business Address"
                      initialValue={blankFormat ? '' : formatAddress}
                      onChange={address => {
                        setAddress(address);
                      }}
                    />
                    {'street' in errors && (
                      <FormHelperText sx={{color: 'red'}}>
                        {errors.street}
                      </FormHelperText>
                    )}
                  </>
                )}
              </FormControl>
              <FormControl>
                <TextField
                  label="Contact Name"
                  variant="outlined"
                  size="small"
                  error={'name' in errors}
                  helperText={errors.name}
                  value={state.contact_name}
                  onChange={event => {
                    setState({...state, contact_name: event.target.value});
                    handleUpdate({...state, contact_name: event.target.value});
                  }}
                />
              </FormControl>
              <FormControl>
                <TextField
                  label="Contact Email"
                  variant="outlined"
                  size="small"
                  error={'email' in errors}
                  helperText={errors.email}
                  value={state.contact_email}
                  onChange={event => {
                    setState({...state, contact_email: event.target.value});
                    handleUpdate({...state, contact_email: event.target.value});
                  }}
                />
              </FormControl>
              <FormControl>
                <PhoneInput
                  placeholder="Phone number"
                  international
                  countryCallingCodeEditable
                  limitMaxLength
                  value={state.contact_phone}
                  onChange={event => {
                    setState({...state, contact_phone: event});
                    handleUpdate({...state, contact_phone: event});
                  }}
                />
                {'phone' in errors && (
                  <FormHelperText sx={{color: 'red'}}>
                    {errors.phone}
                  </FormHelperText>
                )}
              </FormControl>
              <Button variant="outlined" onClick={handleUpdateLocation}>
                Save
              </Button>
            </Stack>
          </AccordionDetails>
        </Accordion>
      )}
      <Confirm
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        handleConfirmed={handleDeleteLocation}
        text="Are you sure you want to delete this location?"
      />
    </>
  );
};
