import {useCallback, useEffect, useState} from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {SortableItem} from './SortableItem';
import {Box, Button, Divider, Grid, Stack, TextField} from '@mui/material';
import {GlobalModal} from '../../common/GlobalModal/GlobalModal';
import {Confirm} from '../../common/Alerts/Confirm';
import {StaticItem} from './StaticItem';
import {
  createDigiscreenColumn,
  deleteDigiscreenColumn,
  listDigiscreenColumns,
  updateDigiscreenColumn,
} from '@digistaff/business';
import {app} from '../../providers/Account';
import {listStaffProfileTenants} from '@digistaff/staff';
import {Toast} from '../../common/Alerts/Toast';
import {ToastStruct} from '../../types/types';

export interface HeaderStruct {
  created_at?: string;
  id: string;
  name: string;
  position: string;
  updated_at?: string;
  __typename?: string;
}

export const emptyHeader: HeaderStruct = {
  created_at: '',
  id: '',
  name: '',
  position: '',
  updated_at: '',
  __typename: '',
};

export const DigiScreenSettings = () => {
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [header, setHeader] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [items, setItems] = useState<HeaderStruct[]>([emptyHeader]);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    verticalPos: 'bottom',
    horizontalPos: 'center',
    severity: 'info',
    text: '',
  });

  const getColumns = useCallback(() => {
    listDigiscreenColumns(app)
      .then(res => {
        const tempItems = res.data.listDigiscreenColumns;
        setItems(sortItems(tempItems));
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    getColumns();
  }, [getColumns]);

  const sortItems = (list: HeaderStruct[]): HeaderStruct[] => {
    return [...list].sort((a, b) => {
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
      return 0;
    });
  };

  const insertNewItem = () => {
    createDigiscreenColumn(app, {
      name: header,
      position: items.length.toString(),
    })
      .then(() => getColumns())
      .catch(err => console.log(err));
  };

  const handleSaveClick = () => {
    insertNewItem();
    setShow(false);
    setHeader('');
  };

  const handleDeleteClicked = async (id: string) => {
    const result = await listStaffProfileTenants(app, {
      filter: {verification_status: id},
    });
    if (result.data.listStaffProfileTenants.length > 0) {
      setToastData({
        ...toastData,
        severity: 'warning',
        text: 'You cannot delete a DigiScreen column that has staff in it. Please move all staff out of this column on the business protal before you delete it',
      });
      setShowToast(true);
    } else {
      setDeleteId(id);
      setShowConfirm(true);
    }
  };

  useEffect(() => {
    const newItems = [...items].map((item, index) => {
      return {...item, position: index.toString()};
    });

    newItems.forEach(item => {
      updateDigiscreenColumn(app, item.id, {
        name: item.name,
        position: item.position,
      });
    });
  }, [items]);

  const handleDelete = () => {
    deleteDigiscreenColumn(app, deleteId)
      .then(() => {
        getColumns();
      })
      .catch(err => console.log(err));
    setShowConfirm(false);
  };

  const handleEdit = (id: string, newValue: string) => {
    const item = items.find(item => item.id === id);
    if (item) {
      updateDigiscreenColumn(app, id, {
        name: newValue,
        position: item.position,
      })
        .then(() => getColumns())
        .catch(err => console.log(err));
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const {active, over} = event;
    if (active.id !== over?.id) {
      setItems(items => {
        const oldIndex = items.findIndex(i => i.id === active.id);
        const newIndex = items.findIndex(i => i.id === over?.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  return (
    <>
      <Box
        sx={{display: 'flex', justifyContent: 'flex-end', marginBottom: '15px'}}
      >
        <Button variant="outlined" onClick={() => setShow(true)}>
          Insert New Column
        </Button>
      </Box>
      <Box>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            <Grid container direction="column" gap={1}>
              <StaticItem key="rejected" itemId="rejected" header="Rejected" />
              <StaticItem
                key="pending"
                itemId="pending"
                header="Pending Verification"
              />
              {items.map(item => (
                <Grid item xs={6} key={item.id}>
                  <SortableItem
                    key={item.id}
                    itemId={item.id}
                    header={item.name}
                    handleEdit={handleEdit}
                    handleDelete={handleDeleteClicked}
                  />
                </Grid>
              ))}
              <StaticItem key="approved" itemId="approved" header="Approved" />
            </Grid>
          </SortableContext>
        </DndContext>
      </Box>
      <Divider />
      <GlobalModal
        open={show}
        onClose={() => setShow(false)}
        defaultHeader={true}
        title="New Column Header"
        width="40vw"
      >
        <Stack spacing={2}>
          <TextField
            label="Header"
            size="small"
            fullWidth
            value={header}
            onChange={event => setHeader(event.target.value)}
          />
          <Button variant="contained" onClick={handleSaveClick}>
            Save
          </Button>
        </Stack>
      </GlobalModal>
      <Confirm
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        handleConfirmed={handleDelete}
        text="Are you sure you want to delete this header?"
      />
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        {...toastData}
      />
    </>
  );
};
