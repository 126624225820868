/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Grid, Stack} from '@mui/material';
import {AppBar, Drawer, DrawerHeader} from './MainViewContainerStyles';
import {NewDigistaffLogoFullWhite} from '../Image/Image';
import {useNavigate} from 'react-router-dom';
import {NestedList} from './NestedList';
import {sideBarItemList} from './sideBarItemList';
import {AccountMenu} from './AccountMenu';
import {AppsMenu} from './AppMenu';

export interface MainViewContainerProps {
  title: string;
  children: React.ReactNode;
}

/**
 * Container that handles the structure and rendering of views
 *
 * @param title - Title of the page to be rendered at the top
 * @param children - JSX element to render under the Title
 */
export const MainViewContainer = ({
  title,
  children,
}: MainViewContainerProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{display: 'flex'}}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center" spacing={0}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 2,
                    ...(open && {display: 'none'}),
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Stack>
              <Box
                component="img"
                onClick={() => navigate('/')}
                sx={{height: '4.5rem', cursor: 'pointer'}}
                alt="Digistaff Logo"
                src={NewDigistaffLogoFullWhite}
                display="flex"
                alignItems="center"
              />
              <Stack direction="row" spacing={1}>
                <AppsMenu />
                <AccountMenu />
              </Stack>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <Box onClick={handleDrawerOpen}>
              {sideBarItemList.map((sideBarItem: any) => (
                <NestedList
                  key={sideBarItem.unCollapsedItem.name}
                  unCollapsedItem={sideBarItem.unCollapsedItem}
                  collapsedItems={sideBarItem.collapsedItems}
                />
              ))}
            </Box>
          </List>
          <Divider />
        </Drawer>
        <Box sx={{flexGrow: 1, p: 5}}>
          <DrawerHeader />
          <Typography variant="h4" component="h2" sx={{marginBottom: '30px'}}>
            {title}
          </Typography>
          {children}
        </Box>
      </Box>
    </>
  );
};
