import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  TextField,
  Box,
  CircularProgress,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {useCallback, useEffect, useState} from 'react';
import {listPayrollSettings, updatePayrollSetting} from '@digistaff/business';
import {app} from '../../providers/Account';
import {Toast} from '../../common/Alerts/Toast';
import {ToastStruct} from '../../types/types';

export const Automation = () => {
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [id, setId] = useState('');
  const [frequency, setFrequency] = useState(0);
  const [dayEnding, setDayEnding] = useState(0);
  const [payDelay, setPayDelay] = useState(0);
  const [enabled, setEnabled] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });

  const getSettings = useCallback(async () => {
    const result = await listPayrollSettings(app);
    const settings = result.data.listPayrollSettings[0];

    setId(settings.id);
    setFrequency(settings.automation_pay_period_frequency);
    setDayEnding(settings.automation_pay_day_ending);
    setPayDelay(settings.automation_pay_run_after_day_ending);
    setEnabled(settings.status === 'active');
    setLoading(false);
  }, []);

  const updateSettings = useCallback(async () => {
    const payload = {
      automation_pay_period_frequency: frequency,
      automation_pay_day_ending: dayEnding,
      automation_pay_run_after_day_ending: payDelay,
      status: enabled ? 'active' : 'inactive',
    };

    const result = await updatePayrollSetting(app, id, payload);
    setLoadingUpdate(false);

    if (result.data.updatePayrollSetting.id) {
      setToastData({
        severity: 'success',
        text: 'Payroll automation settings have been updated',
        verticalPos: 'bottom',
        horizontalPos: 'center',
      });
    } else {
      setToastData({
        severity: 'error',
        text: 'Could not update payroll automation settings',
        verticalPos: 'bottom',
        horizontalPos: 'center',
      });
    }

    setShowToast(true);
  }, [dayEnding, enabled, frequency, id, payDelay]);

  const handleUpdateClick = () => {
    setLoadingUpdate(true);
    updateSettings();
  };

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <MainViewContainer title="Payroll Automation Settings">
      {loading ? (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={1} rowSpacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enabled}
                  onClick={() => setEnabled(!enabled)}
                />
              }
              label="Enable Automatic Payroll"
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Tooltip title="How often to run the payroll cycle" placement="top">
              <FormControl size="small" fullWidth>
                <InputLabel id="freqLbl">Pay Frequency</InputLabel>
                <Select
                  labelId="freqLbl"
                  id="freq"
                  label="Pay Frequency"
                  disabled={!enabled}
                  value={frequency}
                  onChange={event => setFrequency(+event.target.value)}
                >
                  <MenuItem value={52}>Weekly (52 pay periods)</MenuItem>
                  <MenuItem value={26}>Bi-weekly (26 pay periods)</MenuItem>
                </Select>
              </FormControl>
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={5}>
            <Tooltip title="Last day of the payroll cycle" placement="top">
              <FormControl size="small" fullWidth>
                <InputLabel id="dayLbl">Day Ending</InputLabel>
                <Select
                  labelId="dayLbl"
                  id="day"
                  label="Day Ending"
                  disabled={!enabled}
                  value={dayEnding}
                  onChange={event => setDayEnding(+event.target.value)}
                >
                  <MenuItem value={0}>Sunday</MenuItem>
                  <MenuItem value={1}>Monday</MenuItem>
                  <MenuItem value={2}>Tuesday</MenuItem>
                  <MenuItem value={3}>Wednesday</MenuItem>
                  <MenuItem value={4}>Thursday</MenuItem>
                  <MenuItem value={5}>Friday</MenuItem>
                  <MenuItem value={6}>Saturday</MenuItem>
                </Select>
              </FormControl>
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={2}>
            <Tooltip
              title="How many days after the 'Day Ending' to automatically run the payroll"
              placement="top"
            >
              <FormControl size="small" fullWidth>
                <TextField
                  type="number"
                  label="Pay Delay (days)"
                  size="small"
                  disabled={!enabled}
                  value={payDelay}
                  onChange={event => setPayDelay(+event.target.value)}
                  InputProps={{inputProps: {min: 0}}}
                />
              </FormControl>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="outlined" onClick={handleUpdateClick}>
              {loadingUpdate ? <CircularProgress /> : 'Update'}
            </Button>
          </Grid>
        </Grid>
      )}
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        severity={toastData.severity}
        text={toastData.text}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
    </MainViewContainer>
  );
};
