/* eslint-disable @typescript-eslint/no-explicit-any */
import {useCallback, useEffect, useState} from 'react';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import {Toast} from '../../common/Alerts/Toast';
import {ToastStruct} from '../../types/types';
import {useCertifications} from '../../providers/api/useCertifications';

export const EnabledCertificationsView = () => {
  const [loading, setLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });
  const [masterCerts, setMasterCerts] = useState([]);
  const [tenantCerts, setTenantCerts] = useState([
    {id: '2', name: 'Test', is_compliant_required: false},
  ]);
  const {
    listMasterCertifications,
    listTenantCertifications,
    createTenantCertification,
    deleteTenantCertification,
    updateTenantCertification,
  } = useCertifications();

  const getData = useCallback(async () => {
    const certs: any = await listMasterCertifications();
    setMasterCerts(certs);

    const tCerts: any = await listTenantCertifications();
    setTenantCerts(tCerts);

    setLoading(false);
  }, [listMasterCertifications, listTenantCertifications]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleCertificationClicked = (cert: any) => {
    const exists = tenantCerts.findIndex(c => c.id === cert.id) > -1;

    if (exists) {
      setToastData({
        ...toastData,
        severity: 'success',
        text: 'Certification was disabled',
      });
      setTenantCerts(oldValue =>
        oldValue.filter((current: any) => current.id !== cert.id)
      );
      deleteTenantCertification(cert.id);
    } else {
      setToastData({
        ...toastData,
        severity: 'success',
        text: 'Certification was enabled',
      });
      const newValues: any = [...tenantCerts, {id: cert.id}];
      setTenantCerts(newValues);
      createTenantCertification(cert.id);
    }
    setShowToast(true);
  };

  const handleComplianceClicked = (cert: any) => {
    const currentState =
      tenantCerts.findIndex(c => c.id === cert.id && c.is_compliant_required) >
      -1;

    updateTenantCertification(cert.id, {
      is_compliant_required: !currentState,
    })
      .then(() => {
        setToastData({
          ...toastData,
          severity: 'success',
          text: 'Certification was updated',
        });
        setShowToast(true);
        getData();
      })
      .catch(err => console.log('err', err));
  };

  return (
    <MainViewContainer title="Enabled Certifications">
      {loading ? (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography>
            Enable a certification below if you would like to verify it on
            DigiCheck
          </Typography>
          <Grid container columns={12}>
            {masterCerts.map((cert: any) => (
              <Grid
                item
                key={cert.id}
                sx={{border: 'solid 1px black', p: 2, m: 1}}
                xs={5}
              >
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        label={cert.name}
                        control={
                          <Checkbox
                            checked={
                              tenantCerts.findIndex(c => c.id === cert.id) > -1
                            }
                            onClick={() => handleCertificationClicked(cert)}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" justifyContent="flex-end">
                        <FormControlLabel
                          style={{textAlign: 'right'}}
                          label={'Required Compliance'}
                          control={
                            <Checkbox
                              disabled={
                                !(
                                  tenantCerts.findIndex(c => c.id === cert.id) >
                                  -1
                                )
                              }
                              checked={
                                tenantCerts.findIndex(
                                  c =>
                                    c.id === cert.id && c.is_compliant_required
                                ) > -1
                              }
                              onClick={() => handleComplianceClicked(cert)}
                            />
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      )}
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        text={toastData.text}
        severity={toastData.severity}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
    </MainViewContainer>
  );
};
