import {GridColDef} from '@mui/x-data-grid';

export interface BusinessStruct {
  id: string;
  name: string;
}

export const businessGridCols: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
];

export const emptyBusiness: BusinessStruct = {
  id: '',
  name: '',
};

export interface BusinessLocation {
  id?: string;
  business_id?: string;
  contact_name?: string;
  contact_email?: string;
  contact_phone?: string;
  street?: string;
  city?: string;
  postal?: string;
  province?: string;
  country?: string;
  geohash?: string;
  latitude?: number;
  longitude?: number;
  precision?: number;
}

export const emptyLocation: BusinessLocation = {
  id: '',
  contact_email: '',
  contact_name: '',
  contact_phone: '',
  city: '',
  country: '',
  latitude: 0,
  longitude: 0,
  postal: '',
  province: '',
  street: '',
};
