import {GridColDef} from '@mui/x-data-grid';
import {nanoid} from 'nanoid';

export const certificationsGridCols: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
];

export interface CertificationStruct {
  id: string;
  name: string;
  description: string;
}

export const emptyCertification: CertificationStruct = {
  id: nanoid(),
  name: '',
  description: '',
};
