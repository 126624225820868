/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box} from '@mui/material';

export const TabPanel = (props: {[key: string]: any}) => {
  const {children, value, index, ...other} = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index ? <Box sx={{p: 3}}>{children}</Box> : null}
    </Box>
  );
};
