import {AppSettingsAlt, Apps, Business} from '@mui/icons-material';
import {useCallback, useEffect, useState} from 'react';
import {
  Box,
  IconButton,
  MenuItem,
  ListItemIcon,
  Menu,
  Tooltip,
} from '@mui/material';
import {app, getCurrentToken} from '../../providers/Account';
import {getOpsProfile, getOpsProfileTenant} from '@digistaff/business';
import {getCurrentLoggedOps} from '../../providers/api/ops';

export const AppsMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isLocal = window.location.origin === process.env.LOCAL_ADMIN_URL;
  const [isInternal, setIsInternal] = useState(false);

  const getRole = useCallback(async () => {
    const res = await getOpsProfile(app, getCurrentLoggedOps());
    if (res.data.getOpsProfile?.is_internal_user) setIsInternal(true);
  }, []);

  useEffect(() => {
    getRole();
  }, [getRole]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBusinessRedirection = async (newTab: boolean) => {
    const tenantProfile = await getOpsProfileTenant(app, getCurrentLoggedOps());

    const role = tenantProfile.data.getOpsProfileTenant.role;
    const status = tenantProfile.data.getOpsProfileTenant.status;
    const session = getCurrentToken(app);
    const redirectBaseUrl = isLocal
      ? process.env.LOCAL_BUSINESS_URL
      : process.env.DS_BUSINESS_URL;

    const url = `${redirectBaseUrl}?tenant=${app.tenantKey}&username=${session.username}&accessToken=${session.accessToken}&idToken=${session.idToken}&refreshToken=${session.refreshToken}&role=${role}&status=${status}`;

    if (newTab) window.open(url, '_blank');
    else window.location.href = url;
  };

  const handleInternalRedirection = async (newTab: boolean) => {
    const tenantProfile = await getOpsProfileTenant(app, getCurrentLoggedOps());

    const role = tenantProfile.data.getOpsProfileTenant.role;
    const status = tenantProfile.data.getOpsProfileTenant.status;
    const session = getCurrentToken(app);
    const redirectBaseUrl = isLocal
      ? process.env.LOCAL_INTERNAL_URL
      : process.env.DS_INTERNAL_URL;

    const url = `${redirectBaseUrl}?tenant=${app.tenantKey}&username=${session.username}&accessToken=${session.accessToken}&idToken=${session.idToken}&refreshToken=${session.refreshToken}&role=${role}&status=${status}`;

    if (newTab) window.open(url, '_blank');
    else window.location.href = url;
  };

  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        <Tooltip title="Apps">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'apps-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Apps sx={{fontSize: '1.75em', color: 'white'}} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="apps-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            marginTop: '10px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem
          onClick={() => handleBusinessRedirection(false)}
          onAuxClick={() => handleBusinessRedirection(true)}
        >
          <ListItemIcon>
            <Business fontSize="small" />
          </ListItemIcon>
          Business
        </MenuItem>
        {isInternal && (
          <MenuItem
            onClick={() => handleInternalRedirection(false)}
            onAuxClick={() => handleInternalRedirection(true)}
          >
            <ListItemIcon>
              <AppSettingsAlt fontSize="small" />
            </ListItemIcon>
            Internal
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
