import {Card, CardContent, Stack, Typography} from '@mui/material';

export interface SortableProps {
  itemId: string;
  header: string;
}
export function StaticItem({header}: SortableProps) {
  return (
    <>
      <Card>
        <CardContent
          sx={{
            '&:last-child': {
              paddingBottom: '16px',
            },
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              spacing={1}
              direction="row"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#C7C7C7',
              }}
            >
              <Typography variant="h6">{header}</Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
