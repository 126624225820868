/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {useCallback, useEffect, useState} from 'react';
import {useContract} from '../../providers/api/useContract';
import moment from 'moment';
import {DeleteForever, Edit} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {Confirm} from '../../common/Alerts/Confirm';
import {Toast} from '../../common/Alerts/Toast';
import {ToastStruct} from '../../types/types';

export const ContractView = () => {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const {listContracts, deleteContract} = useContract();
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState<null | string>(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });

  const handleAddNewContract = () => {
    navigate('/contracts/manage');
  };

  const handleEditContract = (id: string) => {
    navigate('/contracts/manage', {state: {id}});
  };

  const handleDeleteContract = () => {
    setConfirmDelete(false);
    if (deleteId !== null) {
      deleteContract(deleteId)
        .then(() => {
          setToastData({
            ...toastData,
            severity: 'success',
            text: 'Contract has been deleted',
          });
        })
        .catch(err => {
          setToastData({
            ...toastData,
            severity: 'error',
            text: err,
          });
        })
        .finally(() => {
          setDeleteId(null);
          setShowToast(true);
          getData();
        });
    }
  };

  const getData = useCallback(async () => {
    const contractList: any = await listContracts();
    setContracts(contractList);
    setLoading(false);
  }, [listContracts]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <MainViewContainer title="Contract Settings">
      {loading ? (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 2}}>
            <Button variant="outlined" onClick={handleAddNewContract}>
              Add New Contract
            </Button>
          </Box>
          {contracts.map((contract: any) => (
            <Card key={contract.id} sx={{mb: 2.5}}>
              <CardContent>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography
                    sx={{fontSize: 14}}
                    color="text.secondary"
                    gutterBottom
                  >
                    {moment(contract.created_at).format('DD/MM/YYYY')}
                  </Typography>
                  <Stack spacing={2} direction="row">
                    <Tooltip title="Edit">
                      <Edit
                        sx={{cursor: 'pointer'}}
                        onClick={() => handleEditContract(contract.id)}
                      />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <DeleteForever
                        sx={{cursor: 'pointer'}}
                        onClick={() => {
                          setDeleteId(contract.id);
                          setConfirmDelete(true);
                        }}
                      />
                    </Tooltip>
                  </Stack>
                </Box>
                <Typography variant="h5" component="div">
                  {contract.name}
                </Typography>
                <Typography sx={{mb: 1}} color="text.secondary">
                  Status: {contract.status}
                </Typography>
                <Typography variant="body2">{contract.description}</Typography>
              </CardContent>
            </Card>
          ))}
        </>
      )}
      <Confirm
        open={confirmDelete}
        handleClose={() => setConfirmDelete(false)}
        handleConfirmed={handleDeleteContract}
        text="Are you sure you want to delete this contract?"
      />
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        text={toastData.text}
        severity={toastData.severity}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
    </MainViewContainer>
  );
};
