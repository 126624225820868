/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  OutlinedInput,
  Chip,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import {GlobalModal} from '../../../common/GlobalModal/GlobalModal';
import PhoneInput from 'react-phone-number-input';
import {emptyUser, UserStruct} from '../UserViewData';
import {useCallback, useEffect, useState} from 'react';
import {useOps} from '../../../providers/api/useOps';
import {ToastStruct} from '../../../types/types';
import {useBusiness} from '../../../providers/api/useBusiness';
import validator from 'validator';
import {Confirm} from '../../../common/Alerts/Confirm';
import {useRole} from '../../../providers/api/useRole';
import {RoleStruct} from '../../RolesView/rolesViewData';

export interface ManageModalProps {
  title: string;
  open: boolean;
  handleClose: () => void;
  handleToast: (data: ToastStruct) => void;
  user?: UserStruct;
}

export const ManageModal = ({
  title,
  open,
  handleClose,
  user,
  handleToast,
}: ManageModalProps) => {
  const [profile, setProfile] = useState<any>(emptyUser);
  const [errors, setErrors] = useState<any>({});
  const {
    inviteOp,
    updateOpTenant,
    updateOpProfile,
    deleteOp,
    getCurrentLoggedOps,
  } = useOps();
  const {listBusiness} = useBusiness();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);
  const {listAllRoles} = useRole();
  const [roles, setRoles] = useState<RoleStruct[]>([]);

  const handleInviteClicked = async () => {
    const currentErrors: any = {};

    if (validator.isEmpty(profile.id))
      currentErrors.email = 'Email is required';
    else if (!validator.isEmail(profile.id))
      currentErrors.email = 'Valid email is required';

    if (validator.isEmpty(profile.role))
      currentErrors.role = 'Role is required';

    if (
      roles.find(role => role.id === profile.role)?.is_location_bound &&
      (profile.business_location === undefined ||
        profile.business_location.length < 1)
    )
      currentErrors.supervisor = 'Please select at least one location';

    setErrors(currentErrors);
    if (Object.keys(currentErrors).length === 0) {
      try {
        const inviteStatus: any = await inviteOp({
          id: profile.id.toLowerCase(),
          role: profile.role,
          status: 'created',
          business_location: profile.business_location,
        });

        setLoadingInvite(false);
        setProfile(emptyUser);
        if (inviteStatus === 'already_invited') {
          handleToast({
            severity: 'info',
            text: `${profile.id} has already been invited`,
            verticalPos: 'bottom',
            horizontalPos: 'center',
          });
        } else if (inviteStatus === 'google') {
          handleToast({
            severity: 'success',
            text: `${profile.id} has been invited, and can now log in using the Google sign in`,
            verticalPos: 'bottom',
            horizontalPos: 'center',
          });
        } else if (inviteStatus === 'existing_invited') {
          handleToast({
            severity: 'success',
            text: `${profile.id} has been invited`,
            verticalPos: 'bottom',
            horizontalPos: 'center',
          });
        } else {
          handleToast({
            severity: 'success',
            text: `${profile.id} has been invited, and an email has been sent to them with a temporary password`,
            verticalPos: 'bottom',
            horizontalPos: 'center',
          });
        }
      } catch (err: any) {
        console.log(err);
        handleToast({
          severity: 'error',
          text: err.err.message,
          verticalPos: 'bottom',
          horizontalPos: 'center',
        });
      }
    } else {
      setLoadingInvite(false);
    }
  };

  const handleUpdateClicked = async () => {
    const id = profile.id;
    const tenant_payload = {
      role: profile.role,
      business_location: profile.business_location,
    };
    const profile_payload = {
      first_name: profile.nestGetOpsProfile.first_name,
      last_name: profile.nestGetOpsProfile.last_name,
      phone: profile.nestGetOpsProfile.phone,
    };

    setShowConfirmUpdate(false);
    const updateTenantStatus: any = await updateOpTenant(id, tenant_payload);
    const updateProfileStatus: any = await updateOpProfile(id, profile_payload);
    if (
      updateTenantStatus === 'updated_tenant' &&
      updateProfileStatus === 'updated_profile'
    ) {
      setLoadingUpdate(false);
      handleToast({
        severity: 'success',
        text: `${profile.id} has been updated`,
        verticalPos: 'bottom',
        horizontalPos: 'center',
      });
    } else {
      handleToast({
        severity: 'error',
        text: updateTenantStatus,
        verticalPos: 'bottom',
        horizontalPos: 'center',
      });
    }
  };

  const handleDeleteClicked = async () => {
    setShowConfirmDelete(false);
    if (profile.id !== getCurrentLoggedOps()) {
      const deleteStatus: any = await deleteOp(profile.id);
      if (deleteStatus === 'deleted') {
        handleToast({
          severity: 'success',
          text: `${profile.id} has been deleted`,
          verticalPos: 'bottom',
          horizontalPos: 'center',
        });
      } else {
        handleToast({
          severity: 'error',
          text: deleteStatus,
          verticalPos: 'bottom',
          horizontalPos: 'center',
        });
      }
      handleModalClose();
    } else {
      handleToast({
        severity: 'warning',
        text: 'You cannot delete your own account',
        verticalPos: 'bottom',
        horizontalPos: 'center',
      });
    }
  };

  const handleModalClose = () => {
    setProfile(emptyUser);
    handleClose();
  };

  const getRoles = useCallback(async () => {
    const roleList: RoleStruct[] = await listAllRoles();
    setRoles(roleList);
    setLoading(false);
  }, [listAllRoles]);

  const getLocations = useCallback(async () => {
    const result: any = await listBusiness();
    const locationsList: any = [];
    result.forEach((business: any) => {
      business.nestListBusinessLocation.forEach((location: any) => {
        locationsList.push({business_name: business.name, ...location});
      });
    });
    setLocations(locationsList);
  }, [listBusiness]);

  useEffect(() => {
    if (open) {
      getLocations();
      if (user) setProfile(user);
    }
  }, [open, user, setProfile, getLocations]);

  useEffect(() => {
    if (open) getRoles();
  }, [open, getRoles]);

  return (
    <GlobalModal
      open={open}
      onClose={handleModalClose}
      defaultHeader={true}
      title={title}
      width="70vw"
    >
      {loading ? (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Grid
            container
            spacing={2}
            sx={{display: 'flex', justifyContent: 'center'}}
          >
            {user && (
              <>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    size="small"
                    value={
                      profile.nestGetOpsProfile
                        ? profile.nestGetOpsProfile.first_name
                        : '-'
                    }
                    onChange={event =>
                      setProfile({
                        ...profile,
                        nestGetOpsProfile: {
                          ...profile.nestGetOpsProfile,
                          first_name: event.target.value,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    size="small"
                    value={
                      profile.nestGetOpsProfile
                        ? profile.nestGetOpsProfile.last_name
                        : '-'
                    }
                    onChange={event =>
                      setProfile({
                        ...profile,
                        nestGetOpsProfile: {
                          ...profile.nestGetOpsProfile,
                          last_name: event.target.value,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    placeholder="Input phone number"
                    international
                    countryCallingCodeEditable
                    defaultCountry="CA"
                    limitMaxLength
                    value={
                      profile.nestGetOpsProfile
                        ? profile.nestGetOpsProfile.phone
                        : '-'
                    }
                    onChange={event =>
                      setProfile({
                        ...profile,
                        nestGetOpsProfile: {
                          ...profile.nestGetOpsProfile,
                          phone: event,
                        },
                      })
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                size="small"
                disabled={title === 'Edit User'}
                error={'email' in errors}
                helperText={errors.email}
                value={profile.id}
                onChange={event =>
                  setProfile({...profile, id: event.target.value.trim()})
                }
              />
            </Grid>
            {roles && (
              <Grid item xs={12}>
                <FormControl fullWidth={true} size="small">
                  <InputLabel id="rolelbl">Role</InputLabel>
                  <Select
                    id="role"
                    labelId="rolelbl"
                    label="Role"
                    error={'role' in errors}
                    value={profile.role}
                    onChange={event =>
                      setProfile({...profile, role: event.target.value})
                    }
                  >
                    {roles.length === 0 && (
                      <MenuItem disabled value="">
                        No Options
                      </MenuItem>
                    )}
                    {roles.map(role => (
                      <MenuItem key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {'role' in errors && (
                    <FormHelperText sx={{color: 'red'}}>
                      {errors.role}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            )}
            {roles.find(role => role.id === profile.role)
              ?.is_location_bound && (
              <Grid item xs={12}>
                <FormControl fullWidth={true} size="small">
                  <InputLabel id="assignlbl">Assign Locations</InputLabel>
                  <Select
                    labelId="assignlbl"
                    id="required"
                    error={'supervisor' in errors}
                    multiple
                    value={profile.business_location || []}
                    onChange={event => {
                      if (Array.isArray(event.target.value))
                        setProfile({
                          ...profile,
                          business_location: event.target.value,
                        });
                    }}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Assign Locations"
                      />
                    }
                    renderValue={selected => (
                      <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                        {selected.map((value: any) => {
                          const item: any = locations.find(
                            (loc: any) => loc.id === value
                          );
                          if (item)
                            return (
                              <Chip
                                key={item.id}
                                label={`${item.street} (${item.business_name})`}
                              />
                            );
                          else return '';
                        })}
                      </Box>
                    )}
                  >
                    {locations.map((loc: any) => {
                      return (
                        <MenuItem key={loc.id} value={loc.id}>
                          <Checkbox
                            checked={
                              profile.business_location
                                ? profile.business_location.indexOf(loc.id) > -1
                                : false
                            }
                          />
                          {`${loc.street} (${loc.business_name})`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {'supervisor' in errors && (
                    <FormHelperText sx={{color: 'red'}}>
                      {errors.supervisor}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            )}
            {title === 'Invite' && (
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    setLoadingInvite(true);
                    handleInviteClicked();
                  }}
                >
                  {loadingInvite ? <CircularProgress /> : 'Invite'}
                </Button>
              </Grid>
            )}
            {title === 'Edit User' && (
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => setShowConfirmUpdate(true)}
                >
                  {loadingUpdate ? <CircularProgress /> : 'Update'}
                </Button>
              </Grid>
            )}
            {title === 'Edit User' && (
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => setShowConfirmDelete(true)}
                >
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      <Confirm
        open={showConfirmUpdate}
        handleClose={() => setShowConfirmUpdate(false)}
        handleConfirmed={() => {
          setLoadingUpdate(true);
          handleUpdateClicked();
        }}
        text="Are you sure you want to update this ops?"
      />
      <Confirm
        open={showConfirmDelete}
        handleClose={() => setShowConfirmDelete(false)}
        handleConfirmed={handleDeleteClicked}
        text="Are you sure you want to delete this ops?"
      />
    </GlobalModal>
  );
};
