import {HorizontalTabs} from '../../common/Tabs/HorizontalTabs';
import {CreateAppointment} from './CreateAppointment';
import {ViewCustom} from './ViewCustom';

export const CustomAppointment = () => {
  return (
    <HorizontalTabs labels={['Manage', 'Create New']}>
      <ViewCustom />
      <CreateAppointment />
    </HorizontalTabs>
  );
};
