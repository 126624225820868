/* eslint-disable @typescript-eslint/no-explicit-any */
import {styled, Tab, Tabs} from '@mui/material';

export const StylesTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#=',
  },
});

export const StyledTab = styled((props: any) => (
  <Tab disableRipple {...props} />
))(({theme}) => ({
  textTransform: 'none',
  minWidth: 0,
  backgroundColor: 'transparent',
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: '#000',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    textDecoration: 'underline',
    opacity: 1,
  },
  '&.Mui-selected': {
    fontWeight: theme.typography.fontWeightMedium,
    paddingBottom: 10,
  },
  '&.Mui-focusVisible': {
    color: '#000',
  },
}));
