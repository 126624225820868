import {Button, Stack, Typography} from '@mui/material';
import {GlobalModal} from '../GlobalModal/GlobalModal';

export interface ConfirmProps {
  open: boolean;
  text: string;
  handleClose: () => void;
  handleConfirmed: () => void;
}

export const Confirm = ({
  open,
  text,
  handleClose,
  handleConfirmed,
}: ConfirmProps) => {
  return (
    <GlobalModal
      open={open}
      onClose={handleClose}
      defaultHeader={true}
      title="Are You Sure?"
      width="30vw"
    >
      <Stack spacing={3}>
        <Typography>{text}</Typography>
        <Stack
          direction="row"
          spacing={1}
          sx={{display: 'flex', justifyContent: 'flex-end'}}
        >
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleConfirmed}>
            Confirm
          </Button>
        </Stack>
      </Stack>
    </GlobalModal>
  );
};
