import {
  countRoles,
  createRole,
  deleteRole,
  getRole,
  listRoles,
  updateRole,
} from '@digistaff/core';
import {useCallback} from 'react';
import {app} from '../Account';
import {RoleStruct} from '../../views/RolesView/rolesViewData';

export const useRole = () => {
  const countAllRoles = useCallback(() => {
    return new Promise((resolve, reject) => {
      countRoles(app)
        .then(res => resolve(res.data.countRoles))
        .catch(err => reject(err));
    });
  }, []);

  const listAllRoles = useCallback(() => {
    return new Promise<RoleStruct[]>((resolve, reject) => {
      listRoles(app, {
        order: [{column: 'created_at', order: 'desc'}],
      })
        .then(res => resolve(res.data.listRoles))
        .catch(err => reject(err));
    });
  }, []);

  const getARole = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      getRole(app, id)
        .then(res => resolve(res.data.getRole))
        .catch(err => reject(err));
    });
  }, []);

  const createARole = useCallback((payload: Object) => {
    return new Promise<RoleStruct>((resolve, reject) => {
      createRole(app, payload)
        .then(res => resolve(res.data.createRole))
        .catch(err => reject(err));
    });
  }, []);

  const deleteARole = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      deleteRole(app, id)
        .then(res => resolve(res.data.deleteRole))
        .catch(err => reject(err));
    });
  }, []);

  const updateARole = useCallback((id: string, payload: Object) => {
    return new Promise<RoleStruct>((resolve, reject) => {
      updateRole(app, id, payload)
        .then(res => resolve(res.data.updateRole))
        .catch(err => reject(err));
    });
  }, []);

  return {
    countAllRoles,
    listAllRoles,
    getARole,
    createARole,
    deleteARole,
    updateARole,
  };
};
