/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {GridColDef} from '@mui/x-data-grid';
import {ToastStruct} from '../../types/types';
import {ManageUserMenu} from './ManageUserMenu';

// export const userColumns: GridColDef[] = [
export const UserColumns = (handleToast: any, handleUpdate: any) => {
  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: '',
      width: 20,
      align: 'center',
      renderCell: (data: any) => (
        <ManageUserMenu
          user={data.row}
          handleToast={(data: ToastStruct) => handleToast(data)}
          handleUpdate={handleUpdate}
        />
      ),
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      width: 175,
      renderCell: data => {
        if (data.row.nestGetOpsProfile)
          return data.row.nestGetOpsProfile.first_name;
        else return '-';
      },
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      width: 175,
      renderCell: data => {
        if (data.row.nestGetOpsProfile)
          return data.row.nestGetOpsProfile.last_name;
        else return '-';
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 175,
      renderCell: data => {
        if (data.row.nestGetRole) return data.row.nestGetRole.name;
        else return '-';
      },
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 150,
      renderCell: data => {
        if (data.row.nestGetOpsProfile) return data.row.nestGetOpsProfile.phone;
        else return '-';
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 225,
      renderCell: data => {
        if (data.row.nestGetOpsProfile) return data.row.nestGetOpsProfile.email;
        else return '-';
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      valueFormatter(params) {
        if (
          params.value === null ||
          params.value === '' ||
          params.value === undefined
        )
          return '-';
        return params.value;
      },
    },
  ];
  return columns;
};

export interface UserStruct {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  role: string;
  status: string;
  sso?: boolean;
}

export const emptyUser: UserStruct = {
  id: '',
  email: '',
  first_name: '',
  last_name: '',
  phone: '',
  role: '',
  status: '',
  sso: false,
};

export const formatPhoneNumber = (phone: string) => {
  return `${phone.substring(0, 2)} (${phone.substring(2, 5)}) ${phone.substring(
    5,
    8
  )}-${phone.substring(8)}`;
};
