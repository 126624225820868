import {Box, Button, Typography} from '@mui/material';

export const PaymentDetails = () => {
  const handleViewInvoice = () => {};
  return (
    <Box>
      <Typography variant={'h2'} align={'center'} sx={{m: 3}}>
        Payment Details
      </Typography>
      <Box sx={{mb: 6}} style={styles.paymentsDetailContainer}>
        <Box sx={styles.paymentsDetailsContentContainer}>
          <Box>
            <Typography align={'left'}>Current invoice</Typography>
            <Typography variant="h3" sx={{mt: 2}} align={'left'}>
              $ 123.50
            </Typography>
          </Box>
          <Button variant="contained" size="large" onClick={handleViewInvoice}>
            View your invoice
          </Button>
        </Box>
        <Box sx={styles.paymentsDetailsInfo}>
          <Typography sx={{pl: 4}} align={'left'}>
            Last payment of <strong>.....</strong> was made on
            <strong>......</strong>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  paymentsMainContainer: {
    borderBottom: 1,
    borderColor: 'divider',
  },
  paymentsDetailContainer: {
    boxShadow: '0 0 6px 2px rgb(65 64 66 / 15%)',
    borderRadius: 2,
  },
  paymentsDetailsContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 4,
  },
  paymentsDetailsInfo: {
    bgcolor: '#f5f5f5',
    pb: 2,
    pt: 2,
  },
};
