/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {listAppointmentCustomDateSettings} from '@digistaff/business';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {app} from '../../providers/Account';
import {useCallback, useEffect, useState} from 'react';
import {TablePaginationActions} from '../../common/DataTable/TablePaginationActions';
import {CustomRow} from './CustomRow';
import {Toast} from '../../common/Alerts/Toast';
import {ToastStruct} from '../../types/types';

export const ViewCustom = () => {
  const [slots, setSlots] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - slots.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getCustomAppointments = useCallback(async () => {
    const list: any = await listAppointmentCustomDateSettings(app, {
      filter: {
        status: {operator: '<>', value: 'deleted'},
        end_date: {operator: '>', value: new Date().toISOString()},
      },
      order: [{column: 'start_date', order: 'asc'}],
    });

    setSlots(list.data.listAppointmentCustomDateSettings);
    setLoading(false);
  }, []);

  useEffect(() => {
    getCustomAppointments();
  }, [getCustomAppointments]);

  return (
    <>
      {loading ? (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell>Bookings Per Slot</TableCell>
              <TableCell>Slot Interval</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Manage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? slots.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : slots
            ).map((slot: any) => (
              <CustomRow
                key={slot.id}
                slot={slot}
                setToastData={setToastData}
                setShowToast={setShowToast}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{height: 53 * emptyRows}}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, {label: 'All', value: -1}]}
                count={slots.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        text={toastData.text}
        severity={toastData.severity}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
    </>
  );
};
