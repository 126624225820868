import {RootView} from '../views/RootView/RootView';
import RouteErrorView from '../views/RouteErrorView/RouteErrorView';

export const publicRoutes = [
  {
    path: '/',
    element: <RootView />,
    children: [],
    errorElement: <RouteErrorView />,
  },
];
