/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button} from '@mui/material';
import {useCallback, useState} from 'react';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {UserColumns} from './UserViewData';
import {ManageModal} from './modals/ManageModal';
import {ToastStruct} from '../../types/types';
import {Toast} from '../../common/Alerts/Toast';
import {useOps} from '../../providers/api/useOps';
import {DataTable} from '../../common/DataTable/DataTable';
import {filterHiddenAdmins} from './helper';

export const UsersView = () => {
  const [loading, setLoading] = useState(true);
  const [userRows, setUserRows] = useState<any>([]);
  const [showAddUser, setShowAddUser] = useState(false);
  const {listAllOps, countOps} = useOps();
  const [rowCount, setRowCount] = useState(0);

  const getData = useCallback(
    async (
      page: number,
      pageSize: number,
      filter: Object = {},
      sorting: Object[] = [{column: 'created_at', order: 'desc'}]
    ) => {
      const opsCount: any = await countOps();
      setRowCount(opsCount);

      const ops = await listAllOps(page, pageSize, filter, sorting);
      const newOpsList = filterHiddenAdmins(ops);
      setUserRows(newOpsList);
      setLoading(false);
    },
    [listAllOps, countOps]
  );

  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });

  const handleToast = (data: ToastStruct) => {
    setToastData(data);
    setShowToast(true);
  };

  return (
    <MainViewContainer title="Users">
      <Box
        sx={{display: 'flex', justifyContent: 'flex-end', marginBottom: '15px'}}
      >
        <Button variant="contained" onClick={() => setShowAddUser(true)}>
          Invite
        </Button>
      </Box>
      <DataTable
        columns={UserColumns(handleToast, getData)}
        rows={userRows}
        loading={loading}
        rowCount={rowCount}
        getData={getData}
        handleRowClick={() => {}}
      />
      <ManageModal
        title="Invite"
        open={showAddUser}
        handleClose={() => {
          setShowAddUser(false);
          getData(0, 100);
        }}
        handleToast={(data: ToastStruct) => handleToast(data)}
      />
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        severity={toastData.severity}
        text={toastData.text}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
    </MainViewContainer>
  );
};
