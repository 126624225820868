import {
  createEmploymentContract,
  deleteEmploymentContract,
  getEmploymentContract,
  listEmploymentContracts,
  updateEmploymentContract,
} from '@digistaff/business';
import {useCallback} from 'react';
import {app} from '../Account';

export const useContract = () => {
  const listContracts = useCallback(() => {
    return new Promise((resolve, reject) => {
      listEmploymentContracts(app)
        .then(res => resolve(res.data.listEmploymentContracts))
        .catch(err => reject(err));
    });
  }, []);

  const getContract = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      getEmploymentContract(app, id)
        .then(res => resolve(res.data.getEmploymentContract))
        .catch(err => reject(err));
    });
  }, []);

  const deleteContract = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      deleteEmploymentContract(app, id)
        .then(res => resolve(res.data.deleteEmploymentContract))
        .catch(err => reject(err));
    });
  }, []);

  const updateContract = useCallback((id: string, payload: Object) => {
    return new Promise((resolve, reject) => {
      updateEmploymentContract(app, id, payload)
        .then(res => resolve(res.data.updateEmploymentContract))
        .catch(err => reject(err));
    });
  }, []);

  const createContract = useCallback((payload: Object) => {
    return new Promise((resolve, reject) => {
      createEmploymentContract(app, payload)
        .then(res => resolve(res.data.createEmploymentContract))
        .catch(err => reject(err));
    });
  }, []);

  return {
    listContracts,
    getContract,
    deleteContract,
    updateContract,
    createContract,
  };
};
