import {DeleteForever, Edit} from '@mui/icons-material';
import {GridColDef} from '@mui/x-data-grid';
import moment from 'moment';

export const staffCustomAttributesViewData: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Created At',
    width: 200,
    renderCell: data =>
      moment(data.row.created_at).format('hh:mm A DD/MM/YYYY'),
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 175,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 130,
  },
  {
    field: 'unit',
    headerName: 'Unit',
    width: 100,
    renderCell: data => (data.row.unit !== null ? data.row.unit : '-'),
  },
  {
    field: 'min',
    headerName: 'Minimum',
    renderCell: data => (data.row.min !== null ? data.row.min : '-'),
  },
  {
    field: 'max',
    headerName: 'Maximum',
    renderCell: data => (data.row.max !== null ? data.row.max : '-'),
  },
  {
    field: 'edit',
    headerName: 'Edit',
    width: 50,
    align: 'center',
    renderCell: () => <Edit sx={{cursor: 'pointer'}} />,
  },
  {
    field: 'delete',
    headerName: 'Delete',
    width: 70,
    align: 'center',
    renderCell: () => <DeleteForever sx={{cursor: 'pointer'}} />,
  },
];
