/* eslint-disable @typescript-eslint/no-explicit-any */
import {useRef} from 'react';

export const useSearchTimer = () => {
  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const submitData = (data: any) => {
    console.log('call made');
    console.log(data);
  };

  const handleSearchTimer = (data: any) => {
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    timerRef.current = setTimeout(() => submitData(data), 1000);
  };

  return [handleSearchTimer];
};
