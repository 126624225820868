/* eslint-disable @typescript-eslint/no-explicit-any */
import {updateAppointmentCustomeDateSetting} from '@digistaff/business';
import {
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import moment from 'moment';
import {useCallback, useState} from 'react';
import {app} from '../../providers/Account';
import {Confirm} from '../../common/Alerts/Confirm';

export interface CustomRowProps {
  slot: any;
  setToastData: any;
  setShowToast: any;
}

export const CustomRow = ({
  slot,
  setToastData,
  setShowToast,
}: CustomRowProps) => {
  const [status, setStatus] = useState(slot.status);
  const [openConfirm, setOpenConfirm] = useState(false);

  const updateStatus = useCallback(async () => {
    const result: any = await updateAppointmentCustomeDateSetting(
      app,
      slot.id,
      {status: status}
    );

    if (result.data.updateAppointmentCustomDateSetting.id) {
      setToastData({
        verticalPos: 'bottom',
        horizontalPos: 'center',
        severity: 'success',
        text: `Status has been set to ${status}`,
      });
    } else {
      setToastData({
        verticalPos: 'bottom',
        horizontalPos: 'center',
        severity: 'error',
        text: result,
      });
    }
    setShowToast(true);
  }, [slot, status, setToastData, setShowToast]);

  return (
    <>
      <TableRow key={slot.id}>
        <TableCell>{moment(slot.start_date).format('DD/MM/YYYY')}</TableCell>
        <TableCell>{moment(slot.start_date).format('hh:mm A')}</TableCell>
        <TableCell>{moment(slot.end_date).format('hh:mm A')}</TableCell>
        <TableCell>{slot.booking_per_slot}</TableCell>
        <TableCell>{slot.interval_minutes}</TableCell>
        <TableCell>
          <FormControl size="small">
            <InputLabel id="inputlbl">Status</InputLabel>
            <Select
              labelId="inputlbl"
              id="input"
              label="Input"
              value={status}
              onChange={event => {
                setStatus(event.target.value);
              }}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
              <MenuItem value="deleted">Delete</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <Button variant="outlined" onClick={() => setOpenConfirm(true)}>
            Update
          </Button>
        </TableCell>
      </TableRow>
      <Confirm
        open={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        handleConfirmed={() => {
          setOpenConfirm(false);
          updateStatus();
        }}
        text={`Are you sure you want to update the status to ${status}`}
      />
    </>
  );
};
