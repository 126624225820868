/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createAppointmentCategories,
  listAppointmentCategories,
  listAppointmentLocationSettings,
  updateAppointmentCategories,
  updateAppointmentLocationSettings,
} from '@digistaff/business';
import {app} from '../Account';
import {useCallback} from 'react';

export const useAppointments = () => {
  const listCategories = useCallback(() => {
    return new Promise((resolve, reject) => {
      listAppointmentCategories(app, {
        filter: {status: {operator: '<>', value: 'deleted'}},
      })
        .then(res => resolve(res.data.listAppointmentCategories))
        .catch(err => reject(err));
    });
  }, []);

  const updateCategory = (id: string, payload: Object) => {
    return new Promise((resolve, reject) => {
      updateAppointmentCategories(app, id, payload)
        .then(res => resolve(res.data.updateAppointmentCategory))
        .catch(err => reject(err));
    });
  };

  const createCategory = (payload: Object) => {
    return new Promise((resolve, reject) => {
      createAppointmentCategories(app, payload)
        .then(res => resolve(res.data.createAppointmentCategory))
        .catch(err => reject(err));
    });
  };

  const saveAllCategories = useCallback((categories: any) => {
    const promises: any = [];
    for (const category of categories) {
      const payload = {
        name: category.name,
        status: category.status,
        input_type: category.input_type,
        apprise_hours: category.apprise_hours,
        is_shift_linked: category.is_shift_linked,
      };
      if (category.id.includes('NEW_CATEGORY')) {
        promises.push(createCategory(payload));
      } else {
        promises.push(updateCategory(category.id, payload));
      }
    }

    return new Promise((resolve, reject) => {
      Promise.all(promises)
        .then(() => {
          resolve('success');
        })
        .catch(err => reject(err));
    });
  }, []);

  const getSettings = useCallback(() => {
    return new Promise((resolve, reject) => {
      listAppointmentLocationSettings(app)
        .then(res => resolve(res.data.listAppointmentLocationSettings[0]))
        .catch(err => reject(err));
    });
  }, []);

  const updateSettings = useCallback((id: string, payload: Object) => {
    return new Promise((resolve, reject) => {
      updateAppointmentLocationSettings(app, id, payload)
        .then(res => resolve(res.data.updateAppointmentLocationSettings))
        .catch(err => reject(err));
    });
  }, []);

  return {
    listCategories,
    updateCategory,
    createCategory,
    saveAllCategories,
    getSettings,
    updateSettings,
  };
};
