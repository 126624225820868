import {
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {GlobalModal} from '../../common/GlobalModal/GlobalModal';
import {ToastStruct} from '../../types/types';
import {emptyVacation, VacationStruct} from './vacationViewData';
import {useVacationBlackout} from '../../providers/api/useVacationBlackout';
import {Confirm} from '../../common/Alerts/Confirm';

export interface ManageModalProps {
  open: boolean;
  handleClose: () => void;
  currentVacation: VacationStruct;
  handleToast: (data: ToastStruct) => void;
}

export const ManageModal = ({
  open,
  handleClose,
  currentVacation,
  handleToast,
}: ManageModalProps) => {
  const [vacation, setVacation] = useState<VacationStruct>(emptyVacation);
  const {updateBlackout, createBlackout} = useVacationBlackout();
  const [confirmUpdate, setConfirmUpdate] = useState(false);

  useEffect(() => {
    if (currentVacation) setVacation(currentVacation);
  }, [currentVacation, setVacation]);

  const handleUpdate = () => {
    setConfirmUpdate(false);
    if (currentVacation.id === '') {
      createBlackout(vacation)
        .then(() => {
          handleToast({
            severity: 'success',
            text: 'The vacation blackout has been created',
            verticalPos: 'bottom',
            horizontalPos: 'center',
          });
        })
        .catch(err => console.log(err))
        .finally(() => closeModal());
    } else {
      updateBlackout(vacation.id, vacation)
        .then(() => {
          handleToast({
            severity: 'success',
            text: 'The vacation blackout has been updated',
            verticalPos: 'bottom',
            horizontalPos: 'center',
          });
        })
        .catch(err => console.log(err))
        .finally(() => closeModal());
    }
  };

  const closeModal = () => {
    setVacation(emptyVacation);
    handleClose();
  };

  return (
    <GlobalModal
      open={open}
      onClose={closeModal}
      defaultHeader={true}
      title={vacation.id === '' ? 'Add Blackout' : 'Update Blackout'}
      width="35vw"
    >
      <Box>
        <Grid
          container
          spacing={2}
          sx={{display: 'flex', justifyContent: 'center'}}
        >
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              size="small"
              value={vacation.title}
              onChange={event =>
                setVacation({...vacation, title: event.target.value})
              }
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                renderInput={props => (
                  <TextField {...props} size="small" fullWidth />
                )}
                label="Start"
                minDate={moment().toDate().toDateString()}
                value={vacation.start}
                onChange={event =>
                  setVacation({
                    ...vacation,
                    start: event,
                  })
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                renderInput={props => (
                  <TextField {...props} size="small" fullWidth />
                )}
                label="End"
                minDate={vacation.start}
                value={vacation.end}
                onChange={event =>
                  setVacation({
                    ...vacation,
                    end: event,
                  })
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Reason"
              variant="outlined"
              size="small"
              value={vacation.reason}
              onChange={event =>
                setVacation({...vacation, reason: event.target.value})
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="categorylbl">Category</InputLabel>
              <Select
                labelId="categorylbl"
                id="category"
                label="Category"
                value={vacation.status}
                onChange={event =>
                  setVacation({...vacation, status: event.target.value})
                }
                sx={{marginBottom: '15px'}}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setConfirmUpdate(true)}
            >
              {vacation.id === '' ? 'Add Blackout' : 'Update'}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Confirm
        open={confirmUpdate}
        handleClose={() => setConfirmUpdate(false)}
        handleConfirmed={handleUpdate}
        text={`Are you sure you want to ${
          vacation.id === '' ? 'add' : 'update'
        } this vacation blackout? Staff will no longer be able to request vacation on these days`}
      />
    </GlobalModal>
  );
};
