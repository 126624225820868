import {useCallback} from 'react';
import {app} from '../Account';
import {countResources, getResource, listResources} from '@digistaff/core';
import {ResourceStruct} from '../../types/types';

export const useResource = () => {
  const countAllResources = useCallback(() => {
    return new Promise((resolve, reject) => {
      countResources(app)
        .then(res => resolve(res.data.countResources))
        .catch(err => reject(err));
    });
  }, []);

  const listAllResources = useCallback(() => {
    return new Promise<ResourceStruct[]>((resolve, reject) => {
      listResources(app)
        .then(res => resolve(res.data.listResources))
        .catch(err => reject(err));
    });
  }, []);

  const getAResource = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      getResource(app, id)
        .then(res => resolve(res.data.getResource))
        .catch(err => reject(err));
    });
  }, []);

  return {countAllResources, listAllResources, getAResource};
};
