/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {SyntheticEvent, useEffect, useMemo, useState} from 'react';

import Form, {ISubmitEvent} from 'react-jsonschema-form';
import {
  useTable,
  useGroupBy,
  useExpanded,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';

import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardArrowRight,
  SupervisorAccount,
  Business,
  Person,
  MoreVert,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import {
  adminGetSchemaById,
  adminListRemotePreferencesByRole,
  adminListRemotePreferencesByUser,
  adminSetRemotePreference,
} from '@digistaff/remote-preference';
import {RemotePreferenceResponse} from '@digistaff/remote-preference/types';
import {styles} from './styles';
import {EmotionJSX} from '@emotion/react/types/jsx-namespace';
import {IArrowIcon, IPreferencesHolderIcon} from './types';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {adminListRoles, adminListUsers} from '@digistaff/app';
import {app} from '../../providers/Account';

const permissionsColumns = [
  {Header: 'Assign', accessor: 'assign', aggregate: 'count'},
  {Header: 'Preferences', accessor: 'preference'},
  {Header: 'Status', accessor: 'status'},
  {Header: 'Deactivation', accessor: 'action'},
];

const ArrowIcon = ({isOpened}: IArrowIcon): EmotionJSX.Element => {
  return isOpened ? <KeyboardArrowDown /> : <KeyboardArrowRight />;
};

const PreferencesHolderIcon = ({
  holder,
}: IPreferencesHolderIcon): EmotionJSX.Element | null => {
  if (holder) {
    if (holder === 'business') return <Business />;
    if (holder === 'role') return <SupervisorAccount />;
    if (holder === 'user') return <Person />;
  }
  return null;
};

const prepareStateRows = (
  rows: RemotePreferenceResponse | any
): {[key: string]: any}[] =>
  rows.map((row: {[key: string]: any}) => {
    const result: {[key: string]: any} = {
      rootId: row?.resourceId || null,
      assign: row?.resourceName || null,
    };
    row?.preferences?.forEach((preference: {[key: string]: any}) => {
      result.preference = preference?.scope || null;
      result.preferenceId = preference?.preferenceId || null;
      result.status = preference?.status || null;
      result.allOptions = preference?.payload || null;
    });
    return result;
  });

export const PreferencesTableView = () => {
  //handle init data state
  const [isLoading, setIsLoading] = useState(false);
  const [allBusiness, setAllBusiness] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  //handle accordion panels state
  const [mainPanelValue, setMainPanelValue] = useState<number | false>(false);
  const [subPanelValue, setSubPanelValue] = useState<number | false>(false);
  const [extraSubPanelValue, setExtraSubPanelValue] = useState<number | false>(
    false
  );
  //handle preferences rows state
  const [preferencesRows, setPreferencesRows] = useState<
    RemotePreferenceResponse | any
  >([]);
  //handle preferences search
  const [searchPreferencesHolderValue, setSearchPreferencesHolderValue] =
    useState('');
  //handle preferences state from the server for the modal
  const [preferencesServerValue, setPreferencesServerValue] =
    useState<any>(null);
  //handle modal state
  const [activeModal, setActiveModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [preferencesData, setPreferencesData] = useState<{
    [key: string]: any;
  } | null>(null);
  const [modalInfo, setModalInfo] = useState<{[key: string]: any} | null>(null);
  //handle deactivation state
  const [deactivationElem, setDeactivationElem] = useState<null | HTMLElement>(
    null
  );
  //handle hover row logic
  const [isRowHover, setIsRowHover] = useState({row: '', hover: false});
  //handle business state
  const [businessValue, setBusinessValue] = useState('Tenant wide');
  //handle preferences entity state
  const [preferencesEntity, setPreferencesEntity] = useState<{
    type: string;
    value: string;
  } | null>(null);

  //handle react-table cell,row and init state and logic
  const data = useMemo(
    () => prepareStateRows(preferencesRows),
    [preferencesRows]
  ) as any;
  const columns = useMemo(() => permissionsColumns, []) as any;
  const initialState = useMemo(
    () => ({groupBy: ['assign'], pageIndex: 0}),
    []
  ) as any;
  //handle react-table entity
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: {globalFilter},
    setGroupBy,
  } = useTable(
    {columns, data, initialState},
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded
  );
  // Search GLOBAL input value state of react-table
  const [search, setSearch] = useState(globalFilter || null);
  // Search input react-table state handle
  const onSearchChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 100);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const allRoles = await adminListRoles(app);
        const allUsers = await adminListUsers(app);
        //change this line when proper function will be available
        const allBusiness: any = null;

        if (allRoles?.data) setAllRoles(allRoles.data);
        if (allUsers?.data) setAllUsers(allUsers.data);
        if (allBusiness?.data) setAllBusiness(allBusiness.data);
      } catch (error) {
        () => {};
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    search ? setGroupBy([]) : setGroupBy(['assign']);
  }, [search, setGroupBy]);

  useEffect(() => {
    if (modalInfo?.allOptions) {
      (async () => {
        try {
          setIsLoading(true);
          const schemas: any = await adminGetSchemaById(
            app,
            modalInfo?.allOptions?.schemaId
          );

          if (schemas?.data) setPreferencesServerValue(schemas.data);
        } catch (error) {
          () => {};
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [modalInfo]);

  useEffect(() => {
    if (preferencesEntity?.type === 'role') {
      (async () => {
        try {
          setIsLoading(true);
          const rolePreferences: any = await adminListRemotePreferencesByRole(
            app,
            preferencesEntity.value
          );

          if (rolePreferences?.data) setPreferencesRows(rolePreferences.data);
        } catch (error) {
          () => {};
        } finally {
          setIsLoading(false);
        }
      })();
    }
    if (preferencesEntity?.type === 'user') {
      (async () => {
        try {
          setIsLoading(true);
          const userPreferences: any = await adminListRemotePreferencesByUser(
            app,
            preferencesEntity.value
          );

          if (userPreferences?.data) setPreferencesRows(userPreferences.data);
        } catch (error) {
          () => {};
        } finally {
          setIsLoading(false);
        }
      })();
    }
    if (preferencesEntity?.type === 'business') {
      () => {};
    }
  }, [preferencesEntity]);

  useEffect(() => {
    if (searchPreferencesHolderValue) {
      [...allBusiness, ...allUsers, ...allRoles].forEach((option: any) => {
        if (option?.roleName === searchPreferencesHolderValue) {
          handleSetPreferencesEntity('role')(null, option?.roleId);
          return;
        }
        if (
          `${option.firstName} ${option.lastName}` ===
          searchPreferencesHolderValue
        ) {
          handleSetPreferencesEntity('user')(null, option?.username);
          return;
        }
        if (option?.businessName === searchPreferencesHolderValue) {
          handleSetPreferencesEntity('business')(null, option.businessName);
          return;
        }
      });
    }
  }, [allBusiness, allRoles, allUsers, searchPreferencesHolderValue]);

  //handle "Main Panel" value
  const handleMainPanelChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      //close sub panel if it active
      if (subPanelValue !== false) {
        setSubPanelValue(false);
      }
      //close extra sub panel if it active
      if (extraSubPanelValue !== false) {
        setExtraSubPanelValue(false);
      }
      setMainPanelValue(newExpanded ? panel : false);
    };

  //handle Modal logic
  const handleSetPreferencesData = (event: ISubmitEvent<unknown> | any) => {
    setPreferencesData({
      schemaId: event?.schema?.schemaId,
      formData: event?.formData,
    });
    setConfirmModal(true);
  };
  const handleCancelation = () => {
    setActiveModal(false);
    setConfirmModal(false);
    setPreferencesData(null);
  };
  const handleSubmit = () => {
    if (preferencesData) {
      (async () => {
        setIsLoading(true);
        try {
          await adminSetRemotePreference(
            app,
            preferencesData?.schemaId,
            preferencesData?.formData
          );
        } catch (error) {
          () => {};
        } finally {
          setIsLoading(false);
          handleCancelation();
        }
      })();
    }
  };

  //handle deactivation logic
  const isDeactivationOpen = useMemo(
    () => Boolean(deactivationElem),
    [deactivationElem]
  );
  const handleDeactivationClick = (event: React.MouseEvent<HTMLElement>) => {
    setDeactivationElem(event.currentTarget);
    setIsRowHover({row: '', hover: false});
  };
  const handleDeactivationClose = () => setDeactivationElem(null);
  const handleDeactivationDelete =
    (preference: {[key: string]: any}) => () => {};
  const handleDeactivationDisable =
    (preference: {[key: string]: any}) => () => {};

  //handle set preference entity
  const handleSetPreferencesEntity =
    (type: string) =>
    (e: SyntheticEvent<Element, Event> | null | any, v: boolean | string) => {
      if (typeof v === 'string') {
        setPreferencesEntity(prev =>
          prev?.value === v ? null : {type, value: v}
        );
      }
      if (e !== null) {
        setPreferencesEntity(prev =>
          prev?.value === e.target.value ? null : {type, value: e.target.value}
        );
      }
    };

  return (
    <MainViewContainer title="Advanced Preferences">
      <Box style={styles({}).root}>
        <Box style={styles({}).collapseContainer}>
          <Autocomplete
            freeSolo
            sx={styles({}).collapseSearchInput}
            value={searchPreferencesHolderValue}
            onChange={(
              event: SyntheticEvent<Element, Event>,
              value: string | null
            ) => {
              setSearchPreferencesHolderValue(value || '');
            }}
            options={[...allBusiness, ...allUsers, ...allRoles].map(
              (option: {[key: string]: any}) =>
                option?.roleName
                  ? option.roleName
                  : option.username
                  ? `${option.firstName} ${option.lastName}`
                  : ''
            )}
            renderInput={params => (
              <TextField {...params} size="small" label="Search for a holder" />
            )}
          />

          {!searchPreferencesHolderValue ? (
            <>
              <FormControl
                component="fieldset"
                sx={{
                  ...styles({}).collapseFormControl,
                  pl: 2,
                }}
              >
                <Business />
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value={businessValue}
                    control={
                      <Checkbox
                        checked={preferencesEntity?.value === businessValue}
                        sx={
                          styles({
                            isCollapseCheckbox:
                              preferencesEntity?.value === businessValue,
                          }).collapseFormCheckbox
                        }
                      />
                    }
                    label={'Tenant'}
                    labelPlacement="start"
                    sx={{
                      ...styles({
                        isCollapseLabel:
                          preferencesEntity?.value === businessValue,
                      }).collapseFormLabel,
                      width: '100%',
                    }}
                    onChange={handleSetPreferencesEntity('business')}
                  />
                </FormGroup>
              </FormControl>

              <Accordion
                expanded={mainPanelValue === 1}
                onChange={handleMainPanelChange(1)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <ArrowIcon isOpened={mainPanelValue === 1} />
                  <Typography sx={{pl: 1}}>Role</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={styles({}).collapseContentContainer}>
                    {allRoles.length &&
                      allRoles.map((role: any) => (
                        <FormControl
                          sx={styles({}).collapseFormControl}
                          key={role?.roleId}
                          component="fieldset"
                        >
                          <SupervisorAccount />
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value={role?.roleId}
                              control={
                                <Checkbox
                                  sx={
                                    styles({
                                      isCollapseCheckbox:
                                        preferencesEntity?.value ===
                                        role.roleId,
                                    }).collapseFormCheckbox
                                  }
                                />
                              }
                              label={role?.roleName}
                              labelPlacement="start"
                              sx={{
                                ...styles({
                                  isCollapseLabel:
                                    preferencesEntity?.value === role.roleId,
                                }).collapseFormLabel,
                                textAlign: 'left',
                              }}
                              onChange={handleSetPreferencesEntity('role')}
                            />
                          </FormGroup>
                        </FormControl>
                      ))}
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={mainPanelValue === 2}
                onChange={handleMainPanelChange(2)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <ArrowIcon isOpened={mainPanelValue === 2} />
                  <Typography sx={{pl: 1}}>User</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={styles({}).collapseContentContainer}>
                    {allUsers.length &&
                      allUsers.map((user: any) => {
                        return (
                          <FormControl
                            sx={styles({}).collapseFormControl}
                            key={user?.username}
                            component="fieldset"
                          >
                            <Person />
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                                value={user?.username}
                                control={
                                  <Checkbox
                                    sx={
                                      styles({
                                        isCollapseCheckbox:
                                          preferencesEntity?.value ===
                                          user?.username,
                                      }).collapseFormCheckbox
                                    }
                                  />
                                }
                                label={`${user?.firstName} ${user?.lastName}`}
                                labelPlacement="start"
                                sx={{
                                  ...styles({
                                    isCollapseLabel:
                                      preferencesEntity?.value ===
                                      user.username,
                                  }).collapseFormLabel,
                                  m: 1,
                                  textAlign: 'left',
                                }}
                                onChange={handleSetPreferencesEntity('user')}
                              />
                            </FormGroup>
                          </FormControl>
                        );
                      })}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            <FormGroup
              sx={styles({}).collapseFormControl}
              aria-label="position"
              row
            >
              <PreferencesHolderIcon holder={preferencesEntity?.type} />
              <FormControlLabel
                value={searchPreferencesHolderValue}
                control={<Checkbox checked sx={{display: 'flex'}} />}
                onChange={() => {
                  setSearchPreferencesHolderValue('');
                }}
                label={searchPreferencesHolderValue}
                labelPlacement="start"
                sx={styles({}).collapsedFormLabel}
              />
            </FormGroup>
          )}
        </Box>

        <Box style={styles({}).tableContainer}>
          {preferencesEntity ? (
            <>
              <Box sx={styles({}).tableSearchContainer}>
                <Autocomplete
                  freeSolo
                  value={search}
                  onChange={(event: any, newValue: string | null) => {
                    setSearch(newValue);
                    onSearchChange(newValue);
                  }}
                  options={preferencesRows.map((option: any) =>
                    option?.preferences
                      ?.map((preference: any) => preference.scope)
                      .toString()
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size="small"
                      label="Search for Preferences"
                    />
                  )}
                  sx={styles({}).tableSearch}
                />
              </Box>
              <Table {...getTableProps()} sx={styles({}).table}>
                <TableHead>
                  {headerGroups.map((headerGroup, index) => {
                    return (
                      <TableRow
                        {...headerGroup.getHeaderGroupProps()}
                        key={`${index}-a`}
                      >
                        {headerGroup.headers.map((column, i) => {
                          return (
                            <TableCell
                              {...column.getHeaderProps(
                                column.id !== 'action'
                                  ? column.getSortByToggleProps()
                                  : undefined
                              )}
                              sx={styles({}).tableHeadCell}
                              key={`${i}-b`}
                            >
                              <Box style={{display: 'flex'}}>
                                {column.render('Header')}
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <KeyboardArrowDown />
                                  ) : (
                                    <KeyboardArrowUp />
                                  )
                                ) : column.id !== 'action' ? (
                                  <KeyboardArrowRight />
                                ) : null}
                              </Box>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <TableRow
                        {...row.getRowProps()}
                        key={`${index}-c`}
                        style={styles({}).tableRow}
                      >
                        {row.cells.map((cell, i) => {
                          const isGrouped = cell.isGrouped;
                          const isAggregated = cell.isAggregated;
                          const isHover =
                            isRowHover.row === cell.row.id && isRowHover.hover;
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              key={`${i}-d`}
                              style={
                                styles({
                                  isGrouped,
                                  isAggregated,
                                  isHover,
                                }).tableRowCell
                              }
                              onClick={() => {
                                if (isHover) {
                                  setModalInfo(cell?.row?.original || null);
                                  setActiveModal(true);
                                }
                              }}
                              onMouseEnter={() => {
                                if (isDeactivationOpen) return;
                                if (!isGrouped && !isAggregated) {
                                  setIsRowHover({
                                    row: cell.row.id,
                                    hover: true,
                                  });
                                }
                              }}
                              onMouseLeave={() => {
                                if (!isGrouped && !isAggregated) {
                                  setIsRowHover({row: '', hover: false});
                                }
                              }}
                            >
                              {isGrouped ? (
                                <Box
                                  {...row.getToggleRowExpandedProps()}
                                  style={styles({}).expandedRowContainer}
                                >
                                  <Box style={{display: 'flex'}}>
                                    <ArrowIcon isOpened={row.isExpanded} />
                                  </Box>
                                  {cell.render('Cell')} ({row.subRows.length})
                                </Box>
                              ) : isAggregated ? (
                                cell.render('Aggregated')
                              ) : cell.isPlaceholder ? null : (
                                <Box style={styles({}).expandedRow}>
                                  {cell.render('Cell')}
                                  {cell.column.id === 'action' ? (
                                    <Box
                                      style={{textAlign: 'center'}}
                                      onClick={e => e.stopPropagation()}
                                    >
                                      <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={
                                          isDeactivationOpen
                                            ? 'long-menu'
                                            : undefined
                                        }
                                        aria-expanded={
                                          isDeactivationOpen
                                            ? 'true'
                                            : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={handleDeactivationClick}
                                      >
                                        <MoreVert />
                                      </IconButton>
                                      <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                          'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={deactivationElem}
                                        open={isDeactivationOpen}
                                        onClose={handleDeactivationClose}
                                        PaperProps={styles({}).paperProps}
                                      >
                                        <MenuItem
                                          onClick={handleDeactivationDelete(
                                            row?.original
                                          )}
                                        >
                                          Delete
                                        </MenuItem>
                                        <MenuItem
                                          onClick={handleDeactivationDisable(
                                            row?.original
                                          )}
                                        >
                                          Disable
                                        </MenuItem>
                                      </Menu>
                                    </Box>
                                  ) : null}
                                </Box>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </>
          ) : (
            <Box style={styles({}).emptyTableElement}>
              <Typography>Select a role</Typography>
            </Box>
          )}
        </Box>

        <Modal open={activeModal} onClose={handleCancelation}>
          <Box sx={styles({}).modalContainer}>
            <Typography>
              {confirmModal
                ? 'Are you sure that you want to "Submit" this data?'
                : 'Set new or update existing preferences'}
            </Typography>

            {confirmModal ? (
              <Box sx={styles({}).buttonContainer}>
                <Button
                  type="reset"
                  variant="contained"
                  onClick={handleCancelation}
                  style={{marginRight: 10}}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  Submit
                </Button>
              </Box>
            ) : preferencesServerValue !== null ? (
              <Form
                schema={preferencesServerValue}
                uiSchema={preferencesServerValue?.uiSchema || {}}
                onError={() => {}}
                onSubmit={handleSetPreferencesData}
              >
                <Box sx={styles({}).buttonContainer}>
                  <Button
                    type="reset"
                    variant="contained"
                    onClick={handleCancelation}
                    style={{marginRight: 10}}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained">
                    Confirm
                  </Button>
                </Box>
              </Form>
            ) : (
              <Typography>There is no data to display</Typography>
            )}
          </Box>
        </Modal>
      </Box>
    </MainViewContainer>
  );
};
