import {getOpsTenant} from '@digistaff/business';
import {app} from '../Account';

export const getTenant = async (tenantId: string) => {
  try {
    const res = await getOpsTenant(app, tenantId);
    const tenant = res.data.getTenant;

    return tenant;
  } catch (err) {
    return err;
  }
};
