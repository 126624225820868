import {
  Modal as ModalContainer,
  Box,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import {Dispatch, SetStateAction} from 'react';

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCardHolderChange: Dispatch<SetStateAction<string>>;
  onCardNumberChange: Dispatch<SetStateAction<string>>;
  onCardExpirationChange: Dispatch<SetStateAction<string>>;
  onCardCVCChange: Dispatch<SetStateAction<string>>;
  onSave: () => void;
  onCancel: () => void;
}

export const Modal = ({
  isOpen,
  onClose,
  onCardHolderChange,
  onCardNumberChange,
  onCardExpirationChange,
  onCardCVCChange,
  onSave,
  onCancel,
}: IModalProps) => {
  return (
    <ModalContainer
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.modalContainer}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Payment Information
        </Typography>
        <Typography id="modal-modal-description" sx={{mt: 2}}>
          <TextField
            fullWidth
            onChange={e => onCardHolderChange(e.target.value)}
            label="Cardholder Name"
            id="name"
          />
          <TextField
            fullWidth
            onChange={e => onCardNumberChange(e.target.value)}
            label="Card number"
            id="number"
          />
          <TextField
            onChange={e => onCardExpirationChange(e.target.value)}
            id="outlined-multiline-flexible"
            fullWidth
            label="MM/YY"
          />
          <TextField
            onChange={e => onCardCVCChange(e.target.value)}
            id="outlined-textarea"
            fullWidth
            label="CVC"
          />

          <Button
            style={{marginTop: '25px'}}
            fullWidth
            variant="outlined"
            size="large"
            onClick={onSave}
          >
            Save
          </Button>
          <Button
            style={{marginTop: '25px'}}
            fullWidth
            variant="outlined"
            size="large"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Typography>
      </Box>
    </ModalContainer>
  );
};

const styles = {
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
};
