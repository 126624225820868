/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControlLabel,
  Checkbox,
  Tooltip,
  Button,
  Stack,
  Box,
  CircularProgress,
} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {useDigiScreenSettings} from '../../providers/api/useDigiScreenSettings';
import {Toast} from '../../common/Alerts/Toast';
import {ToastStruct} from '../../types/types';

export const DigiCheckSettings = () => {
  const [integrateDigiCheck, setIntegrateDigiCheck] = useState<boolean>(true);
  const {listIntegration, updateIntegration} = useDigiScreenSettings();
  const [id, setId] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });

  const handleUpdate = useCallback(
    async (value: boolean) => {
      const status: any = await updateIntegration(id, value);
      if (status.id) {
        setToastData({
          ...toastData,
          severity: 'success',
          text: 'Settings have been updated',
        });
      } else {
        setToastData({
          ...toastData,
          severity: 'error',
          text: 'Could not update settings',
        });
      }
      setShowToast(true);
    },
    [id, toastData, updateIntegration]
  );

  const handleListing = useCallback(async () => {
    const settings: any = await listIntegration();
    setIntegrateDigiCheck(settings.is_digicheck_approval_integrated);
    setId(settings.id);
    setLoading(false);
  }, [listIntegration]);

  useEffect(() => {
    handleListing();
  }, [handleListing]);

  return (
    <>
      {loading ? (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      ) : (
        <Stack>
          <Tooltip title="If enabled, staff will be moved to approved once all their files have been approved on DigiCheck">
            <FormControlLabel
              value={integrateDigiCheck}
              control={<Checkbox checked={integrateDigiCheck} />}
              label="Confirm Staff From DigiCheck"
              onChange={() => setIntegrateDigiCheck(!integrateDigiCheck)}
            />
          </Tooltip>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Button
              variant="outlined"
              onClick={() => handleUpdate(integrateDigiCheck)}
            >
              Save
            </Button>
          </Box>
        </Stack>
      )}
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        text={toastData.text}
        severity={toastData.severity}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
    </>
  );
};
