/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createVacationBlackout,
  deleteVacationBlackout,
  listVacationBlackouts,
  updateVacationBlackout,
} from '@digistaff/business';
import {useCallback} from 'react';
import {app} from '../Account';
import moment from 'moment';

export const useVacationBlackout = () => {
  const listBlackouts = useCallback(() => {
    return new Promise((resolve, reject) => {
      listVacationBlackouts(app)
        .then(res => resolve(res.data.listVacationBlackouts))
        .catch(err => reject(err));
    });
  }, []);

  const updateBlackout = useCallback((id: string, payload: any) => {
    return new Promise((resolve, reject) => {
      const formattedPayload: any = {
        title: payload.title,
        reason: payload.reason,
        status: payload.status,
      };

      if (payload.start)
        formattedPayload.start_date = moment(
          new Date(payload.start)
        ).toISOString();

      if (payload.end)
        formattedPayload.end_date = moment(new Date(payload.end)).toISOString();

      updateVacationBlackout(app, id, formattedPayload)
        .then(res => resolve(res.data.updateVacationBlackout))
        .catch(err => reject(err));
    });
  }, []);

  const createBlackout = useCallback((payload: any) => {
    return new Promise((resolve, reject) => {
      createVacationBlackout(app, {
        title: payload.title,
        reason: payload.reason,
        end_date: moment(new Date(payload.end)).toISOString(),
        start_date: moment(new Date(payload.start)).toISOString(),
        status: payload.status,
      })
        .then(res => resolve(res.data.createVacationBlackout))
        .catch(err => reject(err));
    });
  }, []);

  const deleteBlackout = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      deleteVacationBlackout(app, id)
        .then(res => resolve(res.data.deleteVacationBlackout))
        .catch(err => reject(err));
    });
  }, []);

  return {listBlackouts, updateBlackout, createBlackout, deleteBlackout};
};
