import {Group, Person} from '@mui/icons-material';
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Grid,
  Button,
  Box,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {ListEntry} from './notificationViewData';

function not(a: ListEntry[], b: ListEntry[]) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a: ListEntry[], b: ListEntry[]) {
  return a.filter(value => b.indexOf(value) !== -1);
}

export interface TransferListProps {
  initialLeft: ListEntry[];
  initialRight: ListEntry[];
}
export const TransferList = ({
  initialLeft,
  initialRight,
}: TransferListProps) => {
  const [checked, setChecked] = useState<ListEntry[]>([]);
  const [left, setLeft] = useState<ListEntry[]>([]);
  const [right, setRight] = useState<ListEntry[]>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    setLeft(initialLeft);
    setRight(initialRight);
  }, [initialLeft, initialRight]);

  const handleToggle = (value: ListEntry) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items: ListEntry[], title: string) => (
    <Box>
      <Typography variant="h5" marginBottom="5px">
        {title}
      </Typography>
      <Paper sx={{width: '100%', height: 400, overflow: 'auto'}}>
        <List dense component="div" role="list">
          {items.map((value: ListEntry) => {
            const labelId = `${value.id}-label`;

            return (
              <ListItem
                key={value.id}
                role="listitem"
                onClick={handleToggle(value)}
              >
                <ListItemIcon sx={{marginRight: '-15px'}}>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                      {value.type === 'user' ? <Person /> : <Group />}
                      <Typography marginLeft="5px">{value.name}</Typography>
                    </Box>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </Box>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} md={5}>
        {customList(left, 'Available')}
      </Grid>
      <Grid item xs={12} md={2}>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{my: 0.5}}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{my: 0.5}}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{my: 0.5}}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{my: 0.5}}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} md={5}>
        {customList(right, 'Assigned')}
      </Grid>
    </Grid>
  );
};
