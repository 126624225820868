/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControlLabel,
  Checkbox,
  Collapse,
  TextField,
  Stack,
} from '@mui/material';
import {LocalizationProvider, TimePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import {useState} from 'react';

export interface DayTimingProps {
  label: string;
  data: any;
  state: any;
  updateState: any;
}

export const DayTiming = ({
  label,
  data,
  state,
  updateState,
}: DayTimingProps) => {
  const [checked, setChecked] = useState<boolean>(data.start !== null);
  const [startTime, setStartTime] = useState<any>(
    moment(data.start, 'HH:mm:ss')
  );
  const [endTime, setEndTime] = useState<any>(moment(data.end, 'HH:mm:ss'));

  const handleUpdate = (enabled: boolean, startValue: any, endValue: any) => {
    const start = enabled
      ? moment(new Date(startValue)).format('HH:mm:ss')
      : null;
    const end = enabled ? moment(new Date(endValue)).format('HH:mm:ss') : null;
    updateState({
      ...state,
      [`${label.toLocaleLowerCase()}_start_time`]: start,
      [`${label.toLocaleLowerCase()}_end_time`]: end,
    });
  };

  return (
    <Stack direction="row" sx={{marginY: '5px'}}>
      <FormControlLabel
        sx={{width: '150px'}}
        control={
          <Checkbox
            checked={checked}
            onChange={() => {
              setChecked(!checked);
              handleUpdate(!checked, startTime, endTime);
            }}
          />
        }
        label={label}
      />
      <Collapse in={checked}>
        <Stack spacing={1} direction="row">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              renderInput={props => (
                <TextField
                  fullWidth
                  {...props}
                  inputProps={{
                    ...props.inputProps,
                    placeholder: 'Start Time',
                  }}
                  size="small"
                  label="Start Time"
                />
              )}
              value={startTime}
              onChange={event => {
                setStartTime(event);
                handleUpdate(checked, event, endTime);
              }}
            />
            <TimePicker
              renderInput={props => (
                <TextField
                  fullWidth
                  {...props}
                  inputProps={{
                    ...props.inputProps,
                    placeholder: 'End Time',
                  }}
                  size="small"
                  label="End Time"
                />
              )}
              value={endTime}
              minTime={startTime}
              onChange={event => {
                setEndTime(event);
                handleUpdate(checked, startTime, event);
              }}
            />
          </LocalizationProvider>
        </Stack>
      </Collapse>
    </Stack>
  );
};
