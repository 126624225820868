import {useCallback, useEffect, useState} from 'react';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {listShiftSettings, updateShiftSetting} from '@digistaff/business';
import {app} from '../../providers/Account';
import {
  FormControlLabel,
  Checkbox,
  Box,
  CircularProgress,
  Tooltip,
  Button,
  Stack,
} from '@mui/material';
import {Toast} from '../../common/Alerts/Toast';
import {ToastStruct} from '../../types/types';

export const ShiftSettingsView = () => {
  const [loading, setLoading] = useState(true);
  const [complianceOverride, setComplianceOverride] = useState(true);
  const [settingsId, setSettingsId] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });

  const getSettings = useCallback(async () => {
    const result = await listShiftSettings(app);
    setSettingsId(result.data.listShiftSettings[0].id);
    setComplianceOverride(
      result.data.listShiftSettings[0].is_compliance_override
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const handleSave = async () => {
    const result = await updateShiftSetting(app, settingsId, {
      is_compliance_override: complianceOverride,
    });

    if (result.data.updateShiftSetting.id) {
      setToastData({
        ...toastData,
        text: 'Shift settings have been saved',
        severity: 'success',
      });
    } else {
      setToastData({
        ...toastData,
        text: 'Could not update shift settings',
        severity: 'error',
      });
    }
    setShowToast(true);
  };

  return (
    <MainViewContainer title="Shift Settings">
      {!loading ? (
        <Stack>
          <Tooltip title="Allows staff to override the compliance check when using the Assign to Shift modal">
            <FormControlLabel
              label={'Enable Compliance Override'}
              control={
                <Checkbox
                  checked={complianceOverride}
                  onClick={() => {
                    setComplianceOverride(!complianceOverride);
                  }}
                />
              }
            />
          </Tooltip>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Button
              onClick={handleSave}
              variant="outlined"
              sx={{width: '250px', mt: 4}}
            >
              Save
            </Button>
          </Box>
        </Stack>
      ) : (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      )}
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        text={toastData.text}
        severity={toastData.severity}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
    </MainViewContainer>
  );
};
