import {Button, Stack, TextField, Typography} from '@mui/material';
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import {useState} from 'react';
import {Confirm} from '../../../common/Alerts/Confirm';
import {GlobalModal} from '../../../common/GlobalModal/GlobalModal';
import {ToastStruct} from '../../../types/types';
import {errorMsg} from '../styles';
import {UserStruct} from '../UserViewData';

export interface SuspendModalProps {
  open: boolean;
  handleClose: () => void;
  user?: UserStruct;
  handleToast: (data: ToastStruct) => void;
}

export const SuspendModal = ({
  open,
  handleClose,
  user,
  handleToast,
}: SuspendModalProps) => {
  const [suspendUntil, setSuspendUntil] = useState<null | Date>(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [error, setError] = useState('');

  const handleConfirmed = () => {
    handleClose();
    handleToast({
      severity: 'success',
      text: `${user?.first_name} has been suspended until ${suspendUntil}`,
      verticalPos: 'bottom',
      horizontalPos: 'center',
    });
  };

  const handleSuspendClicked = () => {
    if (suspendUntil !== null) {
      setShowConfirm(true);
    } else {
      setError('Please select a date & time');
    }
  };

  return (
    <GlobalModal
      open={open}
      onClose={handleClose}
      defaultHeader={true}
      title="Suspend User"
      width="30vw"
    >
      <Stack spacing={2}>
        <Typography>Select a date to suspend the user until</Typography>
        <Stack>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              renderInput={props => <TextField {...props} size="small" />}
              label="Suspend User Until"
              minDate={moment().toDate()}
              value={suspendUntil}
              onChange={newValue => {
                setSuspendUntil(newValue);
              }}
            />
          </LocalizationProvider>
          <Typography sx={errorMsg}>{error}</Typography>
        </Stack>
        <Button variant="outlined" onClick={handleSuspendClicked}>
          Suspend User
        </Button>
      </Stack>
      <Confirm
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        text={`Are you sure you want to suspend ${user?.first_name} until the selected date?`}
        handleConfirmed={handleConfirmed}
      />
    </GlobalModal>
  );
};
