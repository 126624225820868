/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {ChangeEvent, useEffect, useState} from 'react';

import {
  CardContent,
  CardActions,
  TableContainer,
  Box,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
  Grid,
  CircularProgress,
  Button,
  TablePagination,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  Card,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import {VisaIcon, MasterCardIcon} from '../../common/Image/Image';

import {
  listInvoices,
  listPaymentProfiles,
  setPaymentProfileAsPrimary,
  getSubscription,
  createPaymentProfile,
} from '../../providers/BillingSubscription';
import {Column} from './types';
import {StyledTab, StylesTabs} from './StyledComponents/StyledComponents';
import {styles} from './styles';
import {EmotionJSX} from '@emotion/react/types/jsx-namespace';
import {Modal as ModalComponent} from './components/Modal';
import {PaymentDetails} from './components/PaymentDetails';
import {AddNewPaymentDialogBox} from './components/AddNewPaymentDialogBox';
import {TabPanel} from './components/TabPanel';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {app} from '../../providers/Account';

const columns: Column[] = [
  {id: 'date', label: 'Date', align: 'left', width: 200},
  {id: 'amount', label: 'Amount', align: 'center', width: 100},
  {id: 'transaction', label: 'Transaction', align: 'center', width: 200},
  {id: 'details', label: 'Details', align: 'center', width: 100},
  {id: 'reference', label: 'Reference', align: 'center', width: 80},
  {id: 'action', label: 'Action', align: 'center', width: 100},
];

const formatDate = (timestamp: number): string => {
  return `${new Date(timestamp).getMonth()}/${new Date(
    timestamp
  ).getDate()}/${new Date(timestamp).getFullYear()} ${new Date(
    timestamp
  ).toLocaleTimeString()}`;
};

const handleDisplayValue = (id: string, invoice: any): string | JSX.Element => {
  switch (id) {
    case 'date':
      return formatDate(invoice.created_at);
    case 'amount':
      return `$${invoice.amount}`;
    case 'transaction':
      return 'Payment by Credit Card';
    case 'details':
      return 'some details';
    case 'reference':
      return invoice.status;
    case 'action':
      return (
        <Button onClick={() => {}} variant="outlined">
          View
        </Button>
      );
    default:
      return '';
  }
};

function tabProps(index: number): {[key: string]: string} {
  return {
    id: `invoice-tab-${index}`,
    'aria-controls': `invoice-tabpanel-${index}`,
  };
}

export const InvoicesView = (): EmotionJSX.Element => {
  const [invoices, setInvoices] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tabValue, setTabValue] = useState(0);
  const [panelValue, setPanelValue] = useState<number | false>(0);

  //paid logic
  const [paymentProfilesLeftColumn, setPaymentProfilesLeftColumn] =
    useState<any>([]);
  const [paymentProfilesRightColumn, setPaymentProfilesRightColumn] =
    useState<any>([]);
  const [primaryPaymentProfileId, setPrimaryPaymentProfileId] = useState('');
  const [billingSubscriptionId, setBillingSubscriptionId] = useState('');
  const [openRemoveCardDialogBox, setOpenRemoveCardDialogBox] = useState(false);
  const [openAddPaymentMethodModal, setOpenAddPaymentMethodModal] =
    useState(false);
  const [openAddPaymentMethodDialogBox, setAddPaymentMethodDialogBox] =
    useState(false);
  const [cardHolderName, setCardHolderName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiration, setExpiration] = useState('');
  const [CVC, setCVC] = useState('');

  useEffect(() => {
    getSubscription(app)
      .then((subscription: any) => {
        listInvoices(app, subscription.result[0].billing_subscription_id).then(
          (invoices: any) => setInvoices(invoices.result)
        );
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    getSubscription(app).then((subscription: any) => {
      setPrimaryPaymentProfileId(subscription.result[0].payment_profile_id);
      setBillingSubscriptionId(subscription.result[0].billing_subscription_id);
      listPaymentProfiles(app).then((profiles: any) => {
        const leftColumn: any = [];
        const rightColumn: any = [];
        profiles.result.forEach((profile: any, index: number) => {
          if (index % 2 === 0) {
            leftColumn.push(profile);
          } else {
            rightColumn.push(profile);
          }
        });
        setPaymentProfilesLeftColumn(leftColumn);
        setPaymentProfilesRightColumn(rightColumn);
      });
    });
  }, []);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setPanelValue(newExpanded ? panel : false);
    };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement | MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleTabValueChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  const handleSetAsPrimary = (paymentProfileId: string) => {
    setPaymentProfileAsPrimary(
      app,
      billingSubscriptionId,
      paymentProfileId
    ).then(() => {
      setPrimaryPaymentProfileId(paymentProfileId);
    });
  };

  const handleSavePaymentMethod = () => {
    const paymentMethod = {
      card: {
        name: cardHolderName,
        number: cardNumber,
        expiry_month: expiration.split('/')[0],
        expiry_year: expiration.split('/')[1],
        cvd: CVC,
      },
    };

    createPaymentProfile(app, paymentMethod)
      .then(() => {
        setOpenAddPaymentMethodModal(false);
      })
      .catch(() => setOpenAddPaymentMethodModal(false));
  };

  const handleRemoveCard = (paymentProfileId: string) => {
    setOpenRemoveCardDialogBox(true);
  };

  const handleCloseRemoveCardDialog = () => {
    setOpenRemoveCardDialogBox(false);
  };

  const handleCloseAddPaymentMethodDialog = () => {
    setOpenAddPaymentMethodModal(true);
    setAddPaymentMethodDialogBox(false);
  };

  const handleCloseAddPaymentMethodModal = () => {
    setOpenAddPaymentMethodModal(false);
  };

  return (
    <MainViewContainer title="Invoices">
      <Box>
        <PaymentDetails />
        <Box sx={styles.paymentsMainContainer}>
          <StylesTabs
            value={tabValue}
            onChange={handleTabValueChange}
            aria-label="invoice tabs"
            variant="fullWidth"
            centered
          >
            <StyledTab label="Make a Payment" {...tabProps(0)} />
            <StyledTab label="Payments" {...tabProps(1)} />
            <StyledTab label="Payment Method" {...tabProps(2)} />
            <StyledTab label="Payment history" {...tabProps(3)} />
          </StylesTabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Accordion expanded={panelValue === 0} onChange={handleChange(0)}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>Collapsible Group Item #1</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Collapsible Group Item #1 Description</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={panelValue === 1} onChange={handleChange(1)}>
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>Collapsible Group Item #2</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Collapsible Group Item #2 Description</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={panelValue === 2} onChange={handleChange(2)}>
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography>Collapsible Group Item #3</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Collapsible Group Item #3 Description</Typography>
            </AccordionDetails>
          </Accordion>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Typography variant={'h4'}>Payments</Typography>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Box
            component="form"
            sx={{
              maxWidth: '100%',
              m: 'auto',
              textAlign: 'center',
            }}
          >
            <div style={{textAlign: 'center'}}>
              <h2>Payment Accounts</h2>
            </div>

            {!paymentProfilesLeftColumn.length &&
            !paymentProfilesRightColumn.length ? (
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                style={{minHeight: '100vh'}}
              >
                <Grid item xs={3}>
                  <Box
                    alignContent={'center'}
                    sx={{display: 'flex', alignContent: 'center'}}
                  >
                    <CircularProgress />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Box sx={styles.cardContainer}>
                <Box>
                  {paymentProfilesLeftColumn.map((paymentProfile: any) => (
                    <Card
                      key={paymentProfile.payment_profile_id}
                      sx={styles.card}
                    >
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <Box
                              sx={{width: 85}}
                              component="img"
                              alt="Bank Logo"
                              src={
                                paymentProfile.provider === 'Visa'
                                  ? VisaIcon
                                  : MasterCardIcon
                              }
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="h6" component="div">
                              {`•••• •••• •••• ${paymentProfile.account_holder_number}`}
                            </Typography>
                            <Typography sx={{mb: 1.5}} color="text.secondary">
                              Expires {paymentProfile?.expiration || ''}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            {paymentProfile.payment_profile_id !==
                            primaryPaymentProfileId ? (
                              <Button
                                size="small"
                                onClick={() => {
                                  handleSetAsPrimary(
                                    paymentProfile.payment_profile_id
                                  );
                                }}
                              >
                                Set as primary
                              </Button>
                            ) : (
                              <Typography
                                fontWeight={'bold'}
                                color={'green'}
                                ml={'5px'}
                              >
                                Primary
                              </Typography>
                            )}
                          </Grid>
                          <Grid textAlign={'right'} item xs={6}>
                            {paymentProfile.payment_profile_id !==
                            primaryPaymentProfileId ? (
                              <Button
                                onClick={() => {
                                  handleRemoveCard(
                                    paymentProfile.payment_profile_id
                                  );
                                }}
                                size="small"
                              >
                                Remove
                              </Button>
                            ) : (
                              ''
                            )}
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  ))}
                </Box>
                <Box>
                  {paymentProfilesRightColumn.map((paymentProfile: any) => (
                    <Card
                      key={paymentProfile.payment_profile_id}
                      sx={{minWidth: 275, marginBottom: '25px'}}
                    >
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <Box
                              sx={{width: 85}}
                              component="img"
                              alt="Bank Logo"
                              src={
                                paymentProfile.provider === 'Visa'
                                  ? VisaIcon
                                  : MasterCardIcon
                              }
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="h6" component="div">
                              {`•••• •••• •••• ${paymentProfile.account_holder_number}`}
                            </Typography>
                            <Typography sx={{mb: 1.5}} color="text.secondary">
                              Expires {paymentProfile?.expiration || ''}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            {paymentProfile.payment_profile_id !==
                            primaryPaymentProfileId ? (
                              <Button
                                size="small"
                                onClick={() => {
                                  handleSetAsPrimary(
                                    paymentProfile.payment_profile_id
                                  );
                                }}
                              >
                                Set as primary
                              </Button>
                            ) : (
                              <Typography
                                fontWeight={'bold'}
                                color={'green'}
                                ml={'5px'}
                              >
                                Primary
                              </Typography>
                            )}
                          </Grid>
                          <Grid textAlign={'right'} item xs={6}>
                            {paymentProfile.payment_profile_id !==
                            primaryPaymentProfileId ? (
                              <Button
                                onClick={() => {
                                  handleRemoveCard(
                                    paymentProfile.payment_profile_id
                                  );
                                }}
                                size="small"
                              >
                                Remove
                              </Button>
                            ) : (
                              ''
                            )}
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  ))}
                </Box>
              </Box>
            )}
            <Button
              onClick={() => setAddPaymentMethodDialogBox(true)}
              fullWidth
              variant="outlined"
              size="large"
              sx={{width: '50%'}}
            >
              + Add payment method
            </Button>
          </Box>
          <AddNewPaymentDialogBox
            open={openAddPaymentMethodDialogBox}
            onClose={handleCloseAddPaymentMethodDialog}
          />
          <Box>
            <Dialog
              open={openRemoveCardDialogBox}
              onClose={handleCloseRemoveCardDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Remove this payment method?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This changes cannot be reverted back. But you can always input
                  the new information back.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseRemoveCardDialog}>Yes</Button>
                <Button onClick={handleCloseRemoveCardDialog} autoFocus>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          {!invoices.length ? (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              style={{minHeight: '100vh'}}
            >
              <Grid item xs={3}>
                <Box sx={styles.loaderContainer}>
                  <CircularProgress />
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Paper>
              <TableContainer>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {columns.map(column => (
                        <TableCell key={column.id} align={column.align}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoices
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((invoice: any) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={invoice.billing_invoice_id}
                          sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                          {columns.map(({id, width}) => (
                            <TableCell style={{minWidth: width}} key={id}>
                              {handleDisplayValue(id, invoice)}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={invoices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </TabPanel>

        <ModalComponent
          isOpen={openAddPaymentMethodModal}
          onClose={handleCloseAddPaymentMethodModal}
          onCardHolderChange={setCardHolderName}
          onCardNumberChange={setCardNumber}
          onCardExpirationChange={setExpiration}
          onCardCVCChange={setCVC}
          onSave={handleSavePaymentMethod}
          onCancel={handleCloseAddPaymentMethodModal}
        />
      </Box>
    </MainViewContainer>
  );
};
