import {
  listDigiscreenSettings,
  updateDigiscreenSetting,
} from '@digistaff/business';
import {useCallback} from 'react';
import {app} from '../Account';

export const useDigiScreenSettings = () => {
  const listIntegration = useCallback(() => {
    return new Promise((resolve, reject) => {
      listDigiscreenSettings(app)
        .then(res => resolve(res.data.listDigiscreenSettings[0]))
        .catch(err => reject(err));
    });
  }, []);

  const updateIntegration = useCallback((id: string, value: boolean) => {
    return new Promise((resolve, reject) => {
      updateDigiscreenSetting(app, id, {
        is_digicheck_approval_integrated: value,
      })
        .then(res => resolve(res.data.updateDigiscreenSetting))
        .catch(err => reject(err));
    });
  }, []);

  return {listIntegration, updateIntegration};
};
