import {
  init,
  getCurrentUser,
  isSessionValid,
  signOut,
  getUserAttributes,
  updateUserAttribute,
  signInWithToken,
  DSCommonResult,
  getCurrentToken,
  setTenantKey,
} from '@digistaff/app';

const app = init({
  ClientId: process.env.COGNITO_CLIENT_ID as string,
  environment: process.env.CLOUD_ENVIRONMENT as string,
});

export {
  app,
  getCurrentUser,
  isSessionValid,
  signOut,
  getUserAttributes,
  updateUserAttribute,
  signInWithToken,
  DSCommonResult,
  getCurrentToken,
  setTenantKey,
};
