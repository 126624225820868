import {
  countRoleResourcePolicies,
  createRoleResourcePolicy,
  deleteRoleResourcePolicy,
  getRoleResourcePolicy,
  listRoleResourcePolicies,
  updateRoleResourcePolicy,
} from '@digistaff/core';
import {useCallback} from 'react';
import {app} from '../Account';
import {PolicyStruct} from '../../types/types';
import {RoleStruct} from '../../views/RolesView/rolesViewData';

export const useRoleResourcePolicy = () => {
  const countAllRoleResourcePolicies = useCallback(() => {
    return new Promise((resolve, reject) => {
      countRoleResourcePolicies(app)
        .then(res => resolve(res.data.countRoleResourcePolicies))
        .catch(err => reject(err));
    });
  }, []);

  const listAllRoleResourcePolicies = useCallback((filter = {}) => {
    return new Promise<PolicyStruct[]>((resolve, reject) => {
      listRoleResourcePolicies(app, {filter})
        .then(res => resolve(res.data.listRoleResourcePolicies))
        .catch(err => reject(err));
    });
  }, []);

  const getARoleResourcePolicy = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      getRoleResourcePolicy(app, id)
        .then(res => resolve(res.data.getRoleResourcePolicy))
        .catch(err => reject(err));
    });
  }, []);

  const createARoleResourcePolicy = useCallback((payload: Object) => {
    return new Promise((resolve, reject) => {
      createRoleResourcePolicy(app, payload)
        .then(res => resolve(res.data.createRoleResourcePolicy))
        .catch(err => reject(err));
    });
  }, []);

  const deleteARoleResourcePolicy = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      deleteRoleResourcePolicy(app, id)
        .then(res => resolve(res.data.deleteRoleResourcePolicy))
        .catch(err => reject(err));
    });
  }, []);

  const updateARoleResourcePolicy = useCallback(
    (id: string, payload: Object) => {
      return new Promise<RoleStruct>((resolve, reject) => {
        updateRoleResourcePolicy(app, id, payload)
          .then(res => resolve(res.data.deleteRoleResourcePolicy))
          .catch(err => reject(err));
      });
    },
    []
  );

  return {
    countAllRoleResourcePolicies,
    listAllRoleResourcePolicies,
    getARoleResourcePolicy,
    createARoleResourcePolicy,
    deleteARoleResourcePolicy,
    updateARoleResourcePolicy,
  };
};
