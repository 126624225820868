/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {createAppointmentCustomDateSetting} from '@digistaff/business';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useCallback, useState} from 'react';
import {app} from '../../providers/Account';
import {Confirm} from '../../common/Alerts/Confirm';
import moment from 'moment';
import {ToastStruct} from '../../types/types';
import {Toast} from '../../common/Alerts/Toast';

export const CreateAppointment = () => {
  const [date, setDate] = useState<any>(null);
  const [startTime, setStartTime] = useState<any>(null);
  const [endTime, setEndTime] = useState<any>(null);
  const [interval, setInterval] = useState(0);
  const [bookings, setBookings] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });

  const createCustomSlot = useCallback(async () => {
    const formattedDate = moment(new Date(date)).format('YYYY-MM-DD');
    const formattedStart = moment(new Date(startTime)).format('HH:mm:SS');
    const formattedEnd = moment(new Date(endTime)).format('HH:mm:ss');

    const startDate = moment(
      `${formattedDate} ${formattedStart}`,
      'YYYY-MM-DD HH:mm:SS'
    );
    const endDate = moment(
      `${formattedDate} ${formattedEnd}`,
      'YYYY-MM-DD HH:mm:SS'
    );

    const payload = {
      booking_per_slot: bookings,
      interval_minutes: interval,
      status: 'active',
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
    };

    setLoading(false);
    const result: any = await createAppointmentCustomDateSetting(app, payload);
    if (result.data.createAppointmentCustomDateSetting.id) {
      setToastData({
        ...toastData,
        severity: 'success',
        text: 'Custom appointment slot has been created',
      });
      clearForm();
    } else {
      setToastData({...toastData, severity: 'error', text: result});
    }
    setShowToast(true);
  }, [date, bookings, interval, startTime, endTime, toastData]);

  const verifyForm = () => {
    const formErrors: any = {};
    if (date === null) formErrors.date = 'Slot date is required';
    if (startTime === null) formErrors.start = 'Start time is required';
    if (endTime === null) formErrors.end = 'End time is required';
    if (interval < 1) formErrors.interval = 'Slot interval is required';
    if (bookings < 1) formErrors.bookings = 'Bookings per slot is required';

    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) setOpenConfirm(true);
    else setLoading(false);
  };

  const clearForm = () => {
    setDate(null);
    setStartTime(null);
    setEndTime(null);
    setInterval(0);
    setBookings(0);
    setLoading(false);
    setErrors({});
  };

  return (
    <Box sx={{marginTop: '25px'}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                renderInput={props => (
                  <TextField
                    {...props}
                    size="small"
                    error={'date' in errors}
                    helperText={errors.date}
                  />
                )}
                label="Slot Date"
                inputFormat="DD/MM/YYYY"
                minDate={moment(new Date())}
                value={date}
                onChange={value => {
                  setDate(value);
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                renderInput={props => (
                  <TextField
                    {...props}
                    size="small"
                    error={'start' in errors}
                    helperText={errors.start}
                  />
                )}
                disabled={date === null}
                label="Start Time"
                value={startTime}
                onChange={value => {
                  setStartTime(value);
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                renderInput={props => (
                  <TextField
                    {...props}
                    size="small"
                    error={'end' in errors}
                    helperText={errors.end}
                  />
                )}
                disabled={startTime === null}
                label="Slot End"
                minTime={startTime}
                value={endTime}
                onChange={value => {
                  setEndTime(value);
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            type="number"
            fullWidth
            label="Slot Interval"
            value={interval}
            onChange={event => setInterval(+event.target.value)}
            error={'interval' in errors}
            helperText={errors.interval}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            type="number"
            fullWidth
            label="Bookings per Slot"
            value={bookings}
            onChange={event => setBookings(+event.target.value)}
            error={'bookings' in errors}
            helperText={errors.bookings}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              verifyForm();
            }}
          >
            {loading ? <CircularProgress /> : 'Add'}
          </Button>
        </Grid>
      </Grid>
      <Confirm
        open={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        handleConfirmed={() => {
          setOpenConfirm(false);
          setLoading(true);
          createCustomSlot();
        }}
        text="Are you sure you want to create this custom slot?"
      />
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        text={toastData.text}
        severity={toastData.severity}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
    </Box>
  );
};
