import RouteErrorView from '../views/RouteErrorView/RouteErrorView';
import {InvoicesView} from '../views/InvoicesView';
import {SubscriptionView} from '../views/SubscriptionView/SubscriptionView';
import {PrivateRoute} from './gaurds/PrivateRoute';
import {UsersView} from '../views/UsersView/UsersView';
import {RolesView} from '../views/RolesView/RolesView';
import {PreferencesTableView} from '../views/PreferencesTableView';
import {GeneralView} from '../views/GeneralView/GeneralView';
import {DigiTrackView} from '../views/DigiTrackView/DigiTrackView';
import {VacationsView} from '../views/VacationsView/VacationsView';
import {NotificationsView} from '../views/NotificationsView/NotificationsView';
import {SecurityView} from '../views/SecurityView/SecurityView';
import {OnboardingView} from '../views/OnboardingView/Onboarding';
import {BusinessView} from '../views/BusinessView/BusinessView';
import {AppointmentsView} from '../views/AppointmentsView/AppointmentsView';
import {CertificationsView} from '../views/CertificationsView/CertificationsView';
import {EquipmentView} from '../views/EquipmentView/EquipmentView';
import {KioskView} from '../views/KioskView/KioskView';
import {StaffCustomAttributesView} from '../views/StaffCustomAttributesView/StaffCustomAttributesView';
import {ContractView} from '../views/ContractView/ContractView';
import {ManageContract} from '../views/ContractView/ManageContract';
import {EnabledCertificationsView} from '../views/EnabledCertificationsView/EnabledCertificationsView';
import {ShiftSettingsView} from '../views/ShiftSettingsView/ShiftSettingsView';
import {Automation} from '../views/PayrollView/Automation';
import {Settings} from '../views/PayrollView/Settings';

export const privateRoutes = [
  {
    path: '/subscription',
    element: (
      <PrivateRoute>
        <SubscriptionView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/invoices',
    element: (
      <PrivateRoute>
        <InvoicesView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/users',
    element: (
      <PrivateRoute>
        <UsersView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/roles',
    element: (
      <PrivateRoute>
        <RolesView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/preferences',
    element: (
      <PrivateRoute>
        <PreferencesTableView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/general',
    element: (
      <PrivateRoute>
        <GeneralView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/digitrack',
    element: (
      <PrivateRoute>
        <DigiTrackView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/vacations',
    element: (
      <PrivateRoute>
        <VacationsView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/notifications',
    element: (
      <PrivateRoute>
        <NotificationsView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/contracts',
    element: (
      <PrivateRoute>
        <ContractView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/contracts/manage',
    element: (
      <PrivateRoute>
        <ManageContract />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/security',
    element: (
      <PrivateRoute>
        <SecurityView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/onboarding',
    element: (
      <PrivateRoute>
        <OnboardingView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/business',
    element: (
      <PrivateRoute>
        <BusinessView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/appointments',
    element: (
      <PrivateRoute>
        <AppointmentsView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/certifications',
    element: (
      <PrivateRoute>
        <CertificationsView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/equipment',
    element: (
      <PrivateRoute>
        <EquipmentView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/kiosk',
    element: (
      <PrivateRoute>
        <KioskView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/certifications/enabled',
    element: (
      <PrivateRoute>
        <EnabledCertificationsView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/staff-custom-attributes',
    element: (
      <PrivateRoute>
        <StaffCustomAttributesView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/shift-settings',
    element: (
      <PrivateRoute>
        <ShiftSettingsView />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/payroll/settings',
    element: (
      <PrivateRoute>
        <Settings />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/payroll/automation',
    element: (
      <PrivateRoute>
        <Automation />,
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
];
