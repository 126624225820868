import {CancelRounded} from '@mui/icons-material';
import {Box, Divider, Modal, Stack, Typography} from '@mui/material';
import {popupCloseBtn, popupModal} from './globalModalStyles';

interface GlobalModalProps {
  open: boolean;
  onClose: (open: boolean) => void;
  children: React.ReactNode;
  title?: string;
  defaultHeader: boolean;
  width?: string;
  height?: string;
}

/**
 * Popup modal that displays a title, close handler, and whatever children are passed
 *
 * @param open - Whether to show the modal or not
 * @param onClose - Handles what happens when the component is closed
 * @param children - JSX element to render in the modal
 * @param title - Optional title of the modal
 * @param defaultHeader - Flag whether or not to render the default header (title & close icon)
 * @param width - Optional width of the modal. auto if no value is provided
 * @param height - Optional height of the modal. auto if no value is provided
 */
export const GlobalModal = ({
  open,
  onClose,
  children,
  title,
  defaultHeader,
  width,
  height,
}: GlobalModalProps) => {
  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <Stack
        sx={popupModal}
        width={width ? width : 'auto'}
        height={height ? height : 'auto'}
      >
        {defaultHeader && (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4" marginBottom="15px">
                {title}
              </Typography>
              <CancelRounded
                onClick={() => onClose(false)}
                sx={popupCloseBtn}
              />
            </Stack>
            <Divider />
          </>
        )}
        <Box sx={{overflow: 'auto', padding: '15px'}} height="100%">
          {children}
        </Box>
      </Stack>
    </Modal>
  );
};
