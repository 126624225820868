import {countCertifications, listCertifications} from '@digistaff/business';
import {Box, Button} from '@mui/material';
import {GridRowParams} from '@mui/x-data-grid';
import {useCallback, useEffect, useState} from 'react';
import {Toast} from '../../common/Alerts/Toast';
import {DataTable} from '../../common/DataTable/DataTable';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {app} from '../../providers/Account';
import {ToastStruct} from '../../types/types';
import {certificationsGridCols, CertificationStruct} from './CertificationData';
import {ManageCertification} from './ManageCertification';

export const CertificationsView = () => {
  const [certifications, setCertifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [showMange, setShowMange] = useState(false);
  const [data, setData] = useState<null | CertificationStruct>(null);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    verticalPos: 'bottom',
    horizontalPos: 'center',
    severity: 'info',
    text: '',
  });

  const getCertifications = useCallback(
    (
      page: number,
      pageSize: number,
      filter: Object = {},
      sorting: Object[] = [{column: 'updated_at', order: 'desc'}]
    ) => {
      listCertifications(app, {
        order: sorting,
        limit: pageSize,
        offset: page * pageSize,
        filter: filter,
      })
        .then(res => {
          setCertifications(res.data.listCertifications);
          setLoading(false);
        })
        .catch(err => {
          setToastData({severity: 'error', text: err});
          setShowToast(true);
        });
    },
    []
  );

  useEffect(() => {
    countCertifications(app)
      .then(res => setRowCount(res.data.countCertifications))
      .catch(err => {
        setToastData({severity: 'error', text: err});
        setShowToast(true);
      });
  }, []);

  const handleRowClick = (event: GridRowParams) => {
    setData({
      id: event.row.id,
      name: event.row.name,
      description: event.row.description,
    });
    setShowMange(true);
  };

  const handleCloseManage = () => {
    setData(null);
    setShowMange(false);
  };

  const displayToast = (data: ToastStruct) => {
    setToastData(data);
    setShowToast(true);
  };

  return (
    <MainViewContainer title="Certifications">
      <Box
        sx={{display: 'flex', justifyContent: 'flex-end', marginBottom: '15px'}}
      >
        <Button variant="outlined" onClick={() => setShowMange(true)}>
          Add Certification
        </Button>
      </Box>
      <DataTable
        rows={certifications}
        columns={certificationsGridCols}
        loading={loading}
        rowCount={rowCount}
        handleRowClick={handleRowClick}
        getData={getCertifications}
      />
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        {...toastData}
      />
      <ManageCertification
        open={showMange}
        handleClose={handleCloseManage}
        reload={() => getCertifications(0, 10)}
        data={data}
        displayToast={displayToast}
      />
    </MainViewContainer>
  );
};
