/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  countOpsProfileTenants,
  createOpsProfile,
  createOpsProfileTenant,
  deleteOpsProfileTenant,
  getOpsProfile,
  listOpsProfileTenants,
  updateOpsProfile,
  updateOpsProfileTenant,
} from '@digistaff/business';
import {app} from '../Account';
import {useCallback} from 'react';
import {createUserWithEmailAndPassword} from '@digistaff/app';

export const useOps = () => {
  const listAllOps = useCallback(
    async (
      page: number,
      pageSize: number,
      filter: Object = {},
      sorting: Object[] = [{column: 'created_at', order: 'desc'}]
    ) => {
      return new Promise((resolve, reject) => {
        listOpsProfileTenants(app, {
          filter: filter,
          order: sorting,
          limit: pageSize,
          offset: page * pageSize,
        })
          .then(res => {
            resolve(res.data.listOpsProfileTenants);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    []
  );

  const inviteOp = useCallback((payload: any) => {
    return new Promise((resolve, reject) => {
      createOpsProfileTenant(app, payload)
        .then(() => {
          getOpsProfile(app, payload.id).then(res => {
            if (res.data.getOpsProfile === null) {
              createOpsProfile(app, {
                id: payload.id,
                tenants: [app.tenantKey],
                birthdate: null,
                first_name: '-',
                last_name: '-',
                email: payload.id,
                current_onboarding_step: 0,
                current_selected_tenant: app.tenantKey,
              })
                .then(res => {
                  const tempPassword =
                    res.data.createOpsProfile.temporary_credentials;
                  const emailProvider =
                    res.data.createOpsProfile.email_provider;

                  createUserWithEmailAndPassword(app, payload.id, tempPassword)
                    .then(() => {
                      resolve(emailProvider);
                    })
                    .catch(err => reject(err));
                })
                .catch(err => reject(err));
            } else {
              const profile = res.data.getOpsProfile;
              const newTenants = [...profile.tenants];

              if (!newTenants.includes(app.tenantKey))
                newTenants.push(app.tenantKey);
              else resolve('already_invited');

              updateOpsProfile(app, profile.id, {
                tenants: newTenants,
              })
                .then(() => resolve('existing_invited'))
                .catch(err => reject(err));
            }
          });
        })
        .catch(err => reject(err));
    });
  }, []);

  const updateOpTenant = useCallback((id: string, payload: any) => {
    return new Promise((resolve, reject) => {
      updateOpsProfileTenant(app, id, payload)
        .then(() => resolve('updated_tenant'))
        .catch(err => reject(err));
    });
  }, []);

  const updateOpProfile = useCallback((id: string, payload: any) => {
    return new Promise((resolve, reject) => {
      updateOpsProfile(app, id, payload)
        .then(() => resolve('updated_profile'))
        .catch(err => reject(err));
    });
  }, []);

  const countOps = useCallback(() => {
    return new Promise((resolve, reject) => {
      countOpsProfileTenants(app)
        .then(res => {
          resolve(res.data.countOpsProfileTenants);
        })
        .catch(err => reject(err));
    });
  }, []);

  const deleteOp = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      deleteOpsProfileTenant(app, id)
        .then(() => {
          resolve('deleted');
        })
        .catch(err => reject(err));
    });
  }, []);

  const getCurrentLoggedOps = () => {
    try {
      return app.session.lastAuthUser || app.user.username;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  return {
    listAllOps,
    inviteOp,
    updateOpTenant,
    updateOpProfile,
    countOps,
    deleteOp,
    getCurrentLoggedOps,
  };
};
