/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button, CircularProgress, Stack, Tooltip} from '@mui/material';
import {GlobalModal} from '../../common/GlobalModal/GlobalModal';
import {useAppointments} from '../../providers/api/useAppointments';
import {useCallback, useEffect, useState} from 'react';
import {CategoryRow} from './CategoryRow';
import {nanoid} from 'nanoid';
import {Confirm} from '../../common/Alerts/Confirm';
import {Toast} from '../../common/Alerts/Toast';
import {ToastStruct} from '../../types/types';
import {AddCircleOutline} from '@mui/icons-material';

export interface CategoriesProps {
  open: boolean;
  handleClose: () => void;
  setToastData: any;
  setShowToast: any;
}

export const Categories = ({
  open,
  handleClose,
  setToastData,
  setShowToast,
}: CategoriesProps) => {
  const {listCategories, saveAllCategories} = useAppointments();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);

  const setCategoriesList = useCallback(async () => {
    const allCategories: any = await listCategories();
    setLoading(false);
    setCategories(allCategories);
  }, [listCategories, setCategories]);

  const handleRowUpdate = (row: any) => {
    const index = categories.findIndex((state: any) => state.id === row.id);
    const newState: any = [...categories];
    newState[index] = row;
    setCategories(newState);
  };

  const handleRowAdd = () => {
    const newRow = {
      id: 'NEW_CATEGORY_' + nanoid(),
      name: '',
      input_type: 'text',
      apprise_hours: 24,
      status: 'active',
    };
    const newState: any = [...categories, newRow];
    setCategories(newState);
  };

  const handleSave = async () => {
    setShowConfirm(false);
    const invalid = categories.find(
      (category: any) =>
        category.status !== 'deleted' &&
        (category.name.length < 1 || category.appraise_hours < 0)
    );

    if (invalid) {
      setToastData({
        severity: 'error',
        text: 'Please fill out all the fields',
      });
    } else {
      const status: any = await saveAllCategories(categories);
      if (status === 'success') {
        setToastData({
          severity: 'success',
          text: 'Categories have been updated',
        });
      } else {
        setToastData({
          severity: 'error',
          text: status.toString(),
        });
      }
    }
    setShowToast(true);
  };

  useEffect(() => {
    setCategoriesList();
  }, [setCategoriesList]);

  return (
    <>
      <GlobalModal
        open={open}
        onClose={handleClose}
        defaultHeader={true}
        title="Edit Categories"
        width="80vw"
        height="55vh"
      >
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '10px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{marginBottom: '25px'}}>
              <Button variant="outlined" onClick={() => setShowConfirm(true)}>
                Save
              </Button>
            </Box>
            <Stack spacing={1}>
              {categories.map((category: any) => {
                if (category.status !== 'deleted')
                  return (
                    <CategoryRow
                      key={category.id}
                      category={category}
                      handleUpdate={handleRowUpdate}
                    />
                  );
                else return null;
              })}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Add Category">
                  <AddCircleOutline
                    sx={{
                      fontSize: '36px',
                      cursor: 'pointer',
                    }}
                    onClick={handleRowAdd}
                  />
                </Tooltip>
              </Box>
            </Stack>
          </>
        )}
      </GlobalModal>
      <Confirm
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        handleConfirmed={handleSave}
        text="Are you sure you want to update the appointment categories?"
      />
    </>
  );
};
