/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import {GlobalModal} from '../../common/GlobalModal/GlobalModal';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {useCallback, useEffect, useState} from 'react';
import {DataTable} from '../../common/DataTable/DataTable';
import {staffCustomAttributesViewData} from './StaffCustomAttributesData';
import {GridCellParams} from '@mui/x-data-grid';
import {Toast} from '../../common/Alerts/Toast';
import {ToastStruct} from '../../types/types';
import {Confirm} from '../../common/Alerts/Confirm';
import {useStaffCustomAttribute} from '../../providers/api/useStaffCustomAttribute';

export const StaffCustomAttributesView = () => {
  const {apiCreate, apiList, apiRemove, apiUpdate} = useStaffCustomAttribute();
  const [openAdd, setOpenAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [attributes, setAttributes] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmAdd, setShowConfirmAdd] = useState(false);
  const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [mode, setMode] = useState('');

  const defaultValue = {
    name: '',
    description: '',
    type: 'number',
    status: 'active',
    unit: '',
    min: 0,
    max: 0,
  };

  const [state, setState] = useState<any>(defaultValue);

  const getData = useCallback(async () => {
    const attributesData: any = await apiList();

    setAttributes(attributesData);

    setLoading(false);
  }, [apiList]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleDelete = async (id: string) => {
    const result: any = await apiRemove(id);
    if (result) {
      setToastData({
        ...toastData,
        severity: 'success',
        text: 'Attribute has been deleted',
      });
    } else {
      setToastData({
        ...toastData,
        severity: 'error',
        text: 'Error, could not delete the Attribute',
      });
    }
    setShowToast(true);
    getData();
  };

  const handleUpdate = async (id: string) => {
    const result: any = await apiUpdate(id, state);
    if (result) {
      setToastData({
        ...toastData,
        severity: 'success',
        text: 'Attribute has been updated',
      });
    } else {
      setToastData({
        ...toastData,
        severity: 'error',
        text: 'Error, could not update the Attribute',
      });
    }
    setShowToast(true);
    getData();
  };

  const handleAddAttribute = async () => {
    const result = await apiCreate(state);

    if (result) {
      setToastData({
        ...toastData,
        severity: 'success',
        text: 'Attribute has been created',
      });
    } else {
      setToastData({
        ...toastData,
        severity: 'error',
        text: 'Error, could not create the attribute',
      });
    }

    setState(defaultValue);
    setOpenAdd(false);
    setShowToast(true);
    getData();
  };

  return (
    <>
      <MainViewContainer title="Staff Custom Attributes">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '15px',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setMode('Add');
              setState(defaultValue);
              setOpenAdd(true);
            }}
          >
            Create New Attribute
          </Button>
        </Box>
        <DataTable
          rows={attributes}
          columns={staffCustomAttributesViewData}
          loading={loading}
          rowCount={attributes.length}
          handleCellClick={(params: GridCellParams) => {
            setCurrentId(params.id.toString());
            if (params.field === 'delete') {
              setShowConfirmDelete(true);
            } else if (params.field === 'edit') {
              setMode('Edit');

              setState({
                name: params.row.name,
                status: params.row.status,
                type: params.row.type,
                unit: params.row.unit,
                description: params.row.description,
                min: params.row.min,
                max: params.row.max,
              });
              setOpenAdd(true);
            }
          }}
          getData={() => {}}
        />
      </MainViewContainer>
      <GlobalModal
        open={openAdd}
        onClose={() => setOpenAdd(false)}
        defaultHeader={true}
        title={`${mode} attribute`}
        width="40vw"
      >
        <Stack spacing={1}>
          <TextField
            label="Name"
            size="small"
            value={state.name}
            onChange={event => {
              setState({...state, name: event.target.value});
            }}
          />
          <TextField
            label="Description"
            size="small"
            value={state.description}
            onChange={event => {
              setState({...state, description: event.target.value});
            }}
          />
          <FormControl size="small">
            <InputLabel id="inputlbl">Type</InputLabel>
            <Select
              labelId="inputlbl"
              id="input"
              label="Status"
              value={state.type}
              onChange={event => {
                setState({...state, type: event.target.value});
              }}
            >
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="text">Text</MenuItem>
            </Select>
          </FormControl>
          {state.type === 'number' ? (
            <Stack spacing={1}>
              <TextField
                label="Unit"
                size="small"
                value={state.unit}
                onChange={event => {
                  setState({...state, unit: event.target.value});
                }}
              />

              <TextField
                label="Minimum"
                size="small"
                value={state.min}
                type="number"
                onChange={event => {
                  setState({...state, min: event.target.value});
                }}
              />

              <TextField
                label="Maximum"
                size="small"
                value={state.max}
                type="number"
                onChange={event => {
                  setState({...state, max: event.target.value});
                }}
              />
            </Stack>
          ) : (
            ''
          )}

          {mode === 'Add' ? (
            <Button
              variant="outlined"
              onClick={() => {
                let canSave = true;

                if (!state.name) canSave = false;

                if (state.type === 'number' && !state.unit) canSave = false;

                if (canSave) setShowConfirmAdd(true);
              }}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={() => setShowConfirmUpdate(true)}
            >
              Update
            </Button>
          )}
        </Stack>
      </GlobalModal>
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        text={toastData.text}
        severity={toastData.severity}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
      <Confirm
        open={showConfirmAdd}
        handleClose={() => setShowConfirmAdd(false)}
        handleConfirmed={() => {
          setShowConfirmAdd(false);
          handleAddAttribute();
        }}
        text="Are you sure you want to add this attribute?"
      />
      <Confirm
        open={showConfirmDelete}
        handleClose={() => setShowConfirmDelete(false)}
        handleConfirmed={() => {
          setShowConfirmDelete(false);
          handleDelete(currentId);
        }}
        text="Are you sure you want to delete this attribute?"
      />
      <Confirm
        open={showConfirmUpdate}
        handleClose={() => setShowConfirmUpdate(false)}
        handleConfirmed={() => {
          setShowConfirmUpdate(false);
          handleUpdate(currentId);
        }}
        text="Are you sure you want to update this attribute?"
      />
    </>
  );
};
