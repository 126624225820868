import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {GlobalModal} from '../../../common/GlobalModal/GlobalModal';
import {useCallback, useEffect, useState} from 'react';
import {emptyRole, RoleStruct} from '../rolesViewData';
import {useResource} from '../../../providers/api/useResource';
import {
  NewRoleErrorsStruct,
  PolicyStruct,
  ResourceStruct,
  ToastStruct,
} from '../../../types/types';
import {PermissionGroup} from './PermissionGroup';
import {useRole} from '../../../providers/api/useRole';
import {useRoleResourcePolicy} from '../../../providers/api/useRoleResourcePolicy';

export interface ManageModalProps {
  title: string;
  open: boolean;
  handleClose: () => void;
  currentRole?: RoleStruct;
  handleToast: (data: ToastStruct) => void;
}

export const ManageModal = ({
  title,
  open,
  handleClose,
  currentRole,
  handleToast,
}: ManageModalProps) => {
  const [role, setRole] = useState<RoleStruct>(emptyRole);
  const [resources, setResources] = useState<ResourceStruct[]>([]);
  const [policies, setPolicies] = useState<PolicyStruct[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [errors, setErrors] = useState<NewRoleErrorsStruct>({});
  const {listAllResources} = useResource();
  const {createARole, updateARole} = useRole();
  const {
    listAllRoleResourcePolicies,
    createARoleResourcePolicy,
    updateARoleResourcePolicy,
  } = useRoleResourcePolicy();

  const getResourceList = useCallback(async () => {
    const resourceList: ResourceStruct[] = await listAllResources();
    setResources(resourceList);
    setLoading(false);
  }, [listAllResources]);

  const getPolicyList = useCallback(
    async (id: string) => {
      const policyList: PolicyStruct[] = await listAllRoleResourcePolicies({
        role_id: id,
      });
      setPolicies(policyList);
    },
    [listAllRoleResourcePolicies]
  );

  const handleUpdate = useCallback(
    (id: string, allow: string[], policyId?: string) => {
      const exists = policies.find(policy => policy.resource_id === id);

      if (!exists) {
        const newPolicies = [...policies, {id: id, allow: allow}];
        setPolicies(newPolicies);
      } else {
        const filteredPolicies = policies.filter(
          policy => policy.resource_id !== id
        );
        const newPolicies = [
          ...filteredPolicies,
          {id: id, allow: allow, resource_id: policyId},
        ];
        setPolicies(newPolicies);
      }
    },
    [policies]
  );

  const handleCreateRole = async () => {
    let roleId: string;
    if (!currentRole) {
      const result = await createARole({
        name: role.name,
        description: role.description,
        is_location_bound: role.is_location_bound,
      });
      roleId = result.id;
    } else {
      const result = await updateARole(currentRole.id, {
        name: role.name,
        description: role.description,
        is_location_bound: role.is_location_bound,
      });
      roleId = result.id;
    }

    policies.forEach(async (policy: PolicyStruct) => {
      if (policy.resource_id) {
        await updateARoleResourcePolicy(policy.resource_id, {
          allow: policy.allow,
        });
      } else {
        await createARoleResourcePolicy({
          role_id: roleId,
          resource_id: policy.id,
          allow: policy.allow,
        });
      }
    });

    setLoadingCreate(false);
    let action = 'created';
    if (currentRole) action = 'updated';

    handleToast({
      horizontalPos: 'center',
      verticalPos: 'bottom',
      severity: 'success',
      text: `Role has been ${action}`,
    });
  };

  const closeModal = () => {
    setResources([]);
    setPolicies([]);
    setRole(emptyRole);
    handleClose();
  };

  const verifyInput = () => {
    const newErrors: NewRoleErrorsStruct = {};
    if (role.name.trim().length === 0) newErrors.name = 'Role name is required';
    if (role.description.trim().length === 0)
      newErrors.description = 'Role description is required';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      handleToast({
        horizontalPos: 'center',
        verticalPos: 'bottom',
        severity: 'warning',
        text: 'Please fill out all fields',
      });
    } else {
      setErrors({});
      setLoadingCreate(true);
      handleCreateRole();
    }
  };

  useEffect(() => {
    if (open) {
      if (currentRole) {
        setRole(currentRole);
        getPolicyList(currentRole.id);
      }
      getResourceList();
    }
  }, [open, getResourceList, currentRole, setRole, getPolicyList]);

  return (
    <GlobalModal
      open={open}
      onClose={closeModal}
      defaultHeader={true}
      title={title}
      width="80vw"
      height="95vh"
    >
      {loading ? (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Grid container spacing={2} columns={10}>
            <Grid item xs={10}>
              <Typography variant="h5">Details</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                label="Title"
                variant="outlined"
                size="small"
                value={role.name}
                helperText={errors.name}
                error={'name' in errors}
                onChange={event => setRole({...role, name: event.target.value})}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                size="small"
                helperText={errors.description}
                error={'description' in errors}
                value={role.description}
                onChange={event =>
                  setRole({...role, description: event.target.value})
                }
              />
            </Grid>
            <Grid item xs={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={role.is_location_bound}
                    onChange={() =>
                      setRole({
                        ...role,
                        is_location_bound: !role.is_location_bound,
                      })
                    }
                  />
                }
                label="Is Location Bound"
              />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h5">Permissions</Typography>
            </Grid>
            {resources.map((resource: ResourceStruct) => (
              <Grid item xs={2} key={resource.id}>
                <PermissionGroup
                  resource={resource}
                  handleUpdate={handleUpdate}
                  policy={policies.find(
                    policy => policy.resource_id === resource.id
                  )}
                />
              </Grid>
            ))}
            <Grid item xs={10}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => verifyInput()}
              >
                {currentRole ? (
                  'Update'
                ) : loadingCreate ? (
                  <CircularProgress />
                ) : (
                  'Add Role'
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </GlobalModal>
  );
};
