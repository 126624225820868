/* eslint-disable @typescript-eslint/no-explicit-any */
import {Home, Logout, Person} from '@mui/icons-material';
import {AuthContext} from '../../providers/AuthProvider';
import {useCallback, useContext, useEffect, useState} from 'react';
import {
  Box,
  IconButton,
  MenuItem,
  ListItemIcon,
  Avatar,
  Divider,
  Menu,
  Tooltip,
} from '@mui/material';
import {fetchOpsTenants, getCurrentLoggedOps} from '../../providers/api/ops';
import {getTenant} from '../../providers/api/tenant';
import {app, setTenantKey} from '../../providers/Account';
import {updateOpsProfile} from '@digistaff/business';

export const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const {logOut} = useContext(AuthContext);
  const open = Boolean(anchorEl);
  const isLocal = window.location.origin === process.env.LOCAL_ADMIN_URL;
  const [logo, setLogo] = useState<null | string>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccount = (newTab: boolean) => {
    const redirectBaseUrl = isLocal
      ? process.env.LOCAL_ACCOUNTS_URL
      : process.env.DS_ACCOUNTS_URL;

    const url = `${redirectBaseUrl}`;

    if (newTab) window.open(url, '_blank');
    else window.location.href = url;
  };

  const listTenants = async (opsId: string) => {
    const tenantRows: any = [];
    const tenants = await fetchOpsTenants(opsId);

    for (let step = 0; step < tenants.length; step++) {
      const tenantProfile: any = await getTenant(tenants[step]);
      tenantRows.push({
        id: tenantProfile.id,
        name: tenantProfile.name,
        business: tenantProfile.businesses,
        staff: tenantProfile.staff,
        logo: tenantProfile.logo,
      });
    }

    return tenantRows;
  };

  const getLogo = useCallback(async () => {
    if (app.tenantKey) {
      const tenant = await getTenant(app.tenantKey);
      setLogo(tenant.logo);
    }
  }, []);

  useEffect(() => {
    if (open) {
      listTenants(getCurrentLoggedOps())
        .then(res => {
          setTenants(res);
          setLoading(false);
        })
        .catch(err => console.log(err));
    }
  }, [open]);

  useEffect(() => {
    listTenants(getCurrentLoggedOps())
      .then(res => {
        setTenants(res);
        getLogo();
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, [getLogo]);

  const switchTenant = (id: string) => {
    updateOpsProfile(app, getCurrentLoggedOps(), {
      current_selected_tenant: id,
    })
      .then(() => {
        setTenantKey(app, id);
        localStorage.setItem('tenant', id);
        location.reload();
      })
      .catch(err => console.log(err));
  };

  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        <Tooltip title="Account Settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {logo === null ? (
              <Person sx={{fontSize: '1.75em', color: 'white'}} />
            ) : (
              <Avatar src={logo} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            marginTop: '10px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem
          onClick={() => handleAccount(false)}
          onAuxClick={() => handleAccount(true)}
        >
          <ListItemIcon>
            <Avatar />
          </ListItemIcon>
          My Account
        </MenuItem>
        <Divider />

        {loading && (
          <MenuItem disabled>
            <ListItemIcon>
              <Home fontSize="small" />
            </ListItemIcon>
            Loading Tenants
          </MenuItem>
        )}

        {!loading &&
          tenants.length > 1 &&
          tenants.map((tenant: any) => (
            <MenuItem
              key={tenant.id}
              onClick={() => switchTenant(tenant.id)}
              sx={{
                backgroundColor:
                  tenant.id === app.tenantKey ? 'lightgray' : 'white',
              }}
            >
              <ListItemIcon>
                <Avatar src={tenant.logo}>{tenant.name.substring(0, 1)}</Avatar>
              </ListItemIcon>
              {tenant.name}
            </MenuItem>
          ))}
        {!loading && tenants.length > 1 && <Divider />}
        <MenuItem onClick={() => logOut()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
