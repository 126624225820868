export interface RoleStruct {
  id: string;
  name: string;
  description: string;
  is_location_bound: boolean;
}

export const emptyRole: RoleStruct = {
  id: '',
  name: '',
  description: '',
  is_location_bound: false,
};
