import {
  FormControlLabel,
  Checkbox,
  Slider,
  Stack,
  Typography,
  Button,
  Tooltip,
} from '@mui/material';
import {useState} from 'react';
import {Toast} from '../../common/Alerts/Toast';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';

const valuetext = (value: number) => {
  return `${value} M`;
};

export const DigiTrackView = () => {
  const [clockOut, setClockOut] = useState(false);
  const [alertZone, setAlertZone] = useState(500);
  const [checkInZone, setCheckInZone] = useState(500);
  const [show, setShow] = useState(false);

  return (
    <MainViewContainer title="DigiTrack">
      <Stack spacing={2}>
        <Typography variant="h6" width="fit-content">
          Alert Zone
        </Typography>
        <Typography>
          If enabled, workers will automatically be clocked out if they X KM
          outside of the business location radius
        </Typography>
        <FormControlLabel
          sx={{width: 'fit-content'}}
          control={
            <Checkbox value={clockOut} onClick={() => setClockOut(!clockOut)} />
          }
          label="Auto Clock Out Workers"
        />
        {clockOut && (
          <Stack direction="row" spacing={2}>
            <Typography>Distance (m):</Typography>
            <Slider
              value={alertZone}
              onChange={(event, value) => setAlertZone(+value)}
              min={500}
              max={10000}
              step={500}
              valueLabelDisplay="auto"
              sx={{width: '50%'}}
              getAriaValueText={valuetext}
            />
          </Stack>
        )}
        <Tooltip
          title="Distance the worker needs to be from the location,
          to be considered as checked in"
        >
          <Typography variant="h6" width="fit-content">
            Check In Zone
          </Typography>
        </Tooltip>
        <Stack direction="row" spacing={2}>
          <Typography>Distance (m):</Typography>
          <Slider
            value={checkInZone}
            onChange={(event, value) => setCheckInZone(+value)}
            min={500}
            max={10000}
            step={500}
            valueLabelDisplay="auto"
            sx={{width: '50%'}}
            getAriaValueText={valuetext}
          />
        </Stack>
        <Button
          variant="outlined"
          sx={{width: '250px'}}
          onClick={() => setShow(true)}
        >
          Save
        </Button>
      </Stack>
      <Toast
        open={show}
        handleClose={() => setShow(false)}
        severity="success"
        text="DigiTrack settings have been updated"
        verticalPos="bottom"
        horizontalPos="center"
      />
    </MainViewContainer>
  );
};
