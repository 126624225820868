export interface ListEntry {
  id: string;
  type: 'user' | 'group';
  name: string;
  category: string[];
}

export const entries: ListEntry[] = [
  {
    id: '01',
    type: 'user',
    name: 'Robert',
    category: ['billing-payment'],
  },
  {
    id: '02',
    type: 'user',
    name: 'John',
    category: ['technology'],
  },
  {
    id: '03',
    type: 'user',
    name: 'Alice',
    category: ['billing-payment', 'daily-ops'],
  },
  {
    id: '11',
    type: 'group',
    name: 'Admin',
    category: ['campaign'],
  },
  {
    id: '12',
    type: 'group',
    name: 'Supervisor',
    category: ['campaign'],
  },
];
