import {Box, Button} from '@mui/material';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {useCallback, useEffect, useState} from 'react';
import {Toast} from '../../common/Alerts/Toast';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {ToastStruct} from '../../types/types';
import {ManageRoleMenu} from './ManageRoleMenu';
import {ManageModal} from './Modals/ManageModal';
import {useRole} from '../../providers/api/useRole';
import {RoleStruct} from './rolesViewData';

export const RolesView = () => {
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState<RoleStruct[]>([]);
  const [showAddRole, setShowAddRole] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });
  const {listAllRoles} = useRole();

  const handleToast = (data: ToastStruct) => {
    setToastData(data);
    setShowToast(true);
  };

  const getData = useCallback(async () => {
    const roleList: RoleStruct[] = await listAllRoles();
    setRoles(roleList);
    setLoading(false);
  }, [listAllRoles]);

  useEffect(() => {
    getData();
  }, [getData]);

  const userColumns: GridColDef[] = [
    {
      field: 'manage',
      headerName: '',
      width: 40,
      align: 'center',
      renderCell: data => (
        <ManageRoleMenu
          role={data.row}
          handleToast={(data: ToastStruct) => handleToast(data)}
          update={getData}
        />
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 500,
    },
  ];

  return (
    <MainViewContainer title="Roles">
      <Box
        sx={{display: 'flex', justifyContent: 'flex-end', marginBottom: '15px'}}
      >
        <Button
          variant="contained"
          onClick={() => {
            setShowAddRole(true);
          }}
        >
          Add Role
        </Button>
      </Box>
      <DataGrid
        sx={{minHeight: '400px'}}
        rows={roles}
        loading={loading}
        columns={userColumns}
        rowsPerPageOptions={[25, 50, 100]}
        density="compact"
      />
      <ManageModal
        title="Add Role"
        open={showAddRole}
        handleClose={() => {
          getData();
          setShowAddRole(false);
        }}
        handleToast={(data: ToastStruct) => handleToast(data)}
      />
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        severity={toastData.severity}
        text={toastData.text}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
    </MainViewContainer>
  );
};
