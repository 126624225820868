/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import {GlobalModal} from '../../common/GlobalModal/GlobalModal';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {useCallback, useEffect, useState} from 'react';
import {DataTable} from '../../common/DataTable/DataTable';
import {kioskViewData} from './kioskViewData';
import {GridCellParams} from '@mui/x-data-grid';
import {Toast} from '../../common/Alerts/Toast';
import {ToastStruct} from '../../types/types';
import {Confirm} from '../../common/Alerts/Confirm';
import {useKiosk} from '../../providers/api/useKiosk';
import {useBusiness} from '../../providers/api/useBusiness';

export const KioskView = () => {
  const {listKiosk, deleteKiosk, createKiosk, updateKiosk} = useKiosk();
  const [openAdd, setOpenAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [kiosks, setKiosks] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmAdd, setShowConfirmAdd] = useState(false);
  const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [mode, setMode] = useState('');
  const {listBusinessLocations} = useBusiness();
  const [state, setState] = useState({
    name: '',
    status: '',
    business_location_id: '',
    is_enable_photo_capture: true,
  });
  const [locations, setLocations] = useState([]);

  const getData = useCallback(async () => {
    const loc: any = await listBusinessLocations();
    const data: any = await listKiosk();

    setLocations(loc);
    setKiosks(data);
    setLoading(false);
  }, [listKiosk, listBusinessLocations]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleDelete = async (id: string) => {
    const result: any = await deleteKiosk(id);
    if (result) {
      setToastData({
        ...toastData,
        severity: 'success',
        text: 'Kiosk has been deleted',
      });
    } else {
      setToastData({
        ...toastData,
        severity: 'error',
        text: 'Error, could not delete the Kiosk',
      });
    }
    setShowToast(true);
    getData();
  };

  const handleUpdate = async (id: string) => {
    const result: any = await updateKiosk(id, state);
    if (result) {
      setToastData({
        ...toastData,
        severity: 'success',
        text: 'Kiosk has been updated',
      });
    } else {
      setToastData({
        ...toastData,
        severity: 'error',
        text: 'Error, could not update the Kiosk',
      });
    }
    setShowToast(true);
    getData();
  };

  const handleAddKiosk = async () => {
    const result = await createKiosk(state);
    if (result) {
      setToastData({
        ...toastData,
        severity: 'success',
        text: 'Kiosk has been created',
      });
    } else {
      setToastData({
        ...toastData,
        severity: 'error',
        text: 'Error, could not create the kiosk',
      });
    }
    setState({
      name: '',
      status: '',
      business_location_id: '',
      is_enable_photo_capture: true,
    });
    setOpenAdd(false);
    setShowToast(true);
    getData();
  };

  return (
    <>
      <MainViewContainer title="Kiosk">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '15px',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setMode('Add');
              setState({
                name: '',
                status: 'active',
                business_location_id: '',
                is_enable_photo_capture: true,
              });
              setOpenAdd(true);
            }}
          >
            Add Kiosk
          </Button>
        </Box>
        <DataTable
          rows={kiosks}
          columns={kioskViewData}
          loading={loading}
          rowCount={kiosks.length}
          handleCellClick={(params: GridCellParams) => {
            setCurrentId(params.id.toString());
            if (params.field === 'delete') {
              setShowConfirmDelete(true);
            } else if (params.field === 'edit') {
              setMode('Edit');
              setState({
                name: params.row.name,
                status: params.row.status,
                business_location_id: params.row.business_location_id,
                is_enable_photo_capture: params.row.is_enable_photo_capture,
              });
              setOpenAdd(true);
            } else if (params.field === 'auth_code') {
              navigator.clipboard.writeText(params.row.auth_code);
              setToastData({
                ...toastData,
                severity: 'success',
                text: `Pin ${params.row.auth_code} has been copied to your clipboard`,
              });
              setShowToast(true);
            }
          }}
          getData={() => {}}
        />
      </MainViewContainer>
      <GlobalModal
        open={openAdd}
        onClose={() => setOpenAdd(false)}
        defaultHeader={true}
        title={`${mode} Kiosk`}
        width="40vw"
      >
        <Stack spacing={1}>
          <TextField
            label="Kiosk Name"
            size="small"
            value={state.name}
            onChange={event => {
              setState({...state, name: event.target.value});
            }}
          />
          <FormControl size="small">
            <InputLabel id="inputlbl">Location</InputLabel>
            <Select
              labelId="inputlbl"
              id="input"
              label="Location"
              value={state.business_location_id}
              onChange={event => {
                setState({...state, business_location_id: event.target.value});
              }}
            >
              {locations.map((loc: any) => (
                <MenuItem key={loc.id} value={loc.id}>
                  {loc.street}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ToggleButtonGroup
            color="primary"
            value={state.is_enable_photo_capture}
            exclusive
            onChange={(event: any) => {
              setState({
                ...state,
                is_enable_photo_capture: event.target.value === 'true',
              });
            }}
            aria-label="Platform"
            size="small"
            fullWidth
          >
            <ToggleButton value={true}>Enable Photo Capture</ToggleButton>
            <ToggleButton value={false}>Disable Photo Capture</ToggleButton>
          </ToggleButtonGroup>
          <FormControl size="small">
            <InputLabel id="inputlbl">Status</InputLabel>
            <Select
              labelId="inputlbl"
              id="input"
              label="Status"
              value={state.status}
              onChange={event => {
                setState({...state, status: event.target.value});
              }}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
          {mode === 'Add' ? (
            <Button variant="outlined" onClick={() => setShowConfirmAdd(true)}>
              Save
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={() => setShowConfirmUpdate(true)}
            >
              Update
            </Button>
          )}
        </Stack>
      </GlobalModal>
      <Toast
        open={showToast}
        handleClose={() => setShowToast(false)}
        text={toastData.text}
        severity={toastData.severity}
        verticalPos={toastData.verticalPos}
        horizontalPos={toastData.horizontalPos}
      />
      <Confirm
        open={showConfirmAdd}
        handleClose={() => setShowConfirmAdd(false)}
        handleConfirmed={() => {
          setShowConfirmAdd(false);
          handleAddKiosk();
        }}
        text="Are you sure you want to add this kiosk?"
      />
      <Confirm
        open={showConfirmDelete}
        handleClose={() => setShowConfirmDelete(false)}
        handleConfirmed={() => {
          setShowConfirmDelete(false);
          handleDelete(currentId);
        }}
        text="Are you sure you want to delete this kiosk?"
      />
      <Confirm
        open={showConfirmUpdate}
        handleClose={() => setShowConfirmUpdate(false)}
        handleConfirmed={() => {
          setShowConfirmUpdate(false);
          handleUpdate(currentId);
        }}
        text="Are you sure you want to update this kiosk?"
      />
    </>
  );
};
