import {
  createKioskSetting,
  listKioskSettings,
  updateKioskSetting,
} from '@digistaff/business';
import {useCallback} from 'react';
import {app} from '../Account';

export const useKiosk = () => {
  const listKiosk = useCallback(async () => {
    return new Promise((resolve, reject) => {
      listKioskSettings(app, {
        filter: {status: {operator: '<>', value: 'deleted'}},
      })
        .then(res => resolve(res.data.listKioskSettings))
        .catch(err => reject(err));
    });
  }, []);

  const deleteKiosk = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      updateKioskSetting(app, id, {status: 'deleted'})
        .then(res => resolve(res.data.updateKioskSetting))
        .catch(err => reject(err));
    });
  }, []);

  const createKiosk = useCallback((payload: Object) => {
    return new Promise((resolve, reject) => {
      createKioskSetting(app, payload)
        .then(res => resolve(res.data.createKioskSetting))
        .catch(err => reject(err));
    });
  }, []);

  const updateKiosk = useCallback((id: string, payload: Object) => {
    return new Promise((resolve, reject) => {
      updateKioskSetting(app, id, payload)
        .then(res => resolve(res.data.updateKioskSetting))
        .catch(err => reject(err));
    });
  }, []);

  return {listKiosk, deleteKiosk, createKiosk, updateKiosk};
};
