import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Close, DragIndicator, Edit} from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {useState} from 'react';
import {GlobalModal} from '../../common/GlobalModal/GlobalModal';

export interface SortableProps {
  itemId: string;
  header: string;
  handleEdit: (id: string, newValue: string) => void;
  handleDelete: (id: string) => void;
}
export function SortableItem({
  itemId,
  header,
  handleEdit,
  handleDelete,
}: SortableProps) {
  const {attributes, listeners, setNodeRef, transform, transition, isDragging} =
    useSortable({id: itemId});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    border: 'solid 1px #eeeeee',
    cursor: isDragging ? 'grabbing' : 'grab',
    opacity: isDragging ? 0.5 : 1,
  };

  const [show, setShow] = useState(false);
  const [newHeader, setNewHeader] = useState(header);

  const handleEditClicked = () => {
    handleEdit(itemId, newHeader);
    setShow(false);
  };

  const handleDeleteClicked = () => {
    handleDelete(itemId);
  };

  return (
    <>
      <Card ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <CardContent
          sx={{
            '&:last-child': {
              paddingBottom: '16px',
            },
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              spacing={1}
              direction="row"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'black',
              }}
            >
              <DragIndicator />
              <Typography variant="h6">{header}</Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{cursor: 'pointer'}}>
              <Edit onClick={() => setShow(true)} />
              <Close onClick={handleDeleteClicked} />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <GlobalModal
        open={show}
        onClose={() => setShow(false)}
        defaultHeader={true}
        title="New Header"
        width="40vw"
      >
        <Stack spacing={2}>
          <TextField
            label="New Header"
            size="small"
            fullWidth
            value={newHeader}
            onChange={event => setNewHeader(event.target.value)}
          />
          <Button variant="contained" onClick={handleEditClicked}>
            Update
          </Button>
        </Stack>
      </GlobalModal>
    </>
  );
}
