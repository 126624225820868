/* eslint-disable @typescript-eslint/no-explicit-any */
import {MoreVert} from '@mui/icons-material';
import {useState} from 'react';
import {Box, IconButton, MenuItem, Menu, Tooltip} from '@mui/material';
import {ManageModal} from './modals/ManageModal';
import {SetPassModal} from './modals/SetPassModal';
import {ToastStruct} from '../../types/types';
import {SuspendModal} from './modals/SuspendModal';
import {Confirm} from '../../common/Alerts/Confirm';
import {DeactivateModal} from './modals/DeactivateModal';
import {useOps} from '../../providers/api/useOps';
import {forgotPassword} from '@digistaff/app';
import {app} from '../../providers/Account';

export interface MenuProps {
  user: any;
  handleToast: (data: ToastStruct) => void;
  handleUpdate: () => void;
}
export const ManageUserMenu = ({
  user,
  handleToast,
  handleUpdate,
}: MenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showSetPass, setShowSetPass] = useState(false);
  const [showSuspend, setShowSuspend] = useState(false);
  const [showReactivate, setShowReactivate] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const {updateOpTenant} = useOps();
  const [showResetConfirm, setShowResetConfirm] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReactivate = () => {
    setShowReactivate(false);
    handleToast({
      severity: 'success',
      text: `${user.nestGetOpsProfile.first_name}'s account has been re-activated`,
      verticalPos: 'bottom',
      horizontalPos: 'center',
    });
  };

  const handleUpdateStatus = (newStatus: string) => {
    const id = user.id;
    const payload = {status: newStatus};
    updateOpTenant(id, payload);
    handleToast({
      severity: 'success',
      text: `${user.nestGetOpsProfile.first_name}'s account has been set to ${newStatus}`,
      verticalPos: 'bottom',
      horizontalPos: 'center',
    });
    handleUpdate();
  };

  const handleReset = () => {
    setShowResetConfirm(false);
    forgotPassword(app, user.id)
      .then(() => {
        handleToast({
          severity: 'success',
          text: `${user.nestGetOpsProfile.first_name} has been sent a password reset to their email`,
          verticalPos: 'bottom',
          horizontalPos: 'center',
        });
      })
      .catch(err => {
        console.log(err);
        handleToast({
          severity: 'error',
          text: err.err.message,
          verticalPos: 'bottom',
          horizontalPos: 'center',
        });
      });
  };

  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        <Tooltip title="Manage">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'manage-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVert sx={{fontSize: '1.5em', color: 'black'}} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="manage-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            marginTop: '10px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem onClick={() => setShowAddUser(true)}>Edit</MenuItem>
        {/* {user.status !== 'suspended' && user.role !== 'admin' && (
          <MenuItem onClick={() => handleUpdateStatus('deactivated')}>
            Deactivate
          </MenuItem>
        )} */}
        {user.status !== 'suspended' && user.role !== 'admin' && (
          <MenuItem onClick={() => handleUpdateStatus('suspended')}>
            Suspend
          </MenuItem>
        )}
        {user.status === 'suspended' && (
          <MenuItem onClick={() => handleUpdateStatus('active')}>
            Unsuspend
          </MenuItem>
        )}
        <MenuItem onClick={() => setShowResetConfirm(true)}>
          Reset Password
        </MenuItem>
      </Menu>
      <ManageModal
        title="Edit User"
        open={showAddUser}
        handleClose={() => {
          setShowAddUser(false);
          handleUpdate();
        }}
        user={user}
        handleToast={handleToast}
      />
      <SetPassModal
        open={showSetPass}
        handleClose={() => setShowSetPass(false)}
        user={user}
        handleToast={handleToast}
      />
      <SuspendModal
        open={showSuspend}
        handleClose={() => setShowSuspend(false)}
        user={user}
        handleToast={handleToast}
      />
      <DeactivateModal
        open={showDeactivate}
        handleClose={() => setShowDeactivate(false)}
        user={user}
        handleToast={handleToast}
      />
      <Confirm
        open={showReactivate}
        handleClose={() => setShowReactivate(false)}
        text="Are you sure you want to re-activate this user?"
        handleConfirmed={handleReactivate}
      />
      <Confirm
        open={showResetConfirm}
        handleClose={() => setShowResetConfirm(false)}
        text="Are you sure you want to reset this users's password?"
        handleConfirmed={handleReset}
      />
    </>
  );
};
