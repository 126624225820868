/* eslint-disable @typescript-eslint/no-explicit-any */
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactQuill from 'react-quill';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {useContract} from '../../providers/api/useContract';
import {useLocation} from 'react-router-dom';
import {
  Breadcrumbs,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Link,
  Button,
  Stack,
  Box,
  CircularProgress,
} from '@mui/material';
import {GlobalModal} from '../../common/GlobalModal/GlobalModal';
import {Toast} from '../../common/Alerts/Toast';
import {ToastStruct} from '../../types/types';
import ReactSignatureCanvas from 'react-signature-canvas';
import {uploadImageFile} from '../../providers/api/image';

export const ManageContract = () => {
  const {getContract, updateContract, createContract} = useContract();
  const {state} = useLocation();
  const [contract, setContract] = useState({
    name: '',
    description: '',
    status: 'active',
  });
  const [text, setText] = useState('');
  const [openInsert, setOpenInsert] = useState(false);
  const [field, setField] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState<ToastStruct>({
    severity: 'info',
    text: '',
    verticalPos: 'bottom',
    horizontalPos: 'center',
  });
  const [loading, setLoading] = useState(true);
  const quil: any = useRef();
  const sigPadRef = useRef<ReactSignatureCanvas | null>(null);
  const [signatureUrl, setSignatureUrl] = useState('');
  const [creating, setCreating] = useState(false);

  const getData = useCallback(
    async (id: string) => {
      const result: any = await getContract(id);
      setText(result.file);
      setContract({
        name: result.name,
        description: result.description,
        status: result.status,
      });
      setLoading(false);
    },
    [getContract]
  );

  useEffect(() => {
    if (state) getData(state.id);
    else setLoading(false);
  }, [state, getData]);

  const handleInsert = () => {
    if (
      field === 'employer_signature' &&
      sigPadRef.current &&
      sigPadRef.current.isEmpty()
    ) {
      setToastData({
        ...toastData,
        severity: 'error',
        text: 'Please sign before confirming',
      });
      setShowToast(true);
    } else {
      if (field === 'employer_signature') confirmSignature();
      const currentPosition = quil.current.getEditor().getSelection();
      quil.current.getEditor().insertText(currentPosition, ` {${field}} `);
      setField('');
      setOpenInsert(false);
    }
  };

  const handleUpdateContract = () => {
    const payload = {
      name: contract.name,
      description: contract.description,
      status: contract.status,
      file: text,
      file_type: 'html',
      employer_signature_url: signatureUrl,
    };

    updateContract(state.id, payload)
      .then(() => {
        setToastData({
          ...toastData,
          severity: 'success',
          text: 'Contract has been updated',
        });
      })
      .catch(err => {
        setToastData({
          ...toastData,
          severity: 'error',
          text: err,
        });
      })
      .finally(() => {
        setShowToast(true);
      });
  };

  const handleCreateContract = () => {
    const payload = {
      name: contract.name,
      description: contract.description,
      status: contract.status,
      file: text,
      file_type: 'html',
      employer_signature_url: signatureUrl,
    };

    if (
      contract.name.length < 1 ||
      contract.description.length < 1 ||
      text.length < 1
    ) {
      setToastData({
        ...toastData,
        severity: 'error',
        text: 'Please fill in all the fields',
      });
    } else {
      createContract(payload)
        .then(() => {
          setToastData({
            ...toastData,
            severity: 'success',
            text: 'Contract has been created',
          });
        })
        .catch(err => {
          setToastData({
            ...toastData,
            severity: 'error',
            text: err,
          });
        })
        .finally(() => setCreating(false));
    }

    setShowToast(true);
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],
          [{align: []}],
          [{list: 'ordered'}, {list: 'bullet'}],
          [{indent: '-1'}, {indent: '+1'}],
          [{header: [1, 2, 3, 4, 5, 6, false]}],
          [{color: []}, {background: []}],
          [{font: []}],
          ['clean'],
          ['code-block'],
        ],
        handlers: {
          'code-block': () => setOpenInsert(true),
        },
      },
    }),
    []
  );

  const handleClear = () => sigPadRef?.current?.clear();

  const getBase64Data = () => {
    if (sigPadRef.current) {
      const imageData =
        sigPadRef.current &&
        sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
      return imageData.substring(imageData.indexOf(',') + 1);
    } else {
      return;
    }
  };

  const uploadImage = async (base64Data: any, imageId: string) => {
    return uploadImageFile(base64Data, imageId);
  };

  const confirmSignature = async () => {
    if (sigPadRef.current && !sigPadRef.current.isEmpty()) {
      const base64Data = getBase64Data();
      const imageId = 'image_id';
      try {
        const uploadedImage = await uploadImage(base64Data, imageId);
        setSignatureUrl(uploadedImage);
      } catch (err) {
        console.log(err);
        console.log(err);
      }
    } else {
      setToastData({
        ...toastData,
        severity: 'error',
        text: 'Please sign before confirming',
      });
      setShowToast(true);
    }
  };

  const getSignBoxWidth = () => {
    let signBoxWidth = 400;
    const termBox = document.getElementById('termBox');

    if (termBox) {
      signBoxWidth = termBox.offsetWidth - 30;
    }

    return signBoxWidth;
  };

  return (
    <MainViewContainer title="Manage Contract">
      {loading ? (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
            <Link underline="hover" color="inherit" href="/contracts">
              Contract Settings
            </Link>
            <Typography color="text.primary">Manage</Typography>
          </Breadcrumbs>
          <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            {state !== null ? (
              <Button variant="outlined" onClick={handleUpdateContract}>
                Update
              </Button>
            ) : (
              <Button
                variant="outlined"
                onClick={() => {
                  setCreating(true);
                  handleCreateContract();
                }}
                disabled={creating}
              >
                {creating ? <CircularProgress /> : 'Create'}
              </Button>
            )}
          </Box>
          <Grid container sx={{my: 1.5}}>
            <Grid item xs={4}>
              <TextField
                label="Name"
                size="small"
                fullWidth
                value={contract.name}
                onChange={event =>
                  setContract({...contract, name: event.target.value})
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Description"
                size="small"
                fullWidth
                value={contract.description}
                onChange={event =>
                  setContract({...contract, description: event.target.value})
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FormControl size="small" fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  value={contract.status}
                  onChange={event =>
                    setContract({...contract, status: event.target.value})
                  }
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <ReactQuill
            ref={quil}
            theme="snow"
            value={text}
            onChange={value => setText(value)}
            modules={modules}
            placeholder="Paste or type your contract here..."
          />
          <GlobalModal
            open={openInsert}
            onClose={() => setOpenInsert(false)}
            defaultHeader={true}
            title="Insert Autofilled Field"
            width="470px"
          >
            <Stack spacing={2}>
              <FormControl size="small" fullWidth>
                <InputLabel>Field</InputLabel>
                <Select
                  label="Field"
                  value={field}
                  fullWidth
                  onChange={event => setField(event.target.value)}
                >
                  <MenuItem value="" disabled></MenuItem>
                  <MenuItem value="date">Signed Date</MenuItem>
                  <MenuItem value="first_name">Employee First Name</MenuItem>
                  <MenuItem value="last_name">Employee Last Name</MenuItem>
                  <MenuItem value="address">Employee Address</MenuItem>
                  <MenuItem value="officer_position">Officer Position</MenuItem>
                  <MenuItem value="staff_signature">Staff Signature</MenuItem>
                  <MenuItem value="employer_signature">
                    Employer Signature
                  </MenuItem>
                  <MenuItem value="staff_position">Staff Position</MenuItem>
                </Select>
              </FormControl>
              {field === 'employer_signature' && (
                <>
                  <Box
                    sx={{
                      width: '400px',
                      border: '1px solid',
                      borderColor: 'secondary.main',
                    }}
                    id="termBox"
                  >
                    <ReactSignatureCanvas
                      ref={sigPadRef}
                      canvasProps={{
                        width: getSignBoxWidth(),
                        height: 300,
                        className: 'sigCanvas',
                      }}
                    />
                  </Box>
                  <Button variant="text" onClick={handleClear}>
                    Clear
                  </Button>
                </>
              )}
              <Button variant="outlined" fullWidth onClick={handleInsert}>
                Insert
              </Button>
            </Stack>
          </GlobalModal>
          <Toast
            open={showToast}
            handleClose={() => setShowToast(false)}
            text={toastData.text}
            severity={toastData.severity}
            verticalPos={toastData.verticalPos}
            horizontalPos={toastData.horizontalPos}
          />
        </>
      )}
    </MainViewContainer>
  );
};
