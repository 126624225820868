import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useEffect, useState} from 'react';
import {GlobalModal} from '../../common/GlobalModal/GlobalModal';
import {useSearchTimer} from '../../common/hooks/useSearchTimer';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {emptyLog, logColumns, logRows, LogStruct} from './securityViewData';
import {labeledBox, labeledBoxTitle} from './securityViewStyles';

export const SecurityView = () => {
  const [open, setOpen] = useState(false);
  const [log, setLog] = useState<LogStruct>(emptyLog);
  const [state, setState] = useState({
    id: '',
    start: null,
    end: null,
    category: '',
    user: '',
    ip: '',
    action: '',
    message: '',
  });

  const [timer] = useSearchTimer();

  useEffect(() => {
    timer(state);
  }, [timer, state]);

  return (
    <>
      <MainViewContainer title="Security">
        <Box sx={labeledBox}>
          <Typography component="legend" sx={labeledBoxTitle}>
            Search
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <FormControl fullWidth={true}>
                <TextField
                  label="Log ID"
                  variant="outlined"
                  size="small"
                  value={state.id}
                  onChange={event => {
                    setState({...state, id: event.target.value});
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  renderInput={props => (
                    <TextField {...props} size="small" fullWidth />
                  )}
                  label="Start"
                  value={state.start}
                  onChange={event => {
                    setState({
                      ...state,
                      start: event,
                    });
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  renderInput={props => (
                    <TextField {...props} size="small" fullWidth />
                  )}
                  label="End"
                  minDate={state.start}
                  value={state.end}
                  onChange={event => {
                    setState({
                      ...state,
                      end: event,
                    });
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <FormControl size="small" fullWidth>
                <InputLabel id="categorylbl">Category</InputLabel>
                <Select
                  labelId="categorylbl"
                  id="category"
                  label="Category"
                  sx={{marginBottom: '15px'}}
                  value={state.category}
                  onChange={event => {
                    setState({...state, category: event.target.value});
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="billing-payment">Billing & Payment</MenuItem>
                  <MenuItem value="technology">Technology Alert</MenuItem>
                  <MenuItem value="daily-ops">Daily Ops Summary</MenuItem>
                  <MenuItem value="campaign">Campaign Results</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth={true}>
                <TextField
                  label="User"
                  variant="outlined"
                  size="small"
                  value={state.user}
                  onChange={event => {
                    setState({...state, user: event.target.value});
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth={true}>
                <TextField
                  label="IP"
                  variant="outlined"
                  size="small"
                  value={state.ip}
                  onChange={event => {
                    setState({...state, ip: event.target.value});
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth={true}>
                <TextField
                  label="Action"
                  variant="outlined"
                  size="small"
                  value={state.action}
                  onChange={event => {
                    setState({...state, action: event.target.value});
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth={true}>
                <TextField
                  label="Message"
                  variant="outlined"
                  size="small"
                  value={state.message}
                  onChange={event => {
                    setState({...state, message: event.target.value});
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <DataGrid
          sx={{minHeight: '400px'}}
          rows={logRows}
          columns={logColumns}
          rowsPerPageOptions={[25, 50, 100]}
          density="compact"
          components={{
            Toolbar: () => <GridToolbar />,
          }}
          onRowClick={data => {
            setLog(data.row);
            setOpen(true);
          }}
        />
      </MainViewContainer>
      <GlobalModal
        open={open}
        onClose={() => setOpen(false)}
        defaultHeader={true}
        title="Log Information"
      >
        <Grid container rowGap={3}>
          <Grid item xs={4}>
            <Stack>
              <Typography fontWeight="bold">ID</Typography>
              {log.id}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack>
              <Typography fontWeight="bold">Timestamp</Typography>
              {log.timestamp}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack>
              <Typography fontWeight="bold">Category</Typography>
              {log.category}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack>
              <Typography fontWeight="bold">User</Typography>
              {log.user}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack>
              <Typography fontWeight="bold">IP</Typography>
              {log.ip}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack>
              <Typography fontWeight="bold">Action</Typography>
              {log.logAction}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography fontWeight="bold">Message</Typography>
              {log.message}
            </Stack>
          </Grid>
        </Grid>
      </GlobalModal>
    </>
  );
};
