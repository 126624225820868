import {listBusinessLocation, listBusinessesAssign} from '@digistaff/business';
import {useCallback} from 'react';
import {app} from '../Account';

export const useBusiness = () => {
  const listBusiness = useCallback(async () => {
    return new Promise((resolve, reject) => {
      listBusinessesAssign(app)
        .then(res => {
          resolve(res.data.listBusinesses);
        })
        .catch(err => {
          reject(err);
        });
    });
  }, []);

  const listBusinessLocations = useCallback(async () => {
    return new Promise((resolve, reject) => {
      listBusinessLocation(app)
        .then(res => resolve(res.data.listBusinessLocations))
        .catch(err => reject(err));
    });
  }, []);

  return {listBusiness, listBusinessLocations};
};
