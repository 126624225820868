/* eslint-disable @typescript-eslint/no-explicit-any */
import {Navigate, useLocation} from 'react-router-dom';
import {
  app,
  getCurrentUser,
  isSessionValid,
  setTenantKey,
  signOut,
} from '../../providers/Account';

const handleAuthRedirect = () => {
  const isLocal = window.location.origin === process.env.LOCAL_ADMIN_URL;

  const redirectBaseUrl = isLocal
    ? process.env.LOCAL_ACCOUNTS_URL
    : process.env.DS_ACCOUNTS_URL;

  window.location.href = `${redirectBaseUrl}/tenants?continue=${window.location.pathname}&site=admin&clear=true`;
};

const checkUser = () => {
  getCurrentUser(app).catch(() => {
    signOut(app)
      .then(() => {
        localStorage.clear();
        handleAuthRedirect();
      })
      .catch(err => console.log(err));
  });
};

window.addEventListener('focus', checkUser);

export const PrivateRoute = ({children}: any) => {
  checkUser();
  const checkSession = isSessionValid(app);
  const currentPath = useLocation().pathname;

  const path = useLocation().pathname.split('/');
  const pathTitle = path[1].charAt(0).toUpperCase() + path[1].slice(1);

  document.title = `${pathTitle} - Digistaff`;

  const tenant = localStorage.getItem('tenant');

  if (tenant !== null) {
    setTenantKey(app, tenant!);
  } else {
    handleAuthRedirect();
  }

  localStorage.setItem('dsLastPage', currentPath);

  if (!checkSession) {
    return <Navigate to="/" replace />;
  }

  return children;
};
