import {
  Avatar,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import {AccountBalance, CreditCard} from '@mui/icons-material';

interface IAddNewPaymentDialogBoxProps {
  open: boolean;
  onClose: (value: string) => void;
}

export const AddNewPaymentDialogBox = (props: IAddNewPaymentDialogBoxProps) => {
  const {onClose, open} = props;

  const handleClose = () => {
    onClose('selectedValue');
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Choose a method</DialogTitle>
      <List sx={{pt: 0}}>
        <ListItem onClick={() => handleListItemClick('addAccount')}>
          <ListItemAvatar>
            <Avatar>
              <AccountBalance />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Bank Account (Direct)" />
        </ListItem>
        <ListItem onClick={() => handleListItemClick('addAccount')}>
          <ListItemAvatar>
            <Avatar>
              <CreditCard />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Credit Card" />
        </ListItem>
      </List>
    </Dialog>
  );
};
