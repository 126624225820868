import {Tab, Tabs, Box} from '@mui/material';
import {useState} from 'react';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = ({children, index, value}: TabPanelProps) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tab-${index}`}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

interface HorizontalTabsProps {
  children: JSX.Element[];
  labels: string[];
}

export const HorizontalTabs = ({children, labels}: HorizontalTabsProps) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Box>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          centered
          sx={{margin: '10px'}}
          orientation="horizontal"
        >
          {labels.map((label: string, index: number) => {
            return (
              <Tab
                label={label}
                id={`tab-${index}`}
                key={index}
                sx={{marginRight: '5px'}}
              />
            );
          })}
        </Tabs>
        {children.map((child: JSX.Element, index: number) => {
          return (
            <TabPanel key={index} index={index} value={currentTab}>
              {child}
            </TabPanel>
          );
        })}
      </Box>
    </>
  );
};
