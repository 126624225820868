import {
  Button,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import {useState} from 'react';
import {Confirm} from '../../../common/Alerts/Confirm';
import {GlobalModal} from '../../../common/GlobalModal/GlobalModal';
import {ToastStruct} from '../../../types/types';
import {errorMsg} from '../styles';
import {UserStruct} from '../UserViewData';

export interface DeactivateModalProps {
  open: boolean;
  handleClose: () => void;
  user?: UserStruct;
  handleToast: (data: ToastStruct) => void;
}

export const DeactivateModal = ({
  open,
  handleClose,
  user,
  handleToast,
}: DeactivateModalProps) => {
  const [deactivateUntil, setDeactivateUntil] = useState<null | Date>(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [error, setError] = useState('');
  const [deactivateStatus, setDeactivateStatus] = useState('perm');

  const handleConfirmed = () => {
    const until =
      deactivateUntil === null ? 'indefinitely' : `until ${deactivateUntil}`;
    handleClose();
    handleToast({
      severity: 'success',
      text: `${user?.first_name} has been deactivated ${until}`,
      verticalPos: 'bottom',
      horizontalPos: 'center',
    });
  };

  const handleDeactivatedClicked = () => {
    if (deactivateUntil !== null || deactivateStatus === 'perm') {
      setShowConfirm(true);
    } else {
      setError('Please select a date & time');
    }
  };

  return (
    <GlobalModal
      open={open}
      onClose={handleClose}
      defaultHeader={true}
      title="Deactivate User"
      width="30vw"
    >
      <Stack spacing={2}>
        <ToggleButtonGroup
          value={deactivateStatus}
          exclusive
          onChange={(_event, value) => setDeactivateStatus(value)}
        >
          <ToggleButton value="perm" aria-label="left aligned">
            Indefinitely
          </ToggleButton>
          <ToggleButton value="custom" aria-label="left aligned">
            Custom Date
          </ToggleButton>
        </ToggleButtonGroup>
        {deactivateStatus === 'custom' && (
          <>
            <Typography>Select a date to deactivate the user on</Typography>
            <Stack>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  renderInput={props => <TextField {...props} size="small" />}
                  label="Deactivate User On"
                  minDate={moment().toDate()}
                  value={deactivateUntil}
                  onChange={newValue => {
                    setDeactivateUntil(newValue);
                  }}
                />
              </LocalizationProvider>
              <Typography sx={errorMsg}>{error}</Typography>
            </Stack>
          </>
        )}
        <Button variant="outlined" onClick={handleDeactivatedClicked}>
          Deactivate User
        </Button>
      </Stack>
      <Confirm
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        text={`Are you sure you want to deactivate ${user?.first_name}`}
        handleConfirmed={handleConfirmed}
      />
    </GlobalModal>
  );
};
