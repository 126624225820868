import {getOpsProfile} from '@digistaff/business';
import {app} from '../Account';

export const fetchOpsProfile = async (opsId: string) => {
  try {
    const res = await getOpsProfile(app, opsId);

    return res.data.getOpsProfile;
  } catch (error) {
    return Promise.reject(error);
  }
};
