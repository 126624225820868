/* eslint-disable @typescript-eslint/no-explicit-any */
import {fileUpload} from '@digistaff/core';
import {app} from '../Account';

export const uploadImageFile = async (
  base64Data: string,
  file_name: string
) => {
  try {
    const res: any = await fileUpload(
      app,
      base64Data,
      'image/jpeg',
      `${file_name}.jpeg`,
      'base64'
    );

    return res.data.result.Location;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};
