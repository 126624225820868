/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
} from '@mui/material';
import {
  listBillingPlans,
  getSubscription,
  setSubscription,
} from '../../providers/BillingSubscription';
import {useEffect, useState} from 'react';
import {MainViewContainer} from '../../common/MainViewContainer/MainViewContainer';
import {Toast} from '../../common/Alerts/Toast';
import {app} from '../../providers/Account';

export const SubscriptionView = () => {
  const [billingPlans, setBillingPlans] = useState<any>([]);
  const [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState<any>(
    {}
  );
  const [toasterOpen, setToasterOpen] = useState(false);

  useEffect(() => {
    listBillingPlans(app)
      .then((plans: any) => {
        setBillingPlans(plans.result);
      })
      .catch(err => {
        console.log('err', err);
      });
  }, []);

  useEffect(() => {
    getSubscription(app)
      .then((subscription: any) => {
        setCurrentSubscriptionPlan(subscription.result[0]);
      })
      .catch(err => {
        console.log('err', err);
      });
  }, []);

  const handleRadioChange = (e: any) => {
    const selectedPlanId = e.target.value;
    setSubscription(
      app,
      currentSubscriptionPlan.billing_subscription_id,
      selectedPlanId
    ).then(() => {
      console.log('success');
      setToasterOpen(true);
    });
  };

  return (
    <MainViewContainer title="Subscription Plan">
      <Box style={{marginTop: '50px'}}>
        {!currentSubscriptionPlan.billing_plan_id ? (
          ''
        ) : (
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Selected Plan
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={currentSubscriptionPlan.billing_plan_id}
              name="radio-buttons-group"
              onChange={handleRadioChange}
            >
              {billingPlans.map((plan: any) => (
                <FormControlLabel
                  key={plan.billing_plan_id}
                  value={plan.billing_plan_id}
                  control={<Radio />}
                  label={`${plan.name}`}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </Box>
      <Toast
        open={toasterOpen}
        handleClose={() => setToasterOpen(false)}
        text="Succesfully updated your subscription plan"
        severity="success"
        verticalPos="bottom"
        horizontalPos="center"
      />
    </MainViewContainer>
  );
};
