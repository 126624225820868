import {Wrapper} from '@googlemaps/react-wrapper';
import {TextField} from '@mui/material';
import {Autocomplete} from '@react-google-maps/api';
import {useState} from 'react';

export interface AutoCompleteProps {
  initialValue?: string;
  placeHolder?: string;
  onChange: (addressData: Object) => void;
}

/**
 * Displays a TextField that will provide address suggtions
 *
 * @remarks
 * Uses the Google places API & react-google-maps package
 *
 * @param initalValue - Optional inital value for the TextField
 * @param placeholder - Optional placeholder for the TextField
 * @param onChange - Handles what happens when the value of the TextField changes
 */
export const AutoComplete = ({
  initialValue,
  onChange,
  placeHolder,
}: AutoCompleteProps) => {
  const [text, setText] = useState(initialValue);
  const [place, setPlace] = useState<null | google.maps.places.Autocomplete>(
    null
  );

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setPlace(autocomplete);
  };

  const handleChange = () => {
    if (place !== null) {
      const data = place.getPlace();
      if (data.address_components) {
        const street = `${data.address_components[0].long_name} ${data.address_components[1].long_name}`;
        const city = data.address_components[2].long_name;
        const province =
          data.address_components[data.address_components.length - 3]
            .short_name;
        const postal =
          data.address_components[data.address_components.length - 1].long_name;
        const country =
          data.address_components[data.address_components.length - 2].long_name;
        const latitude = data.geometry?.location?.lat();
        const longitude = data.geometry?.location?.lng();

        const addressData = {
          street,
          city,
          province,
          postal,
          country,
          latitude,
          longitude,
        };
        onChange(addressData);
      }
      setText(place.getPlace().formatted_address + '');
    }
  };

  const GOOGLE_PLACES_API_KEY: string = process.env.GOOGLE_PLACES_KEY as string;

  return (
    <Wrapper apiKey={GOOGLE_PLACES_API_KEY} libraries={['places']}>
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={handleChange}
        restrictions={{country: 'ca'}}
      >
        <TextField
          size="small"
          value={text}
          onChange={event => setText(event.target.value)}
          placeholder={placeHolder}
          fullWidth
        />
      </Autocomplete>
    </Wrapper>
  );
};
